import styled from 'styled-components'
import React from 'react'
import { SERVICE_REQUEST } from 'constants/resources'
import { Chip } from 'components/common'
import { useTranslation } from 'react-i18next'
import { useStatusColors } from 'components/hooks'
import moment from 'moment'
import { DATETIME } from 'constants/formats'

const StatusUpdateStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px 0; 

  .status-bar {
    flex-grow: 1;
    border-bottom: 1px solid ${({ theme }) => theme.color.secondary500};
    opacity: 0.3;
  }

  .status-update {
    margin: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      font-size: 12px;
      margin-bottom: 10px;
      opacity: 0.5;
    }
  }
`

function StatusUpdate({
  status,
  timestamp,
}) {
  const statusColors = useStatusColors(SERVICE_REQUEST)
  const { t } = useTranslation(SERVICE_REQUEST)
  return (
    <StatusUpdateStyled>
      <div className="status-bar" />
      <div className="status-update">
        <p>{moment(timestamp).format(DATETIME)}</p>
        <Chip
          background={statusColors[status]}
          label={t(`serviceRequest:status.${status}`)}
        />
      </div>
      <div className="status-bar" />
    </StatusUpdateStyled>
  )
}

export default StatusUpdate
