import React from 'react'
import { Chip, DetailHeader, ExtraButton } from 'components/common'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useStatusColors } from 'components/hooks'
import { ORDER } from 'constants/resources'
import { Map } from 'immutable'
import OrderItemButton from './OrderItemButton'
import OrderServiceButton from './OrderServiceButton'
import OffRentButton from './OffRentButton'
import DuplicateButton from './DuplicateButton'

function Header({
  id,
  status,
  title,
  loading,
  order,
}) {
  const { t } = useTranslation(ORDER)
  const statusColors = useStatusColors(ORDER)
  title = title ? title.slice(3) : ''

  return (
    <DetailHeader
      title={title}
      loading={loading}
    >
      <DuplicateButton
        order={order}
      />
      <ExtraButton 
        id={id}
        status={status}
        currentItem={order}
        requestType={ORDER}
      />
      <OffRentButton
        id={id}
        status={status}
        order={order}
      />
      <OrderServiceButton
        id={id}
        status={status}
      />
      <OrderItemButton
        id={id}
        status={status}
      />
      <Chip
        background={statusColors[status]}
        label={t(`order:status.${status}`)}
      />
    </DetailHeader>
  )
}

Header.propTypes = {
  id: PropTypes.string,
  status: PropTypes.string,
  title: PropTypes.string,
  loading: PropTypes.bool,
  order: PropTypes.instanceOf(Map),
}

Header.defaultProps = {
  id: '',
  status: '',
  title: '',
  loading: false,
  order: new Map(),
}


export default Header
