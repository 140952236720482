import React from 'react'
import { TableHeader, TableCell, Sort } from 'components/common'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { SERVICE_REQUEST } from 'constants/resources'
import { useResource, useDeviceColumns } from 'components/hooks'
import { useDispatch } from 'react-redux'
import { serviceRequests } from 'store/action-creators'
import { connectSortBy } from 'react-instantsearch-dom'
import {
  SERVICE_REQUEST_INDEX,
  SERVICE_REQUEST_CREATED_ASC_INDEX,
  SERVICE_REQUEST_CREATED_DESC_INDEX,
} from 'constants/algolia'

function RequestsHeader({
  refine, columns,
}) {
  const dispatch = useDispatch()
  const { t } = useTranslation(['common', SERVICE_REQUEST])
  const selected = useResource(SERVICE_REQUEST, 'current', 'id')
  const currentIndex = useResource(SERVICE_REQUEST, 'list', 'search', 'index')
  const templateColumns = useDeviceColumns(columns, !!selected)

  const handleSortClick = (value) => {
    dispatch(serviceRequests.setListIndex(value))
    if (refine) refine(value)
  }

  return (
    <TableHeader templateColumns={templateColumns}>
      <TableCell sm>
        <Sort
          onClick={handleSortClick}
          currentIndex={currentIndex}
          defaultIndex={SERVICE_REQUEST_INDEX}
          ascendantIndex={SERVICE_REQUEST_CREATED_ASC_INDEX}
          descendantIndex={SERVICE_REQUEST_CREATED_DESC_INDEX}
        >
          <p>{t('common:shared.id')}</p>
        </Sort>
      </TableCell>
      <TableCell sm>
        <p>{t('common:shared:type')}</p>
      </TableCell>
      <TableCell sm>
        <p>{t('common:shared.status')}</p>
      </TableCell>
      <TableCell lg={!selected}>
        <p>{t('common:resources.companies.singular')}</p>
      </TableCell>
      <TableCell lg={!selected}>
        <Sort
          onClick={handleSortClick}
          currentIndex={currentIndex}
          defaultIndex={SERVICE_REQUEST_INDEX}
          ascendantIndex={SERVICE_REQUEST_CREATED_ASC_INDEX}
          descendantIndex={SERVICE_REQUEST_CREATED_DESC_INDEX}
        >
          <p>{t('serviceRequest:shared.createdAt')}</p>
        </Sort>
      </TableCell>
      <TableCell lg={!selected}>
        <p>{t('serviceRequest:shared.createdBy')}</p>
      </TableCell>
      <TableCell md>
        <p>{t('common:shared.handledBy')}</p>
      </TableCell>
    </TableHeader>
  )
}

RequestsHeader.propTypes = {
  columns: PropTypes.object.isRequired,
}

export default connectSortBy(RequestsHeader)
