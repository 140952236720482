import MaterialCheckbox from '@material-ui/core/Checkbox'
import React from 'react'

function CheckboxEditable({ label, resource, resourceItem, disabled, ...otherProps }) {

  return (
    <div>
      <MaterialCheckbox disabled={disabled} {...otherProps} />
      <span data-cy="checkbox-editable-label">
        {label}
      </span>
      
    </div>
  )
}

export default CheckboxEditable
