import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Detail, PreviewLink, SectionHeader } from 'components/common'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { COMPANY, USER } from 'constants/resources'

function Information({
  action,
  title,
  name,
  loading,
  salesRepName,
  salesRepId,
  rentalProtection,
  hasAccount,
  hasSignedRPP,
  currentSupplier,
  notes,
  emailsList,
  emailsOptin,
  emailsLang
}) {
  const { t } = useTranslation(['common', COMPANY])
  return (
    <Grid container spacing={2}>
      {title ? <SectionHeader title={title}>{action}</SectionHeader> : null}
      <Grid item xs={12} md={6}>
        <Detail text={salesRepName} loading={loading} label={t('shared.salesRep')} empty={t('company:shared.emptySalesRep')}>
          <PreviewLink resource={USER} id={salesRepId}>
            <span>{salesRepName}</span>
          </PreviewLink>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail text={rentalProtection} loading={loading} label={t('orderItem:shared.rentalProtection')}>
          {rentalProtection}%
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail text={hasAccount ? t('common:shared.yes') : t('common:shared.no')} loading={loading} label={t('company:shared.signedRPP')}>
          {hasSignedRPP ? t('common:shared.yes') : t('common:shared.no')}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail text={hasAccount ? t('common:shared.yes') : t('common:shared.no')} loading={loading} label={t('company:shared.account')}>
          {hasAccount ? t('common:shared.yes') : t('common:shared.no')}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail text={currentSupplier} loading={loading} label={t('serviceRequest:shared.currentSupplier')}>
          {currentSupplier}
        </Detail>
      </Grid>
      <Grid item xs={12} md={12}>
        <Detail text={notes} loading={loading} label={t('common:shared.notes')}>
          {notes}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail text={emailsOptin} loading={loading} label={t('company:emails.subscription')}>
          {emailsOptin ? t('company:emails.optin') : t('company:emails.optout')}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail text={emailsList} loading={loading} label={t('company:emails.list')}>
          {emailsList?.join('\n')}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6}>
        <Detail text={emailsLang} loading={loading} label={t('company:emails.lang')}>
          {emailsLang === 'fr' ? t('common:languages.fr') : t('common:languages.en')}
        </Detail>
      </Grid>
    </Grid>
  )
}

Information.propTypes = {
  action: PropTypes.node,
  name: PropTypes.string,
  salesRepName: PropTypes.string,
  salesRepId: PropTypes.string,
  title: PropTypes.string,
  loading: PropTypes.bool,
  hasAccount: PropTypes.bool,
  rentalProtection: PropTypes.number,
  currentSupplier: PropTypes.string,
  notes: PropTypes.string
}

Information.defaultProps = {
  action: null,
  name: '',
  salesRepName: '',
  salesRepId: '',
  title: '',
  loading: false,
  rentalProtection: 0,
  hasAccount: false,
  currentSupplier: '',
  notes: ''
}

export default Information
