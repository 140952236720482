import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { Divider } from 'components/ui'
import styled from 'styled-components'
import Information from './detail/Information'
import Header from './detail/Header'
import TransportInformation from 'components/pages/service-request-items/detail/TransportInformation'
import SuppliersInformation from 'components/pages/order-items/detail/SuppliersInformation'
import { SectionHeader } from 'components/common'
import { useTranslation } from 'react-i18next'

const StyledCollapsible = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.primary200};
  padding: 1em 0;
  .collapsible-visible {
    cursor: pointer;
  }
  .collapsible-content {
    box-sizing: border-box;
    transition: 300ms ease-out;
    max-height: ${({ open }) => (open ? undefined : 1)}px;
    overflow: hidden;
  }
  &:last-child {
    margin-bottom: 20px;
  }
`

function OrderItemDetail({ selected, loading }) {
  const { t } = useTranslation('common')
  const [open, setOpen] = useState(false)

  return (
    <StyledCollapsible open={open}>
      <div className="collapsible-visible" data-cy="order-items-details">
        <div onClick={() => setOpen(!open)}>
          <Header
            small
            isEdit
            title={selected.getIn(['equipment', 'name'])}
            status={selected.get('status')}
            code={selected.get('code')}
            orderItemId={selected.get('id')}
            orderId={selected.getIn(['order', 'id'])}
            orderItem={selected}
            equipmentName={selected.getIn(['equipment', 'name'])}
            loading={loading}
          />
        </div>
      </div>
      <div className="collapsible-content">
        <Divider spacing={20} />
        <Information
          offRentAt={selected.get('off_rented_at')}
          equipmentName={selected.getIn(['equipment', 'name'])}
          equipmentId={selected.getIn(['equipment', 'id'])}
          quantity={selected.get('quantity')}
          dailyRate={selected.get('daily_rate')}
          weeklyRate={selected.get('weekly_rate')}
          monthlyRate={selected.get('monthly_rate')}
          rentalProtection={selected.get('rental_protection')}
          envCompensation={selected.get('environmental_fee')}
          invoicesSent={selected.get('invoices_sent') || selected.getIn(['metadata', 'invoices_sent'])}
          invoicesReceived={selected.get('invoices_received') || selected.getIn(['metadata', 'invoices_received'])}
          code={selected.get('code')}
          internalCode={selected.get('internal_code')}
          loading={loading}
        />
        {selected.get('transport') && selected.get('transport').size > 0 && (
          <>
            <SectionHeader title={t('common:resources.transport.singular')} fullSize />
            {[...Array(selected.get('transport').size)].map((x, i) => {
              return (
                <TransportInformation
                  key={selected.getIn(['transport', i, 'type']) + selected.getIn(['transport', i, 'value'])}
                  type={selected.getIn(['transport', i, 'type'])}
                  rate={selected.getIn(['transport', i, 'value'])}
                  loading={loading}
                />
              )
            })}
          </>
        )}
        {selected.get('selected_supplier') && (
          <>
            <Divider spacing={20} />
            <SectionHeader title={t('common:resources.suppliers.singular')} fullSize />
            <Divider spacing={20} />
            <SuppliersInformation
              id={selected.getIn(['selected_supplier', 'id'])}
              supplierRate={selected.get('selected_supplier')}
              supplierName={selected.getIn(['selected_supplier', 'supplier', 'name'])}
              supplierId={selected.getIn(['selected_supplier', 'supplier', 'id'])}
              dailyRate={selected.getIn(['selected_supplier', 'daily_rate'])}
              weeklyRate={selected.getIn(['selected_supplier', 'weekly_rate'])}
              monthlyRate={selected.getIn(['selected_supplier', 'monthly_rate'])}
              transport={selected.getIn(['selected_supplier', 'transport'])}
              notes={selected.getIn(['selected_supplier', 'notes'])}
              loading={loading}
              checked={true}
            />
          </>
        )}
        {selected.get('selected_transport_supplier') && (
          <>
            <SectionHeader title={t('common:resources.transportSuppliers.singular')} fullSize />
            <Divider spacing={20} />
            <SuppliersInformation
              id={selected.getIn(['selected_transport_supplier', 'id'])}
              supplierRate={selected.get('selected_transport_supplier')}
              supplierName={selected.getIn(['selected_transport_supplier', 'supplier', 'name'])}
              supplierId={selected.getIn(['selected_transport_supplier', 'supplier', 'id'])}
              dailyRate={selected.getIn(['selected_transport_supplier', 'daily_rate'])}
              weeklyRate={selected.getIn(['selected_transport_supplier', 'weekly_rate'])}
              monthlyRate={selected.getIn(['selected_transport_supplier', 'monthly_rate'])}
              transport={selected.getIn(['selected_transport_supplier', 'transport'])}
              notes={selected.getIn(['selected_transport_supplier', 'notes'])}
              loading={loading}
              checked={true}
            />
          </>
        )}
        <Divider spacing={20} />
      </div>
    </StyledCollapsible>
  )
}

OrderItemDetail.propTypes = {
  selected: PropTypes.instanceOf(Map),
  loading: PropTypes.bool,
  open: PropTypes.bool
}

OrderItemDetail.defaultProps = {
  selected: new Map(),
  loading: false,
  open: false
}

export default OrderItemDetail
