import React from 'react'
import { DateFilter } from 'components/common'
import { SERVICE_REQUEST } from 'constants/resources'


function Filters() {
  return (
    <DateFilter
      resource={SERVICE_REQUEST}
      id="created"
      attribute="created"
      label="Creation date"
      icon="today"
    />
  )
}

export default Filters
