import React, { useState } from 'react'
import { Input } from 'components/common'
import { useTranslation } from 'react-i18next'
import { Configure } from 'react-instantsearch-dom'
import PropTypes from 'prop-types'
import { useAlgoliaSelectOptions } from 'components/hooks'
function AutoCompleteSelect({
  hits,
  value,
  attributesToRetrieve,
  objectName,
  keyLabel,
  configureFilter,
  configureHitsPerPage,
  ...otherProps
}) {
  const { t } = useTranslation(objectName)
  const [query, setQuery] = useState(value || '')
  const options = useAlgoliaSelectOptions(hits, value, keyLabel)
  const handleInputChange = (event, data) => {
    setQuery(data)
    return data
  }
  return (
    <>
      <Configure
        filters={configureFilter}
        query={query}
        hitsPerPage={configureHitsPerPage}
        attributesToRetrieve={attributesToRetrieve}
      />
      <Input
        name={objectName}
        label={t('select.label')}
        placeholder={t('select.placeholder')}
        type="select"
        value={value}
        onInputChange={handleInputChange}
        options={options}
        {...otherProps}
      />
    </>
  )
}

AutoCompleteSelect.propTypes = {
  value: PropTypes.string,
  hits: PropTypes.array,
  attributesToRetrieve: PropTypes.array,
  objectName: PropTypes.string,
  label: PropTypes.string,
  configureFilter: PropTypes.string,
  configureHitsPerPage: PropTypes.number,
}

export default AutoCompleteSelect
