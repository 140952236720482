import { SUPPLIER_RATE } from 'constants/resources'
import { SUPPLIER_RATE_CREATE_WITH_TRANSPORT, SELECT_SUPPLIER_RATE } from 'store/actions/supplierRate'
import createActionsWithResource, { createAction } from './factory'

const supplierRates = createActionsWithResource(SUPPLIER_RATE)

export default {
  ...supplierRates,
  createWithTransport: createAction(SUPPLIER_RATE_CREATE_WITH_TRANSPORT),
  selectSupplierRate: createAction(SELECT_SUPPLIER_RATE)
}
