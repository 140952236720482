export const formatSentence = (sentence) => {
    if (!sentence) return sentence
    const words = sentence.split(' ')
    const formattedSentence = words.map((word) => { 
        return word[0] + word.substring(1).toLowerCase(); 
    }).join(' ')
    return formattedSentence
}

export default word => (word ? word.charAt(0).toUpperCase() + word.slice(1) : '')
