import { useState, useEffect } from 'react'
import { useAlgolia } from 'components/hooks'

function useAlgoliaSearch(resource, filters) {
  const [items, setItems] = useState(0)
  const searchClient = useAlgolia()
  const index = searchClient.initIndex(resource)
  useEffect(() => {
    index.search('', filters).then((hits) => {
      setItems(hits.nbHits)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return items
}

export default useAlgoliaSearch
