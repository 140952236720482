import api from 'config/axios'
import { buildQueryString } from 'utils'

export const list = async (filters) => api.get(`/app/service_requests/items/?${buildQueryString(filters)}`)

export const get = async (id) => api.get(`/app/service_requests/items/${id}/`)

export const create = async (serviceRequestItemList) => api.post('/app/service_requests/items/', serviceRequestItemList)

export const update = async (
  id,
  { service_request, equipment, daily_rate, weekly_rate, monthly_rate, rental_protection, environmental_fee, metadata, quantity, transport }
) =>
  api.patch(`/app/service_requests/items/${id}/`, {
    service_request,
    equipment,
    quantity,
    daily_rate,
    weekly_rate,
    monthly_rate,
    rental_protection,
    environmental_fee,
    transport,
    metadata
  })

export const destroy = async (id) => api.delete(`/app/service_requests/items/${id}/`)
