import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import {
  OFF_RENT, ORDER, PURCHASE_PRICE,
  RENTAL_PRICE, SERVICE_CALL, EXTRA,
} from 'constants/designations'
import ServiceCallForm from './ServiceCallForm'
import ExtraForm from './ExtraForm'
import OffRentForm from './OffRentForm'
import PurchasePriceForm from './PurchasePriceForm'
import RentalPriceForm from './RentalPriceForm'
import OrderForm from './OrderForm'

function Form({
  current,
  loading,
  update,
}) {
  if (current.get('designation') === OFF_RENT) return <OffRentForm current={current} loading={loading} update={update} />
  if (current.get('designation') === PURCHASE_PRICE) return <PurchasePriceForm current={current} loading={loading} update={update} />
  if (current.get('designation') === ORDER) return <OrderForm current={current} loading={loading} update={update} />
  if (current.get('designation') === RENTAL_PRICE) return <RentalPriceForm current={current} loading={loading} update={update} />
  if (current.get('designation') === SERVICE_CALL) return <ServiceCallForm current={current} loading={loading} update={update} />
  if (current.get('designation') === EXTRA) return <ExtraForm current={current} loading={loading} update={update} />

  return null
}

Form.propTypes = {
  loading: PropTypes.bool,
  update: PropTypes.bool,
  current: PropTypes.instanceOf(Map),
}

Form.defaultProps = {
  loading: false,
  update: false,
  current: new Map(),
}

export default Form
