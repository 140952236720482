import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useCurrent } from 'components/hooks'
import PropTypes from 'prop-types'
import { Divider } from 'components/ui'
import styled from 'styled-components'
import Information from './detail/Information'
import Header from './detail/Header'
import { useTranslation } from 'react-i18next'
import { SectionHeader } from 'components/common'
import TransportInformation from './detail/TransportInformation'
import SuppliersInformation from './detail/SuppliersInformation'
import { serviceRequestItems, serviceRequests, supplierRates } from 'store/action-creators'
import { SERVICE_REQUEST } from 'constants/resources'

const StyledCollapsible = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.primary200};
  padding: 1em 0;
  .collapsible-visible {
    cursor: pointer;
  }
  .collapsible-content {
    box-sizing: border-box;
    transition: 300ms ease-out;
    max-height: ${({ open }) => (open ? undefined : 1)}px;
    overflow: hidden;
  }
  &:last-child {
    margin-bottom: 20px;
  }
`

function ServiceRequestItemDetail({ selected, loading }) {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const id = selected.get('id')
  const currentServiceRequest = useCurrent(SERVICE_REQUEST)
  const allSuppliers = selected.get('supplier_rates') ?? []
  const transportSuppliers = allSuppliers
    .filter((supplier) => supplier.getIn(['metadata', 'isTransportSupplier']))
    .sort((supplierA, supplierB) => {
      if (supplierA.get('is_selected_transport') && !supplierB.get('is_selected_transport')) {
        return -1
      }
      if (!supplierA.get('is_selected_transport') && supplierB.get('is_selected_transport')) {
        return 1
      }
      return 0
    })
  const itemSuppliers = allSuppliers
    .filter((supplier) => !supplier.getIn(['metadata', 'isTransportSupplier']))
    .sort((supplierA, supplierB) => {
      if (supplierA.get('is_selected') && !supplierB.get('is_selected')) {
        return -1
      }
      if (!supplierA.get('is_selected') && supplierB.get('is_selected')) {
        return 1
      }
      return 0
    })
  const [open, setOpen] = useState(false)

  const onSelectSupplier = (supplierRateId) => {
    const serviceRequest = currentServiceRequest.toJS()
    const itemIndex = currentServiceRequest.get('service_request_items').findIndex((item) => item.get('id') === selected.get('id'))
    const supplierToUnselect = itemSuppliers.find((supplier) => supplier.get('is_selected'))
    const supplierToSelect = itemSuppliers.find((supplier) => supplier.get('id') === supplierRateId)
    const body = {
      supplierToSelectId: supplierToSelect.get('id'),
      ...(supplierToUnselect && { supplierToUnselectId: supplierToUnselect.get('id') }),
      isTransport: false
    }
    dispatch(
      supplierRates.selectSupplierRate(body, () => {
        let updatedSupplierRates = serviceRequest.service_request_items[itemIndex].supplier_rates.map((supplier) => {
          if (supplier.id === supplierRateId) {
            return { ...supplier, is_selected: true }
          }
          if (supplier.is_selected) {
            return { ...supplier, is_selected: false }
          }
          return supplier
        })
        updatedSupplierRates = updatedSupplierRates.sort((supplierA, supplierB) => {
          if (supplierA.is_selected) return -1
          if (supplierB.is_selected) return 1
          return 0
        })
        serviceRequest.service_request_items[itemIndex].supplier_rates = updatedSupplierRates
        dispatch(serviceRequestItems.setCurrentId(id))
        dispatch(serviceRequestItems.setCurrentData(serviceRequest.service_request_items[itemIndex]))
        dispatch(serviceRequests.setCurrentData(serviceRequest))
      })
    )
  }

  const onSelectTransportSupplier = (supplierRateId) => {
    const serviceRequest = currentServiceRequest.toJS()
    const itemIndex = currentServiceRequest.get('service_request_items').findIndex((item) => item.get('id') === selected.get('id'))
    const supplierToUnselect = transportSuppliers.find((supplier) => supplier.get('is_selected_transport'))
    const supplierToSelect = transportSuppliers.find((supplier) => supplier.get('id') === supplierRateId)
    const body = {
      supplierToSelectId: supplierToSelect.get('id'),
      ...(supplierToUnselect && { supplierToUnselectId: supplierToUnselect.get('id') }),
      isTransport: true
    }
    dispatch(
      supplierRates.selectSupplierRate(body, () => {
        let updatedSupplierRates = serviceRequest.service_request_items[itemIndex].supplier_rates.map((supplier) => {
          if (supplier.id === supplierRateId) {
            return { ...supplier, is_selected_transport: true }
          }
          if (supplier.is_selected_transport) {
            return { ...supplier, is_selected_transport: false }
          }
          return supplier
        })
        updatedSupplierRates = updatedSupplierRates.sort((supplierA, supplierB) => {
          if (supplierA.is_selected_transport) return -1
          if (supplierB.is_selected_transport) return 1
          return 0
        })
        serviceRequest.service_request_items[itemIndex].supplier_rates = updatedSupplierRates
        dispatch(serviceRequestItems.setCurrentId(id))
        dispatch(serviceRequestItems.setCurrentData(serviceRequest.service_request_items[itemIndex]))
        dispatch(serviceRequests.setCurrentData(serviceRequest))
      })
    )
  }

  return (
    <StyledCollapsible open={open}>
      <div className="collapsible-visible" data-cy="sr-items-details">
        <div onClick={() => setOpen(!open)}>
          <Header
            small
            isEdit
            status={selected.get('status')}
            code={selected.get('code')}
            serviceRequestItemId={id}
            serviceRequestId={selected.getIn(['service_request', 'id'])}
            serviceRequestItem={selected}
            equipmentName={selected.getIn(['equipment', 'name'])}
            loading={loading}
          />
        </div>
      </div>
      <div className="collapsible-content">
        <Divider spacing={20} />
        <Information
          equipmentName={selected.getIn(['equipment', 'name'])}
          equipmentId={selected.getIn(['equipment', 'id'])}
          quantity={selected.get('quantity')}
          dailyRate={selected.get('daily_rate')}
          weeklyRate={selected.get('weekly_rate')}
          monthlyRate={selected.get('monthly_rate')}
          rentalProtection={selected.get('rental_protection')}
          envCompensation={selected.get('environmental_fee')}
          code={selected.get('code')}
          internalCode={selected.get('internal_code')}
          loading={loading}
        />
        {selected.get('transport') && selected.get('transport').size > 0 && (
          <>
            <SectionHeader title={t('common:resources.transport.singular')} fullSize />
            {[...Array(selected.get('transport').size)].map((x, i) => {
              return (
                <TransportInformation
                  key={selected.getIn(['transport', i, 'type']) + selected.getIn(['transport', i, 'value'])}
                  type={selected.getIn(['transport', i, 'type'])}
                  rate={selected.getIn(['transport', i, 'value'])}
                  loading={loading}
                />
              )
            })}
          </>
        )}

        {itemSuppliers.size > 0 && (
          <>
            <SectionHeader title={t('common:resources.suppliers.plural')} fullSize />
            <Divider spacing={20} />
            {[...Array(itemSuppliers.size)].map((_, i) => {
              return (
                <div key={itemSuppliers.getIn([i, 'id'])}>
                  <SuppliersInformation
                    supplier={itemSuppliers.get(i)}
                    supplierName={itemSuppliers.getIn([i, 'supplier', 'name'])}
                    supplierId={itemSuppliers.getIn([i, 'supplier', 'id'])}
                    dailyRate={itemSuppliers.getIn([i, 'daily_rate'])}
                    weeklyRate={itemSuppliers.getIn([i, 'weekly_rate'])}
                    monthlyRate={itemSuppliers.getIn([i, 'monthly_rate'])}
                    transport={itemSuppliers.getIn([i, 'transport'])}
                    notes={itemSuppliers.getIn([i, 'notes'])}
                    loading={itemSuppliers.getIn([i, 'loading'])}
                    checked={itemSuppliers.getIn([i, 'is_selected'])}
                    onSelect={() => onSelectSupplier(itemSuppliers.getIn([i, 'id']))}
                  />
                  <Divider spacing={30} />
                </div>
              )
            })}
          </>
        )}

        {transportSuppliers.size > 0 && (
          <>
            <SectionHeader title={t('common:resources.transportSuppliers.singular')} fullSize />
            <Divider spacing={20} />
            {[...Array(transportSuppliers.size)].map((_, i) => {
              return (
                <div key={transportSuppliers.getIn([i, 'id'])}>
                  <SuppliersInformation
                    supplier={transportSuppliers.get(i)}
                    supplierName={transportSuppliers.getIn([i, 'supplier', 'name'])}
                    supplierId={transportSuppliers.getIn([i, 'supplier', 'id'])}
                    dailyRate={transportSuppliers.getIn([i, 'daily_rate'])}
                    weeklyRate={transportSuppliers.getIn([i, 'weekly_rate'])}
                    monthlyRate={transportSuppliers.getIn([i, 'monthly_rate'])}
                    transport={transportSuppliers.getIn([i, 'transport'])}
                    notes={transportSuppliers.getIn([i, 'notes'])}
                    loading={transportSuppliers.getIn([i, 'loading'])}
                    checked={transportSuppliers.getIn([i, 'is_selected_transport'])}
                    onSelect={() => onSelectTransportSupplier(transportSuppliers.getIn([i, 'id']))}
                  />
                  <Divider spacing={30} />
                </div>
              )
            })}
          </>
        )}
      </div>
    </StyledCollapsible>
  )
}

ServiceRequestItemDetail.propTypes = {
  selected: PropTypes.instanceOf(Map),
  loading: PropTypes.bool,
  open: PropTypes.bool
}

ServiceRequestItemDetail.defaultProps = {
  selected: new Map(),
  loading: false,
  open: false
}

export default ServiceRequestItemDetail
