import React from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from 'components/ui'
import { Flex } from 'components/common'
import { useTheme } from 'components/hooks'
import PropTypes from 'prop-types'
import {
  ACCOUNT_REQUEST, RESERVATION_CANCELLATION, RESERVATION,
  SERVICE_CALL, OFF_RENT, UPDATE, REMINDER,
  PURCHASE_PRICE, RENTAL_PRICE, ORDER, ASSIGNED,
  EXTRA, ACCOUNT_DELETE
} from 'constants/designations'
import { NOTIFICATION } from 'constants/resources'

function Designation({ name, secondary }) {
  const { t } = useTranslation(NOTIFICATION)
  const [theme] = useTheme()
  const primary = secondary ? theme.color.primary : theme.color.secondary
  let icon
  let color
  switch (name) {
    case OFF_RENT: icon = 'flag'; color = primary; break
    case SERVICE_CALL: icon = 'phone'; color = primary; break
    case EXTRA: icon = 'warning'; color = primary; break
    case RESERVATION: icon = 'move_to_inbox'; color = primary; break
    case RESERVATION_CANCELLATION: icon = 'not_interested'; color = theme.color.accent700; break
    case ACCOUNT_REQUEST: icon = 'person_add'; color = primary; break
    case ACCOUNT_DELETE: icon = 'person_remove'; color = primary; break
    case UPDATE: icon = 'update'; color = primary; break
    case REMINDER: icon = 'touch_app'; color = primary; break
    case PURCHASE_PRICE: icon = 'request_quote'; color = primary; break
    case RENTAL_PRICE: icon = 'request_quote'; color = primary; break
    case ORDER: icon = 'request_quote'; color = primary; break
    case ASSIGNED: icon = 'person_add'; color = primary; break
    default: icon = ''; break
  }
  return (
    <Flex align="center">
      <Icon
        name={icon}
        color={color}
        size={18}
        style={{ marginRight: 8 }}
      />
      <span>{t(`designations.${name}`)}</span>
    </Flex>
  )
}


Designation.propTypes = {
  name: PropTypes.string.isRequired,
  secondary: PropTypes.bool,
}
export default Designation
