import React from 'react'
import { Input } from 'components/common'
import canadianStates from 'data/ca-states.json'
import usStates from 'data/us-states.json'
import { useTranslation } from 'react-i18next'

function StateSelect(props) {
  const { t } = useTranslation('common')
  return (
    <Input
      type="select"
      name="state"
      label={t('address.state.label')}
      placeholder="Quebec"
      options={[
        ...canadianStates,
        ...usStates,
      ]}
      {...props}
    />
  )
}

export default StateSelect
