import React, { useMemo } from 'react'
import {
  InlineTable, TableRow, TableHeader, Flex,
} from 'components/common'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { list as listNotifications } from 'services/notifications'
import { NOTIFICATION } from 'constants/resources'
import { usePaginatedList, useQuickPreview } from 'components/hooks'
import { Grid } from '@material-ui/core'
import { NotificationAcknowledgment, NotificationDesignation } from 'components/pages/notifications'
import moment from 'moment'
import { SHOW } from 'constants/crud'
import { useDispatch } from 'react-redux'
import { notifications } from 'store/action-creators'
import { DATETIME } from 'constants/formats'

function NotificationList({ companyId }) {
  const dispatch = useDispatch()
  const [openPreview] = useQuickPreview()
  const { t } = useTranslation([NOTIFICATION, 'common', 'orderItem'])
  const templateColumns = '1fr 1fr 0.5fr'
  const options = useMemo(() => (companyId ? ({
    company: companyId,
    limit: 5,
  }) : null), [companyId])
  const {
    list, hasMore, hasPrevious, refineNext, refinePrevious, loading, count,
  } = usePaginatedList(listNotifications, options)

  const handleRowClick = (data) => {
    dispatch(notifications.setCurrentData(data))
    openPreview(NOTIFICATION, SHOW)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <InlineTable
          title={`${t('company:notificationList.header')} (${count})`}
          header={(
            <TableHeader
              fullSize
              templateColumns={templateColumns}
            >
              <p>{t('common:shared.type')}</p>
              <p>{t('common:shared.createdAt')}</p>
              <p />
            </TableHeader>
          )}
          onNext={refineNext}
          onPrevious={refinePrevious}
          hasNext={hasMore}
          hasPrevious={hasPrevious}
          loading={loading}
        >
          {
          list.map(item => (
            <TableRow
              key={item.get('id')}
              fullSize
              templateColumns={templateColumns}
              onClick={() => { handleRowClick(item) }}
            >
              <NotificationDesignation
                name={item.get('designation')}
              />
             <span>{moment(item.get('created')).format(DATETIME)}</span>
             <Flex justify="center">
                <NotificationAcknowledgment
                  acknowledgedAt={item.get('acknowledgedAt')}
                  acknowledgedBy={item.get('acknowledgedBy')}
                  id={item.get('id')}
                />
              </Flex>
            </TableRow>
          ))
        }
        </InlineTable>
      </Grid>
    </Grid>
  )
}

NotificationList.propTypes = {
  companyId: PropTypes.string,
}

NotificationList.defaultProps = {
  companyId: '',
}

export default NotificationList
