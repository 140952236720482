import React from 'react'
import { TableHeader, TableCell, Sort } from 'components/common'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ORDER_ITEM } from 'constants/resources'
import { useResource, useDeviceColumns } from 'components/hooks'
import {
  ORDER_ITEM_INDEX,
  ORDER_ITEM_OFFRENTEDAT_ASC_INDEX,
  ORDER_ITEM_OFFRENTEDAT_DESC_INDEX,
  ORDER_ITEM_STARTDATE_ASC_INDEX,
  ORDER_ITEM_STARTDATE_DESC_INDEX,
  ORDER_ITEM_BILLINGDATE_ASC_INDEX,
  ORDER_ITEM_BILLINGDATE_DESC_INDEX
} from 'constants/algolia'
import { useDispatch } from 'react-redux'
import { orderItems } from 'store/action-creators'
import { connectSortBy } from 'react-instantsearch-dom'

function OrderItemHeader({ columns, refine }) {
  const dispatch = useDispatch()
  const { t } = useTranslation(['common', ORDER_ITEM])
  const selected = useResource(ORDER_ITEM, 'current', 'id')
  const currentIndex = useResource(ORDER_ITEM, 'list', 'search', 'index')
  const templateColumns = useDeviceColumns(columns, !!selected)

  const handleSortClick = (value) => {
    dispatch(orderItems.setListIndex(value))
    if (refine) refine(value)
  }

  return (
    <TableHeader templateColumns={templateColumns}>
      <TableCell md>
        <p>{t('common:shared.id')}</p>
      </TableCell>
      <TableCell sm>
        <p>{t('common:shared.status')}</p>
      </TableCell>
      <TableCell md>
        <p>{t('common:resources.companies.singular')}</p>
      </TableCell>
      <TableCell xl={!selected}>
        <p>{t('common:shared.address')}</p>
      </TableCell>
      <TableCell sm>
        <p>{t('common:resources.equipment.singular')}</p>
      </TableCell>
      <TableCell md>
        <p>{t('common:shared.quantity')}</p>
      </TableCell>
      <TableCell sm>
        <Sort
          onClick={handleSortClick}
          currentIndex={currentIndex}
          defaultIndex={ORDER_ITEM_INDEX}
          ascendantIndex={ORDER_ITEM_STARTDATE_ASC_INDEX}
          descendantIndex={ORDER_ITEM_STARTDATE_DESC_INDEX}
        >
          <p>{t('common:shared.startDate')}</p>
        </Sort>
      </TableCell>
      <TableCell md>
        <Sort
          onClick={handleSortClick}
          currentIndex={currentIndex}
          defaultIndex={ORDER_ITEM_INDEX}
          ascendantIndex={ORDER_ITEM_BILLINGDATE_ASC_INDEX}
          descendantIndex={ORDER_ITEM_BILLINGDATE_DESC_INDEX}
        >
          <p>{t('order:shared.billingDate')}</p>
        </Sort>
      </TableCell>
      <TableCell lg={!selected}>
        <Sort
          onClick={handleSortClick}
          currentIndex={currentIndex}
          defaultIndex={ORDER_ITEM_INDEX}
          ascendantIndex={ORDER_ITEM_OFFRENTEDAT_ASC_INDEX}
          descendantIndex={ORDER_ITEM_OFFRENTEDAT_DESC_INDEX}
        >
          <p>{t('orderItem:shared.offRented')}</p>
        </Sort>
      </TableCell>
      <TableCell lg={!selected}>
        <p>{t('common:shared.salesRep')}</p>
      </TableCell>
      <TableCell lg={!selected}>
        <p>{t('common:shared.handledBy')}</p>
      </TableCell>
    </TableHeader>
  )
}

OrderItemHeader.propTypes = {
  columns: PropTypes.object.isRequired,
  refine: PropTypes.func
}

OrderItemHeader.defaultProps = {
  refine: null
}

export default connectSortBy(OrderItemHeader)
