import React, { useEffect, useState } from 'react'
import { SERVICE_REQUEST, SERVICE_REQUEST_MESSAGE } from 'constants/resources'
import {
  TableLoading, SectionHeader, Chip, Message,
} from 'components/common'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { List, Map } from 'immutable'
import { useDispatch, useSelector } from 'react-redux'
import { serviceRequestMessages } from 'store/action-creators'
import { useQuickPreview } from 'components/hooks'
import { NEW } from 'constants/crud'
import styled from 'styled-components'
import StatusUpdate from './StatusUpdate'
import useNotification from 'components/hooks/use-notification'

const UpdatesContainer = styled.div`
  > * {
    margin-bottom: 16px;
  }
`

function UpdatesHistory({
  messages,
  statusHistory,
  stateHistory,
  loading,
  serviceRequestId,
}) {
  const [ordered, setOrdered] = useState(new List())
  const { t } = useTranslation(['common', SERVICE_REQUEST])
  const dispatch = useDispatch()
  const [openPreview] = useQuickPreview()
  const notify = useNotification()
  const authId = useSelector(state => state.getIn(['session', 'user', 'id']))
  const currentRequest = useSelector(state => state.getIn(['serviceRequest', 'current']))
  const designation = currentRequest.getIn(['data', 'designation'])
  const items = currentRequest.getIn(['data', 'service_request_items'])

  const handleNewMessage = () => {
    dispatch(serviceRequestMessages.setCreateData({
      service_request: {
        id: serviceRequestId,
      },
    }))
    openPreview(SERVICE_REQUEST_MESSAGE, NEW)
  }

  const itemHasValidRate = (index) => {
    return items.getIn([index, 'daily_rate']) || items.getIn([index, 'weekly_rate']) || items.getIn([index, 'monthly_rate'])
  }

  const autoGenerateQuote = () => {
    let item_id_list = []
    for(let i = 0; i < items.size; i++) {
      item_id_list.push(items.getIn([i, 'id']))
    }
    if (items.every((item, index) => itemHasValidRate(index))) {
      const data = {
        'content': 'AUTO_GENERATED_PDF',
        'service_request': currentRequest.get('id'),
        'item_id_list': item_id_list,
      }
      dispatch(serviceRequestMessages.createQuote({data}))
     } else {
      notify({
        type: 'error',
        text: t('serviceRequest:pdf.missingInformation'),
      })
     }
  }
  useEffect(() => {
    if (messages || statusHistory || stateHistory) {
      // State history is deprecated. Messages are the new format. This is legacy code to support messages before the new implementation.
      const normalizedStatusHistory = statusHistory.map(status => (
        status
          .set('created', status.get('timestamp'))
          .set('created_by', new Map({
            name: status.get('actor_name'),
            id: status.get('actor_id'),
          }))))
      const normalizedStateHistory = stateHistory.map(status => (
        status
          .set('content', status.get('description'))
          .set('created', status.get('timestamp'))
          .set('created_by', new Map({
            name: status.get('actor_name'),
            id: status.get('actor_id'),
          }))))
      const records = messages.concat(normalizedStatusHistory).concat(normalizedStateHistory)
      const orderedList = records.sort((a, b) => {
        if (a.get('created') > b.get('created')) { return -1 }
        if (a.get('created') < b.get('created')) { return 1 }
        if (a.get('created') === b.get('created')) { return 0 }
        return 0
      })
      setOrdered(orderedList)
    }
  }, [messages, statusHistory, stateHistory])

  return (
    <div>
      <SectionHeader title={t('serviceRequest:timeline.header')} fullSize>
      {
          designation === 'rental-price' ||
          designation === 'order' ? (
            <Chip
              outline
              icon="receipt_long"
              onClick={autoGenerateQuote}
              label={t('serviceRequest:pdf.generate')}
            />
            ) : null
          }
        <Chip
          outline
          icon="mode"
          onClick={handleNewMessage}
          label={t('serviceRequest:shared.postUpdate')}
        />
      </SectionHeader>
      <br />
      <TableLoading loading={loading} rows={3} fullSize />
      <UpdatesContainer>
        {
          (ordered && !loading) ? ordered.map(update => (update.get('new_value')
            ? (
              <StatusUpdate
                status={update.get('new_value')}
                timestamp={update.get('created')}
              />
            )
            : (
              <Message
                isSender={update.getIn(['created_by', 'id']) === authId}
                timestamp={update.get('created')}
                senderName={update.getIn(['created_by', 'name'])}
                senderId={update.getIn(['created_by', 'id'])}
                attachment={update.get('attachment')}
                content={update.get('content')}
              />
            ))) : null
      }
      </UpdatesContainer>
    </div>
  )
}

UpdatesHistory.propTypes = {
  messages: PropTypes.instanceOf(List),
  statusHistory: PropTypes.instanceOf(List),
  stateHistory: PropTypes.instanceOf(List),
  loading: PropTypes.bool,
  serviceRequestId: PropTypes.string.isRequired,
}

UpdatesHistory.defaultProps = {
  messages: new List(),
  statusHistory: new List(),
  stateHistory: new List(),
  loading: false,
}


export default UpdatesHistory
