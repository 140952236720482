import React from 'react'
import { ServiceRequestContent, ServiceRequestHeader, ServiceRequestControls } from 'components/pages/service-requests'
import { SERVICE_REQUEST } from 'constants/resources'
import { useTranslation } from 'react-i18next'
import { Table } from 'components/common'
import {
  SERVICE_REQUEST_INDEX,
  SERVICE_REQUEST_CREATED_ASC_INDEX,
  SERVICE_REQUEST_CREATED_DESC_INDEX,
} from 'constants/algolia'
import { withAlgolia } from 'components/hocs'

function ServiceRequestTable() {
  const { t } = useTranslation('common')
  const columns = {
    sm: '1fr 2fr',
    md: '1fr 2fr 2fr 2fr',
    lg: '1fr 2fr 2fr 2fr 2fr 2fr 1fr 1fr',
    xl: '1fr 2fr 2fr 2fr 2fr 2fr 1fr 1fr',
  }

  return (
    <Table
      title={t('resources.serviceRequests.plural')}
      controls={<ServiceRequestControls />}
      resource={SERVICE_REQUEST}
      header={(
        <ServiceRequestHeader
          columns={columns}
          defaultRefinement={SERVICE_REQUEST_INDEX}
          items={[
            SERVICE_REQUEST_CREATED_ASC_INDEX,
            SERVICE_REQUEST_CREATED_DESC_INDEX,
          ]}
        />
        )}
      content={(
        <ServiceRequestContent
          columns={columns}
        />
      )}
    />
  )
}

export default withAlgolia(SERVICE_REQUEST_INDEX)(ServiceRequestTable)
