import React from 'react'
import { SERVICE_REQUEST } from 'constants/resources'
import { useSelector } from 'react-redux'
import { useResource } from 'components/hooks'
import { TableDetail } from 'components/common'
import Form from './Form'

function New() {
  const current = useSelector(state => state.getIn([SERVICE_REQUEST, 'create']))
  const loading = useResource(SERVICE_REQUEST, 'create', 'loading')

  return (
    <TableDetail>
      <Form
        loading={loading}
        current={current.get('data')}
      />
    </TableDetail>
  )
}


export default New
