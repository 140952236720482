import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroll-component'

const ContentStyled = styled(InfiniteScroll)`
  &::-webkit-scrollbar {
    display: none;
  }
`

function Content({
  children, next, dataLength, hasMore,
}) {
  const height = window.innerHeight - 100 - 90 - 50 - 20 - 30
  return (
    <ContentStyled
      height={height}
      data-cy="table-content"
      dataLength={dataLength}
      next={next}
      hasMore={hasMore}
    >
      {children}
    </ContentStyled>
  )
}

Content.propTypes = {
  next: PropTypes.func.isRequired,
  dataLength: PropTypes.number.isRequired,
  hasMore: PropTypes.bool.isRequired,
}

export default Content
