import { takeLatest, call, put } from 'redux-saga/effects'
import { SUPPLIER_RATE } from 'constants/resources'
import { LIST, GET, CREATE, UPDATE, DELETE } from 'store/actions/factory'
import { SUPPLIER_RATE_CREATE_WITH_TRANSPORT, SELECT_SUPPLIER_RATE } from 'store/actions/supplierRate'
import { create as createService, update as updateService } from 'services/supplier-rate'
import { NEW } from 'constants/crud'
import { supplierRates } from 'store/action-creators'
import notify from 'utils/notify'
import i18n from 'config/i18n'
import { get, createAppend, list, update, destroy } from './factory'
import nprogress from './nprogress'
import { closePreview } from './preview'

function* createWithTransport({ payload }) {
  yield call(nprogress, true)
  const { body, onSuccess, onError } = payload
  yield put(supplierRates.setUpdateLoading(true))
  const { item, transport } = body
  const itemResponse = yield call(createService, item)
  const transportResponse = yield call(createService, transport)
  if (itemResponse && transportResponse) {
    yield put(supplierRates.appendListItem(itemResponse))
    yield put(supplierRates.appendListItem(transportResponse))
    notify({
      text: i18n.t('common:shared.successCreate', { resource: itemResponse.internal_code })
    })
    yield call(closePreview, SUPPLIER_RATE, NEW)
    if (onSuccess) {
      yield call(onSuccess, [itemResponse, transportResponse])
      yield put(supplierRates.setUpdateLoading(false))
      yield call(nprogress, false)
    }
  } else if (onError) yield call(onError)
}

function* selectSupplierRate({ payload }) {
  yield call(nprogress, true)
  const { body, onSuccess, onError } = payload
  const { supplierToSelectId, supplierToUnselectId, isTransport } = body
  yield put(supplierRates.setUpdateLoading(true))
  if (supplierToUnselectId) {
    const unselectBody = isTransport ? { is_selected_transport: false } : { is_selected: false }
    yield call(updateService, supplierToUnselectId, unselectBody)
  }
  const selectBody = isTransport ? { is_selected_transport: true } : { is_selected: true }
  const selectResponse = yield call(updateService, supplierToSelectId, selectBody)
  if (selectResponse) {
    yield call(notify, {
      text: i18n.t('common:shared.successUpdate', { resource: selectResponse.internal_code })
    })
    if (onSuccess) yield call(onSuccess, selectResponse)
  } else if (onError) yield call(onError)
  yield call(nprogress, false)
  yield put(supplierRates.setUpdateLoading(false))
}

export default function* supplierRateSaga() {
  yield takeLatest(LIST(SUPPLIER_RATE), list)
  yield takeLatest(GET(SUPPLIER_RATE), get)
  yield takeLatest(CREATE(SUPPLIER_RATE), createAppend)
  yield takeLatest(UPDATE(SUPPLIER_RATE), update)
  yield takeLatest(DELETE(SUPPLIER_RATE), destroy)
  yield takeLatest(SUPPLIER_RATE_CREATE_WITH_TRANSPORT, createWithTransport)
  yield takeLatest(SELECT_SUPPLIER_RATE, selectSupplierRate)
}
