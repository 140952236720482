import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { supplierRates, serviceRequests } from 'store/action-creators'
import { Button, PriceField, Flex, OutlinedCard, SectionHeader, Checkbox, Input } from 'components/common'
import Grid from '@material-ui/core/Grid'
import { SupplierSelect } from 'components/pages/suppliers'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ORDER_ITEM, SERVICE_REQUEST_ITEM } from 'constants/resources'
import { Divider } from 'components/ui'
import { TransportRow } from '../service-request-items'
import { AddItemButton } from '../service-requests/ItemRow'
import { v4 as uuidv4 } from 'uuid'
import { SUPPLIER_RATE, SERVICE_REQUEST } from 'constants/resources'
import { useNotification, useCurrent } from 'components/hooks'

function SupplierForm({ update, loading, current }) {
  const notify = useNotification()
  const { t } = useTranslation(['common', ORDER_ITEM, 'error', 'transportConventions'])
  const itemId = useSelector(
    (state) => state.getIn([SERVICE_REQUEST_ITEM, 'current', 'id']) || state.getIn([SUPPLIER_RATE, 'current', 'data', 'service_request_item', 'id'])
  )
  const [transportData, setTransportData] = useState([])
  const [useTransportSupplier, setTransportSupplier] = useState(false)
  const dispatch = useDispatch()
  const { handleSubmit, control, errors, register } = useForm({ mode: 'onBlur' })
  const currentServiceRequest = useCurrent(SERVICE_REQUEST)

  useEffect(() => {
    if (update) {
      const transportData = current.getIn(['transport'])

      setTransportData(
        [...Array(transportData.size)].map((_, i) => {
          return {
            transport: transportData.getIn([i, 'type']),
            rate: transportData.getIn([i, 'value']),
            id: uuidv4()
          }
        })
      )
    } else {
      setTransportData([
        { transport: t('transportConventions:deliveryCharge'), rate: null, id: uuidv4() },
        { transport: t('transportConventions:pickupCharge'), rate: null, id: uuidv4() }
      ])
    }
  }, [t, current, update])

  const handleUpdateSuccess = (response) => {
    const itemIndex = currentServiceRequest.get('service_request_items').findIndex((item) => item.get('id') === response.service_request_item.id)
    const rateIndex = currentServiceRequest
      .getIn(['service_request_items', itemIndex, 'supplier_rates'])
      .findIndex((rate) => rate.get('id') === response.id)
    const serviceRequest = currentServiceRequest.toJS()
    serviceRequest.service_request_items[itemIndex].supplier_rates[rateIndex] = response
    dispatch(serviceRequests.setCurrentData(serviceRequest))
  }

  const handleCreateSuccess = (response) => {
    const itemIndex = currentServiceRequest.get('service_request_items').findIndex((item) => item.get('id') === response.service_request_item.id)
    const serviceRequest = currentServiceRequest.toJS()
    serviceRequest.service_request_items[itemIndex].supplier_rates.push(response)
    dispatch(serviceRequests.setCurrentData(serviceRequest))
  }

  const handleCreateWithTransportSuccess = (response) => {
    const itemIndex = currentServiceRequest
      .get('service_request_items')
      .findIndex((item) => item.get('id') === response[0].service_request_item.id && item.get('id') === response[1].service_request_item.id)
    const serviceRequest = currentServiceRequest.toJS()
    serviceRequest.service_request_items[itemIndex].supplier_rates = serviceRequest.service_request_items[itemIndex].supplier_rates.concat(response)
    dispatch(serviceRequests.setCurrentData(serviceRequest))
  }

  const itemHasValidRate = (data) => {
    return data.daily_rate || data.weekly_rate || data.monthly_rate
  }

  const supplierIsTransport = () => {
    return current.getIn(['metadata', 'isTransportSupplier'])
  }

  const onSubmit = (data) => {
    if ((useTransportSupplier && !data.transport_supplier) || (itemHasValidRate(data) && !data.supplier)) {
      notify({
        type: 'error',
        text: t('error:supplierRequired')
      })
      return
    }
    let body = {
      supplier: data.supplier,
      service_request_item: data.service_request_item,
      daily_rate: data.daily_rate,
      weekly_rate: data.weekly_rate,
      monthly_rate: data.monthly_rate,
      transport: transportData
        .filter((t) => t.rate !== null)
        .map((t) => {
          return { type: t.transport, value: t.rate }
        }),
      notes: data.notes,
      metadata: current.getIn(['metadata'])
    }
    if (update) {
      dispatch(supplierRates.update(body, handleUpdateSuccess))
    } else {
      if (useTransportSupplier) {
        const itemBody = {
          supplier: data.supplier,
          service_request_item: data.service_request_item,
          daily_rate: data.daily_rate,
          weekly_rate: data.weekly_rate,
          monthly_rate: data.monthly_rate,
          notes: data.notes
        }
        const transportBody = {
          supplier: data.transport_supplier,
          service_request_item: data.service_request_item,
          transport: transportData
            .filter((t) => t.rate !== null)
            .map((t) => {
              return { type: t.transport, value: t.rate }
            }),
          notes: data.notes,
          metadata: {
            isTransportSupplier: true
          }
        }
        if (itemHasValidRate(itemBody)) {
          dispatch(supplierRates.createWithTransport({ item: itemBody, transport: transportBody }, handleCreateWithTransportSuccess))
        } else {
          dispatch(supplierRates.create(transportBody, handleCreateSuccess))
        }
      } else {
        dispatch(supplierRates.create(body, handleCreateSuccess))
      }
    }
  }

  const onChange = useCallback((index, value, name) => {
    setTransportData((prevState) => {
      if (value) {
        return [
          ...prevState.slice(0, index),
          {
            ...prevState[index],
            [name]: value
          },
          ...prevState.slice(index + 1)
        ]
      } else {
        return [
          ...prevState.slice(0, index),
          {
            ...prevState[index],
            [name]: value === 0 ? null : ''
          },
          ...prevState.slice(index + 1)
        ]
      }
    })
  }, [])

  const onDelete = useCallback((index) => {
    setTransportData((prevState) => {
      let updatedArr = [...prevState]
      updatedArr.splice(index, 1)
      return prevState.length <= 0 ? [...prevState] : [...updatedArr]
    })
  }, [])

  const addTransportRow = () => {
    let updatedArr = [...transportData]
    updatedArr.push({
      transport: '',
      rate: null,
      id: uuidv4()
    })
    setTransportData(updatedArr)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex align="center" justify="space-between">
        <h1>{t('common:shared.information')}</h1>
      </Flex>
      <Divider spacing={20} />
      <input type="hidden" name="service_request_item" ref={register} defaultValue={itemId} />
      <Grid container spacing={2}>
        {!supplierIsTransport() && (
          <>
            <Grid item xs={12} md={12}>
              <h3>{t('common:resources.suppliers.singular')}</h3>
            </Grid>
            <Grid item xs={14} md={7}>
              <Controller
                as={SupplierSelect}
                name="supplier"
                id="new-sr-item-supplier"
                control={control}
                error={errors.supplier?.message}
                defaultValue={current.getIn(['supplier', 'id'])}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12} md={5} />
            <Grid item xs={4} md={4}>
              <Controller
                as={PriceField}
                name="daily_rate"
                control={control}
                error={errors.daily_rate?.message}
                label={t('common:shared.dailyRate')}
                placeholder="69.00"
                id="new-sr-item-supplier-daily-rate"
                defaultValue={current.get('daily_rate')}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <Controller
                as={PriceField}
                name="weekly_rate"
                control={control}
                error={errors.weekly_rate?.message}
                label={t('common:shared.weeklyRate')}
                placeholder="499.00"
                id="new-sr-item-supplier-weekly-rate"
                defaultValue={current.get('weekly_rate')}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <Controller
                as={PriceField}
                name="monthly_rate"
                control={control}
                error={errors.monthly_rate?.message}
                label={t('common:shared.monthlyRate')}
                placeholder="3999.00"
                id="new-sr-item-supplier-monthly-rate"
                defaultValue={current.get('monthly_rate')}
                disabled={loading}
              />
            </Grid>
          </>
        )}
        <SectionHeader title={t('common:resources.transport.singular')} />
        {!update && (
          <Grid item xs={14} md={7}>
            <Checkbox
              checked={useTransportSupplier}
              onChange={() => setTransportSupplier(!useTransportSupplier)}
              inputProps={{ 'aria-label': 'controlled' }}
              label={t('common:shared.useTransportSupplier')}
              data-cy="transport-supplier-checkbox"
            />
          </Grid>
        )}
        {(useTransportSupplier || supplierIsTransport()) && (
          <Grid item xs={14} md={7}>
            <Controller
              as={SupplierSelect}
              name="transport_supplier"
              id="new-sr-item-transport-supplier"
              control={control}
              error={errors.transport_supplier?.message}
              disabled={loading}
              defaultValue={current.getIn(['supplier', 'id'])}
            />
          </Grid>
        )}
        <Divider spacing={10} />
        <>
          {transportData?.map((transportItem, index) => (
            <OutlinedCard>
              <TransportRow
                key={transportItem.id}
                transport={transportItem.transport ?? ''}
                rate={transportItem.rate ?? null}
                idx={index}
                onChange={onChange}
                onDelete={onDelete}
                disabled={loading}
              />
            </OutlinedCard>
          ))}
          <AddItemButton disabled={loading} id="add-transport-row-btn" onClick={() => addTransportRow()} type="button">
            + {t('serviceRequest:items.add')}
          </AddItemButton>
        </>
        <Grid item xs={12} md={5} />
        <Divider spacing={25} />
        <Grid item xs={12} md={12}>
          <Controller
            as={Input}
            name="notes"
            control={control}
            error={errors.metadata?.notes?.message}
            type="text"
            label={t('common:shared.notes')}
            placeholder={t('order:new.notes.placeholder')}
            multiline
            id="new-company-notes"
            defaultValue={current.get('notes')}
            disabled={loading}
          />
        </Grid>
      </Grid>
      <Divider spacing={20} />
      <Button disabled={loading} type="submit" fullSize>
        {update ? t('common:shared.submit.edit') : t('common:shared.submit.new')}
      </Button>
    </form>
  )
}

export default SupplierForm
