import styled from 'styled-components'
import React from 'react'
import moment from 'moment'
import { USER } from 'constants/resources'
import PreviewLink from './PreviewLink'
import Attachment from './Attachment'
import { DATETIME } from 'constants/formats'

const Bubble = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-${({ align }) => (align === 'left' ? 'start' : 'end')};

  .message {
    width: 80%;
  }

  .message-bubble {
    white-space: pre-wrap;
    background-color: ${({ theme }) => theme.color.primary200};
    padding: 16px;
    border-radius: 8px 8px ${({ align }) => (align === 'left' ? '8px 0' : '0 8px')}; 
    word-break: break-all;
  }

  .message-img {
    max-width: 100%;
    max-height: 500px;
    border-radius: 8px;
  }
  
  .message-video {
    max-width: 100%;
    max-height: 500px;
    border-radius: 8px;
  }


  .message-information{ 
    font-size: 10px;
    margin-top: 4px;
    display: flex;
    justify-content: flex-${({ align }) => (align === 'left' ? 'start' : 'end')};
    align-items: center;
  }

  .message-timestamp {
    padding-left: 8px;
    opacity: 0.5;
  }
`

function Message({
  timestamp,
  senderName,
  senderId,
  content,
  attachment,
  isSender,
}) {
  const isURL = content && content.match(/^(http|https):\/\/[^ "]+$/)

  return (
    <Bubble align={isSender ? 'right' : 'left'}>
      <div className="message">
        <div className="message-bubble">
          <p className="message-content">{isURL ? <a target="_new" href={content}>{content}</a> : content}</p>
          <Attachment attachment={attachment} />
        </div>
        <div className="message-information">
          <p className="message-sender">
            {
              !isSender ? (
                <PreviewLink
                  id={senderId}
                  resource={USER}
                >
                  {senderName}
                </PreviewLink>
              ) : null
            }
          </p>
          {
            ' '
          }
          <p className="message-timestamp">
            {moment(timestamp).format(DATETIME)}
          </p>
        </div>
      </div>
    </Bubble>
  )
}

export default Message
