import { useState } from "react";

function useAttachment() {
  const [file, setFile] = useState(null)

  const attachmentPayload = (data) => {
    let formData
    if (file) {
      formData = new FormData()
      formData.append('attachment', file)
    }
    const body = {
      ...data,
      attachment: formData,
    }
    return body
  }

  return [attachmentPayload, setFile]
}

export default useAttachment
