import { call } from 'redux-saga/effects'
import services from 'services'
import nprogress from './nprogress'

export function* getStore({ payload = {}, resource }) {
  yield call(nprogress, true)
  const { body: id, onSuccess, onError } = payload
  const response = yield call(services[resource].get, id)
  if (response) {
    if (onSuccess) yield call(onSuccess, response)
  } else if (onError) yield call(onError)
  yield call(nprogress, false)
}
