import React from 'react'
import { SERVICE_REQUEST } from 'constants/resources'
import { Divider } from 'components/ui'
import {
  AddressSection, ContactSection, Destroy,
} from 'components/common'
import UpdatesHistory from './UpdatesHistory'
import ExtraInformation from './ExtraInformation'

function ExtraDetail({
  selected,
  loading,
  messages,
}) {
  return (
    <>
      <ExtraInformation
        internalCode={selected.get('internal_code')}
        status={selected.get('status')}
        designation={selected.get('designation')}
        createdAt={selected.get('created')}
        contractReference={selected.getIn(['metadata', 'contract_reference'])}
        contractReferenceId={selected.getIn(['metadata', 'contract_reference_id'])}
        companyName={selected.getIn(['metadata', 'company'])}
        companyId={selected.getIn(['metadata', 'company_id'])}
        occurrenceDate={selected.getIn(['metadata', 'occurrence_date'])}
        assigneeName={selected.getIn(['assignee', 'name'])}
        assigneeId={selected.getIn(['assignee', 'id'])}
        salesRepName={selected.getIn(['sales_rep', 'name'])}
        salesRepId={selected.getIn(['sales_rep', 'id'])}
        orderItemCode={selected.getIn(['order_item', 'internal_code'])}
        orderItemId={selected.getIn(['order_item', 'id'])}
        equipmentName={selected.getIn(['order_item', 'equipment', 'name'])}
        equipmentId={selected.getIn(['order_item', 'equipment', 'id'])}
        supplier={selected.getIn(['metadata', 'supplier'])}
        supplierId={selected.getIn(['metadata', 'supplier_id'])}
        serviceCallReference={selected.getIn(['metadata', 'service_call_reference'])}
        serviceCallReferenceId={selected.getIn(['metadata', 'service_call_reference_id'])}
        sentInvoiceNumber={selected.getIn(['metadata', 'sent_invoice_number'])}
        invoicesSent={selected.getIn(['metadata', 'invoices_sent'])}
        receivedInvoiceNumber={selected.getIn(['metadata', 'received_invoice_number'])}
        invoicesReceived={selected.getIn(['metadata', 'invoices_received'])}
        supplierCharge={selected.getIn(['metadata', 'supplier_charge'])}
        racCharge={selected.getIn(['metadata', 'rac_charge'])}
        notes={selected.get('notes')}
        opsNotes={selected.getIn(['metadata', 'opsNotes'])}
        loading={loading}
      />
      <Divider spacing={20} />
      <ContactSection
        list={selected.getIn(['metadata', 'contacts'])}
        loading={loading}
      />
      <Divider spacing={20} />
      <AddressSection
        street={selected.get('address_line1')}
        streetDetails={selected.get('address_line2')}
        city={selected.get('address_city')}
        state={selected.get('address_state')}
        zipcode={selected.get('address_zip')}
        country={selected.getIn(['address_country', 'name'])}
        loading={loading}
      />
      <Divider spacing={20} />
      <Divider spacing={20} />
      <UpdatesHistory
        messages={messages}
        statusHistory={selected.get('status_history')}
        stateHistory={selected.get('state_history')}
        loading={loading}
        serviceRequestId={selected.get('id')}
      />
      <Destroy resource={SERVICE_REQUEST} id={selected.get('id')} />
    </>
  )
}

export default ExtraDetail
