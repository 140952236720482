import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const XBtn = styled.button`
  color: ${({ theme }) => theme.color.accent700};
  background-color: transparent;
  display: block;
  float: right;
  border: 1.5px solid ${({ theme }) => theme.color.accent700};
  border-radius: 3.5px;
  font-size: 10px;
  margin: 10px 2em -5px 0px;
  font-weight: 700;
`

function DeleteXButton({ onDelete }) {
  return (
    <div style={{ display: 'block', width: '100%' }}>
      <div />
      <XBtn type="button" data-cy="quote-row-x-btn" onClick={onDelete}>X</XBtn>
    </div>
  )
}

DeleteXButton.propTypes = {
  onDelete: PropTypes.any,
}

DeleteXButton.defaultProps = {
  onDelete: null
}

export default DeleteXButton