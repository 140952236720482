import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Divider } from 'components/ui'
import { Button, Input, PriceField, PercentageField, Flex, OutlinedCard, SectionHeader } from 'components/common'
import { serviceRequestItems, serviceRequests } from 'store/action-creators'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import { EquipmentSelect } from 'components/pages/equipment'
import { useForm, Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { ORDER_ITEM, SERVICE_REQUEST } from 'constants/resources'
import { TransportRow } from 'components/pages/service-request-items'
import { AddItemButton } from 'components/pages/service-requests/ItemRow'
import { v4 as uuidv4 } from 'uuid'
import { useCurrent } from 'components/hooks'

function Form({ current, loading }) {
  const { t } = useTranslation(['common', ORDER_ITEM, 'error', 'transportConventions'])
  const [transportData, setTransportData] = useState([])
  const supplierRates = current.get('supplier_rates').toJS()
  const selectedSupplier = supplierRates.find((supplier) => supplier.is_selected)
  const selectedTransportSupplier = supplierRates.find((supplier) => supplier.is_transport_selected)
  const dispatch = useDispatch()
  const currentServiceRequest = useCurrent(SERVICE_REQUEST)
  const { handleSubmit, control, errors, register } = useForm({ mode: 'onBlur' })

  useEffect(() => {
    if (current.get('transport') && current.get('transport').size > 0) {
      setTransportData(
        [...Array(current.get('transport').size)].map((x, i) => {
          const transportCost = selectedSupplier?.transport[i]?.value || selectedTransportSupplier?.transport[i]?.value
          return {
            transport: current.getIn(['transport', i, 'type']),
            rate: current.getIn(['transport', i, 'value']),
            helperText: transportCost && t('common:resources.suppliers.singular') + ': $' + transportCost,
            id: uuidv4()
          }
        })
      )
    } else {
      setTransportData([
        { transport: t('transportConventions:deliveryCharge'), rate: null, id: uuidv4() },
        { transport: t('transportConventions:pickupCharge'), rate: null, id: uuidv4() }
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, current])

  const onSubmit = (data) => {
    let body = {
      service_request: data.service_request,
      equipment: data.equipment,
      quantity: data.quantity,
      daily_rate: data.daily_rate,
      weekly_rate: data.weekly_rate,
      monthly_rate: data.monthly_rate,
      rental_protection: data.rental_protection,
      environmental_fee: data.environmental_fee,
      transport: transportData
        .filter((t) => t.rate)
        .map((t) => {
          return { type: t.transport, value: t.rate }
        }),
      metadata: {}
    }
    const itemIndex = currentServiceRequest.get('service_request_items').findIndex((item) => item.get('id') === data.service_request_item)
    const serviceRequest = currentServiceRequest.toJS()
    serviceRequest.service_request_items[itemIndex] = current.toJS()
    dispatch(
      serviceRequestItems.update(body, (response) => {
        serviceRequest.service_request_items[itemIndex] = response
        dispatch(serviceRequests.setCurrentData(serviceRequest))
      })
    )
  }

  const onChange = useCallback((index, value, name) => {
    setTransportData((prevState) => {
      if (value) {
        return [
          ...prevState.slice(0, index),
          {
            ...prevState[index],
            [name]: value
          },
          ...prevState.slice(index + 1)
        ]
      } else {
        return [
          ...prevState.slice(0, index),
          {
            ...prevState[index],
            [name]: value === 0 ? NaN : ''
          },
          ...prevState.slice(index + 1)
        ]
      }
    })
  }, [])

  const onDelete = useCallback((index) => {
    setTransportData((prevState) => {
      let updatedArr = [...prevState]
      updatedArr.splice(index, 1)
      return prevState.length <= 0 ? [...prevState] : [...updatedArr]
    })
  }, [])

  const addTransportRow = () => {
    let updatedArr = [...transportData]
    updatedArr.push({
      transport: '',
      rate: null,
      id: uuidv4()
    })
    setTransportData(updatedArr)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex align="center" justify="space-between">
        <h1>{t('common:shared.information')}</h1>
      </Flex>
      <Divider spacing={20} />
      <input type="hidden" name="service_request" ref={register} defaultValue={current.getIn(['service_request', 'id'])} />
      <input type="hidden" name="service_request_item" ref={register} defaultValue={current.get('id')} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Controller
            as={EquipmentSelect}
            name="equipment"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('error:equipment.required')
              }
            }}
            error={errors.equipment?.message}
            id="new-sr-item-equipment"
            defaultValue={current.getIn(['equipment', 'id'])}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            name="quantity"
            type="number"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('error:quantity.required')
              }
            }}
            error={errors.quantity?.message}
            label={t('common:shared.quantity')}
            placeholder={t('orderItem:new.quantity.placeholder')}
            id="new-sr-item-quantity"
            defaultValue={current.get('quantity')}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={4} md={4}>
          <Controller
            as={PriceField}
            name="daily_rate"
            control={control}
            error={errors.daily_rate?.message}
            label={t('common:shared.dailyRate')}
            placeholder="69.00"
            id="new-sr-item-daily-rate"
            defaultValue={current.get('daily_rate')}
            disabled={loading}
            helperText={
              selectedSupplier && selectedSupplier.daily_rate && t('common:resources.suppliers.singular') + ': $' + selectedSupplier.daily_rate
            }
          />
        </Grid>
        <Grid item xs={4} md={4}>
          <Controller
            as={PriceField}
            name="weekly_rate"
            control={control}
            error={errors.weekly_rate?.message}
            label={t('common:shared.weeklyRate')}
            placeholder="499.00"
            id="new-sr-item-weekly-rate"
            defaultValue={current.get('weekly_rate')}
            disabled={loading}
            helperText={
              selectedSupplier && selectedSupplier.weekly_rate && t('common:resources.suppliers.singular') + ': $' + selectedSupplier.weekly_rate
            }
          />
        </Grid>
        <Grid item xs={4} md={4}>
          <Controller
            as={PriceField}
            name="monthly_rate"
            control={control}
            error={errors.monthly_rate?.message}
            label={t('common:shared.monthlyRate')}
            placeholder="3999.00"
            id="new-sr-item-monthly-rate"
            defaultValue={current.get('monthly_rate')}
            disabled={loading}
            helperText={
              selectedSupplier && selectedSupplier.monthly_rate && t('common:resources.suppliers.singular') + ': $' + selectedSupplier.monthly_rate
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={PercentageField}
            name="rental_protection"
            control={control}
            error={errors.rental_protection?.message}
            label={t('orderItem:shared.rentalProtection')}
            placeholder="15"
            id="new-sr-item-rental-protection"
            defaultValue={current.get('rental_protection')}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={PercentageField}
            name="environmental_fee"
            control={control}
            error={errors.environmental_fee?.message}
            label={t('orderItem:shared.envCompensation')}
            placeholder="2"
            id="new-sr-item-env-compensation"
            defaultValue={current.get('environmental_fee')}
            disabled={loading}
          />
        </Grid>
        <SectionHeader title={t('common:resources.transport.singular')} />
        <>
          {transportData?.map((transportItem, index) => (
            <OutlinedCard>
              <TransportRow
                key={transportItem.id}
                transport={transportItem.transport ?? ''}
                rate={transportItem.rate ?? ''}
                idx={index}
                onChange={onChange}
                onDelete={onDelete}
                helperText={transportItem.helperText}
              />
            </OutlinedCard>
          ))}
          <AddItemButton id="add-transport-row-btn" onClick={() => addTransportRow()} type="button">
            + {t('serviceRequest:items.add')}
          </AddItemButton>
        </>
      </Grid>
      <Divider spacing={20} />
      <Button type="submit" fullSize>
        {t('common:shared.submit.edit')}
      </Button>
    </form>
  )
}

Form.propTypes = {
  loading: PropTypes.bool,
  update: PropTypes.bool,
  current: PropTypes.instanceOf(Map)
}

Form.defaultProps = {
  loading: false,
  update: false,
  current: new Map()
}

export default Form
