import React from 'react'
import { Chip, Date, TableRow, PreviewLink, TableCell, AddressLink, FacetFilter } from 'components/common'
import { COMPANY, ORDER, EQUIPMENT, USER } from 'constants/resources'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { List } from 'immutable'
import { ACTIVE } from 'constants/status'

function OrderRow({
  internalCode,
  status,
  companyName,
  companyId,
  street,
  city,
  zipCode,
  state,
  country,
  startDate,
  deliveryDate,
  billingDate,
  active,
  shrink,
  onClick,
  templateColumns,
  statusColor,
  equipment,
  salesRepId,
  salesRepName,
  assigneeId,
  assigneeName,
  extraIdArray
}) {
  const { t } = useTranslation(['common', ORDER])
  const startDateFormatted = typeof startDate === 'string' ? startDate : startDate * 1000
  const deliveryDateFormatted = typeof deliveryDate === 'string' ? deliveryDate : deliveryDate * 1000

  return (
    <TableRow templateColumns={templateColumns} active={active} onClick={onClick}>
      <TableCell sm>
        <p>{internalCode}</p>
      </TableCell>
      <TableCell md>
        <FacetFilter
          filters={{
            attribute: 'status',
            value: status,
            label: t(`order:status.${status}`)
          }}
          resource={ORDER}
        >
          <Chip
            background={statusColor}
            secondary={active && !status === ACTIVE}
            icon={extraIdArray.size > 0 ? 'warning' : null}
            iconColor="inherit"
            label={t(`order:status.${status}`)}
          />
        </FacetFilter>
      </TableCell>
      <TableCell sm>
        <FacetFilter
          filters={{
            attribute: 'serialized_company.id',
            value: companyId,
            label: companyName
          }}
          resource={ORDER}
        >
          <PreviewLink hideMobile resource={COMPANY} id={companyId}>
            {companyName}
          </PreviewLink>
        </FacetFilter>
      </TableCell>
      <TableCell sm lg={!shrink}>
        <AddressLink city={city} zipCode={zipCode} state={state} country={country}>
          {street}
        </AddressLink>
      </TableCell>
      <TableCell lg={!shrink}>
        {equipment.map((machine) => (
          <FacetFilter
            filters={{
              attribute: 'serialized_equipment.id',
              value: machine.get('id'),
              label: machine.get('name')
            }}
            resource={ORDER}
          >
            <PreviewLink hideMobile resource={EQUIPMENT} id={machine.get('id')}>
              {machine.get('quantity')}x {machine.get('name')}
            </PreviewLink>
          </FacetFilter>
        ))}
      </TableCell>
      <TableCell md>
        <Date>{deliveryDateFormatted}</Date>
      </TableCell>
      <TableCell md>
        <Date>{startDateFormatted}</Date>
      </TableCell>
      <TableCell md>
        <Date>{billingDate}</Date>
      </TableCell>
      <TableCell lg={!shrink}>
        <FacetFilter
          filters={{
            attribute: 'serialized_sales_rep.id',
            value: salesRepId,
            label: salesRepName
          }}
          resource={ORDER}
        >
          <PreviewLink hideMobile resource={USER} id={salesRepId}>
            {salesRepName}
          </PreviewLink>
        </FacetFilter>
      </TableCell>
      <TableCell lg={!shrink}>
        <FacetFilter
          filters={{
            attribute: 'serialized_assignee.id',
            value: assigneeId,
            label: assigneeName
          }}
          resource={ORDER}
        >
          <PreviewLink hideMobile resource={USER} id={assigneeId}>
            {assigneeName}
          </PreviewLink>
        </FacetFilter>
      </TableCell>
    </TableRow>
  )
}

OrderRow.propTypes = {
  internalCode: PropTypes.string,
  salesRepId: PropTypes.string,
  salesRepName: PropTypes.string,
  status: PropTypes.string,
  statusColor: PropTypes.string,
  companyName: PropTypes.string,
  companyId: PropTypes.string,
  street: PropTypes.string,
  city: PropTypes.string,
  zipCode: PropTypes.string,
  state: PropTypes.string,
  country: PropTypes.string,
  startDate: PropTypes.string,
  deliveryDate: PropTypes.string,
  billingDate: PropTypes.string,
  active: PropTypes.bool,
  shrink: PropTypes.bool,
  onClick: PropTypes.func,
  equipment: PropTypes.instanceOf(List),
  templateColumns: PropTypes.string.isRequired,
  extraIdArray: PropTypes.array
}

OrderRow.defaultProps = {
  internalCode: '',
  salesRepId: '',
  salesRepName: '',
  status: '',
  statusColor: '',
  companyName: '',
  companyId: '',
  street: '',
  city: '',
  zipCode: '',
  state: '',
  country: '',
  startDate: '',
  deliveryDate: '',
  billingDate: '',
  active: false,
  shrink: false,
  onClick: null,
  equipment: new List(),
  extraIdArray: []
}

export default OrderRow
