import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Divider } from 'components/ui'
import { Button, Input, PriceField, PercentageField, Flex, OutlinedCard, SectionHeader } from 'components/common'
import { orderItems, orders } from 'store/action-creators'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import { EquipmentSelect } from 'components/pages/equipment'
import { TransportRow } from 'components/pages/service-request-items'
import { AddItemButton } from 'components/pages/service-requests/ItemRow'
import { useForm, Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import { Map, fromJS } from 'immutable'
import { ORDER_ITEM } from 'constants/resources'
import { CANCELLED, FINALIZED } from 'constants/status'
import Status from './Status'
import { v4 as uuidv4 } from 'uuid'

function Form({ current, loading, update }) {
  const { t } = useTranslation(['common', ORDER_ITEM, 'error'])
  const storeOrder = useSelector((state) => state.getIn(['order', 'current', 'data']))
  const storeOrderId = useSelector((state) => state.getIn(['order', 'current', 'id']))
  const order = storeOrder === new Map() ? current.get('order') : storeOrder
  const orderId = storeOrderId === '' ? current.getIn(['order', 'id']) : storeOrderId
  const isDisabled = [CANCELLED, FINALIZED].includes(current.get('status'))
  const [transportData, setTransportData] = useState([])
  const dispatch = useDispatch()
  const { handleSubmit, control, errors, register, getValues, setValue } = useForm({ mode: 'onBlur' })

  useEffect(() => {
    if (current.get('transport') && current.get('transport').size > 0) {
      setTransportData(
        [...Array(current.get('transport').size)].map((x, i) => {
          const transportCost =
            current.getIn(['selected_supplier', 'transport', i, 'value']) ?? current.getIn(['selected_transport_supplier', 'transport', i, 'value'])
          return {
            transport: current.getIn(['transport', i, 'type']),
            rate: current.getIn(['transport', i, 'value']),
            helperText: transportCost && t('common:resources.suppliers.singular') + ': $' + transportCost,
            id: uuidv4()
          }
        })
      )
    } else {
      setTransportData([
        { transport: t('transportConventions:deliveryCharge'), rate: null, id: uuidv4() },
        { transport: t('transportConventions:pickupCharge'), rate: null, id: uuidv4() }
      ])
    }
  }, [t, current])

  const handleUpdateSuccess = (response) => {
    if (order === storeOrder) {
      const itemIndex = order.get('items').findIndex((item) => item.get('id') === response.id)
      dispatch(orders.setCurrentData(order.setIn(['items', itemIndex], fromJS(response))))
    } else {
      dispatch(orderItems.updateListItem(response))
      dispatch(orderItems.setCurrentData(response))
    }
  }

  const handleCreateSuccess = (response) => {
    dispatch(orders.setCurrentData(order.set('items', order.get('items').push(fromJS(response)))))
  }

  const onSubmit = (data) => {
    let body = {
      order: data.order,
      equipment: data.equipment,
      quantity: data.quantity,
      daily_rate: data.daily_rate,
      weekly_rate: data.weekly_rate,
      monthly_rate: data.monthly_rate,
      rental_protection: data.rental_protection,
      environmental_fee: data.environmental_fee,
      transport: transportData.map((t) => {
        return { type: t.transport, value: t.rate }
      }),
      metadata: {},
      invoices_sent: data.invoices_sent,
      invoices_received: data.invoices_received,
      off_rented_at: data.off_rented_at
    }
    if (update) {
      body = { ...body, id: current.get('id') }
      dispatch(orderItems.update(body, handleUpdateSuccess))
    } else {
      dispatch(orderItems.create(body, handleCreateSuccess))
    }
  }

  const handleStatusChange = (val) => {
    if (!getValues('status')) register({ name: 'status' })
    setValue('status', val)
  }

  const onChange = useCallback((index, value, name) => {
    setTransportData((prevState) => {
      if (value) {
        return [
          ...prevState.slice(0, index),
          {
            ...prevState[index],
            [name]: value
          },
          ...prevState.slice(index + 1)
        ]
      } else {
        return [
          ...prevState.slice(0, index),
          {
            ...prevState[index],
            [name]: value === 0 ? NaN : ''
          },
          ...prevState.slice(index + 1)
        ]
      }
    })
  }, [])

  const onDelete = useCallback((index) => {
    setTransportData((prevState) => {
      let updatedArr = [...prevState]
      updatedArr.splice(index, 1)
      return prevState.length <= 0 ? [...prevState] : [...updatedArr]
    })
  }, [])

  const addTransportRow = () => {
    let updatedArr = [...transportData]
    updatedArr.push({
      transport: '',
      rate: null,
      id: uuidv4()
    })
    setTransportData(updatedArr)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex align="center" justify="space-between">
        <h1>{t('common:shared.information')}</h1>
        <Status id={current.get('id')} defaultValue={current.get('status')} onChange={handleStatusChange} />
      </Flex>
      <Divider spacing={20} />
      <input type="hidden" name="order" ref={register} defaultValue={orderId} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Controller
            as={EquipmentSelect}
            name="equipment"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('error:equipment.required')
              }
            }}
            error={errors.equipment?.message}
            id="new-order-item-equipment"
            defaultValue={current.getIn(['equipment', 'id'])}
            disabled={loading || isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            name="quantity"
            type="number"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('error:quantity.required')
              }
            }}
            error={errors.quantity?.message}
            label={t('common:shared.quantity')}
            placeholder={t('orderItem:new.quantity.placeholder')}
            id="new-order-item-quantity"
            defaultValue={current.get('quantity')}
            disabled={loading || isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            control={control}
            error={errors.off_rented_at?.message}
            name="off_rented_at"
            type="datetime"
            label={t('orderItem:shared.offRented')}
            id="new-service-request-off-rent"
            defaultValue={current.get('off_rented_at')}
            disabled={loading || isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={PriceField}
            name="daily_rate"
            control={control}
            error={errors.metadata?.daily_rate?.message}
            label={t('common:shared.dailyRate')}
            placeholder="69.00"
            id="new-order-item-daily-rate"
            defaultValue={current.get('daily_rate')}
            disabled={loading || isDisabled}
            helperText={
              current.get('selected_supplier') &&
              current.getIn(['selected_supplier', 'daily_rate']) &&
              t('common:resources.suppliers.singular') + ': $' + current.getIn(['selected_supplier', 'daily_rate'])
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={PriceField}
            name="weekly_rate"
            control={control}
            error={errors.metadata?.weekly_rate?.message}
            label={t('common:shared.weeklyRate')}
            placeholder="499.00"
            id="new-order-item-weekly-rate"
            defaultValue={current.get('weekly_rate')}
            disabled={loading || isDisabled}
            helperText={
              current.get('selected_supplier') &&
              current.getIn(['selected_supplier', 'weekly_rate']) &&
              t('common:resources.suppliers.singular') + ': $' + current.getIn(['selected_supplier', 'weekly_rate'])
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={PriceField}
            name="monthly_rate"
            control={control}
            error={errors.metadata?.monthly_rate?.message}
            label={t('common:shared.monthlyRate')}
            placeholder="3999.00"
            id="new-order-item-monthly-rate"
            defaultValue={current.get('monthly_rate')}
            disabled={loading || isDisabled}
            helperText={
              current.get('selected_supplier') &&
              current.getIn(['selected_supplier', 'monthly_rate']) &&
              t('common:resources.suppliers.singular') + ': $' + current.getIn(['selected_supplier', 'monthly_rate'])
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={PercentageField}
            name="rental_protection"
            control={control}
            error={errors.metadata?.rental_protection?.message}
            label={t('orderItem:shared.rentalProtection')}
            placeholder="15"
            id="new-order-item-rental-protection"
            defaultValue={current.get('rental_protection')}
            disabled={loading || isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={PercentageField}
            name="environmental_fee"
            control={control}
            error={errors.metadata?.env_compensation?.message}
            label={t('orderItem:shared.envCompensation')}
            placeholder="2"
            id="new-order-item-env-compensation"
            defaultValue={current.get('environmental_fee')}
            disabled={loading || isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            type="number"
            name="invoices_sent"
            control={control}
            error={errors.invoices_sent?.message}
            label={t('orderItem:shared.invoicesSent')}
            placeholder="1"
            id="new-order-item-invoices-sent"
            defaultValue={current.get('invoices_sent') || current.getIn(['metadata', 'invoices_sent'])}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            type="number"
            name="invoices_received"
            control={control}
            error={errors.invoices_received?.message}
            label={t('orderItem:shared.invoicesReceived')}
            placeholder="1"
            id="new-order-item-invoices-received"
            defaultValue={current.get('invoices_received') || current.getIn(['metadata', 'invoices_received'])}
            disabled={loading}
          />
        </Grid>
        <SectionHeader title={t('common:resources.transport.singular')} />
        <>
          {transportData?.map((transportItem, index) => (
            <OutlinedCard>
              <TransportRow
                key={transportItem.id}
                transport={transportItem.transport ?? ''}
                rate={transportItem.rate ?? ''}
                idx={index}
                onChange={onChange}
                onDelete={onDelete}
                disabled={loading || isDisabled}
                helperText={transportItem.helperText}
              />
            </OutlinedCard>
          ))}
          <AddItemButton id="add-transport-row-btn" onClick={() => addTransportRow()} type="button" disabled={loading || isDisabled}>
            + {t('serviceRequest:items.add')}
          </AddItemButton>
        </>
      </Grid>
      <Divider spacing={20} />
      <Button type="submit" fullSize disabled={loading}>
        {update ? t('common:shared.submit.edit') : t('common:shared.submit.new')}
      </Button>
    </form>
  )
}

Form.propTypes = {
  loading: PropTypes.bool,
  update: PropTypes.bool,
  current: PropTypes.instanceOf(Map)
}

Form.defaultProps = {
  loading: false,
  update: false,
  current: new Map()
}

export default Form
