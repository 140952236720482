import React from 'react'
import { ORDER } from 'constants/resources'
import { ORDER_INDEX } from 'constants/algolia'
import { withAlgolia } from 'components/hocs'
import { connectHits } from 'react-instantsearch-dom'
import { AutoCompleteSelect } from 'components/common'
function OrderSelect({ hits, value, ...otherProps }) {
  return (
    <AutoCompleteSelect
      hits={hits}
      value={value}
      attributesToRetrieve={['objectID', 'internal_code']}
      objectName={ORDER}
      configureHitsPerPage={5}
      keyLabel="internal_code"
      {...otherProps}
    />
  )
}

export default withAlgolia(ORDER_INDEX)(connectHits(OrderSelect))
