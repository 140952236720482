export const APP = process.env.REACT_APP_ALGOLIA_APP

export const COMPANY_INDEX = 'Company'
export const COMPANY_TOTALUSERS_ASC_INDEX = 'CompanyTotalUsersAsc'
export const COMPANY_TOTALUSERS_DESC_INDEX = 'CompanyTotalUsersDesc'
export const COMPANY_TOTALORDERS_ASC_INDEX = 'CompanyTotalOrdersAsc'
export const COMPANY_TOTALORDERS_DESC_INDEX = 'CompanyTotalOrdersDesc'
export const COMPANY_ONRENTORDERS_ASC_INDEX = 'CompanyOnRentOrdersAsc'
export const COMPANY_ONRENTORDERS_DESC_INDEX = 'CompanyOnRentOrdersDesc'

export const EQUIPMENT_INDEX = 'Equipment'
export const EQUIPMENT_TOTALORDERS_ASC_INDEX = 'EquipmentTotalOrdersAsc'
export const EQUIPMENT_TOTALORDERS_DESC_INDEX = 'EquipmentTotalOrdersDesc'

export const CATEGORY_INDEX = 'EquipmentCategory'

export const ORDER_INDEX = 'Order'
export const ORDER_CREATED_ASC_INDEX = 'OrderCreatedAsc'
export const ORDER_CREATED_DESC_INDEX = 'OrderCreatedDesc'
export const ORDER_STARTDATE_ASC_INDEX = 'OrderStartDateAsc'
export const ORDER_STARTDATE_DESC_INDEX = 'OrderStartDateDesc'
export const ORDER_DELIVERYDATE_ASC_INDEX = 'OrderDeliveryDateAsc'
export const ORDER_DELIVERYDATE_DESC_INDEX = 'OrderDeliveryDateDesc'
export const ORDER_BILLINGDATE_ASC_INDEX = 'OrderBillingDateAsc'
export const ORDER_BILLINGDATE_DESC_INDEX = 'OrderBillingDateDesc'
export const ORDER_ENDDATE_ASC_INDEX = 'OrderEndDateAsc'
export const ORDER_ENDDATE_DESC_INDEX = 'OrderEndDateDesc'

export const ORDER_ITEM_INDEX = 'OrderItem'
export const ORDER_ITEM_OFFRENTEDAT_ASC_INDEX = 'OrderItemOffRentedAtAsc'
export const ORDER_ITEM_OFFRENTEDAT_DESC_INDEX = 'OrderItemOffRentedAtDesc'
export const ORDER_ITEM_STARTDATE_ASC_INDEX = 'OrderItemStartDateAsc'
export const ORDER_ITEM_STARTDATE_DESC_INDEX = 'OrderItemStartDateDesc'
export const ORDER_ITEM_BILLINGDATE_ASC_INDEX = 'OrderItemBillingDateAsc'
export const ORDER_ITEM_BILLINGDATE_DESC_INDEX = 'OrderItemBillingDateDesc'

export const SUPPLIER_INDEX = 'Supplier'
export const SUPPLIER_STATE_ASC_INDEX = 'SupplierStateAsc'
export const SUPPLIER_STATE_DESC_INDEX = 'SupplierStateDesc'
export const SUPPLIER_CITY_ASC_INDEX = 'SupplierCityAsc'
export const SUPPLIER_CITY_DESC_INDEX = 'SupplierCityDesc'
export const SUPPLIER_TOTALORDERS_ASC_INDEX = 'SupplierTotalOrdersAsc'
export const SUPPLIER_TOTALORDERS_DESC_INDEX = 'SupplierTotalOrdersDesc'
export const SUPPLIER_RATING_ASC_INDEX = 'SupplierRatingAsc'
export const SUPPLIER_RATING_DESC_INDEX = 'SupplierRatingDesc'

export const USER_INDEX = 'User'
export const USER_LASTSESSION_ASC_INDEX = 'UserLastLoginAsc'
export const USER_LASTSESSION_DESC_INDEX = 'UserLastLoginDesc'

export const SERVICE_REQUEST_INDEX = 'ServiceRequest'
export const SERVICE_REQUEST_CREATED_ASC_INDEX = 'ServiceRequestCreatedAsc'
export const SERVICE_REQUEST_CREATED_DESC_INDEX = 'ServiceRequestCreatedDesc'
