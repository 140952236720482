import api from 'config/axios'
import { buildQueryString } from 'utils'

export const list = async (filters) => api.get(`/app/suppliers/rates/?${buildQueryString(filters)}`)

export const get = async (id) => api.get(`/app/suppliers/rates/${id}/`)

export const create = async ({
  service_request_item,
  order_item,
  supplier,
  daily_rate,
  weekly_rate,
  monthly_rate,
  transport,
  metadata,
  notes,
  is_selected,
  is_selected_transport
}) =>
  api.post('/app/suppliers/rates/', {
    service_request_item,
    order_item,
    supplier,
    daily_rate,
    weekly_rate,
    monthly_rate,
    transport,
    metadata,
    notes,
    is_selected,
    is_selected_transport
  })

export const update = async (
  id,
  {
    service_request_item,
    order_item,
    supplier,
    daily_rate,
    weekly_rate,
    monthly_rate,
    transport,
    metadata,
    notes,
    is_selected,
    is_selected_transport
  }
) =>
  api.patch(`/app/suppliers/rates/${id}/`, {
    service_request_item,
    order_item,
    supplier,
    daily_rate,
    weekly_rate,
    monthly_rate,
    transport,
    metadata,
    notes,
    is_selected,
    is_selected_transport
  })

export const destroy = async (id) => api.delete(`/app/suppliers/rates/${id}/`)
