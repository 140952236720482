import React from 'react'
import { SUPPLIER_RATE } from 'constants/resources'
import { TableDetail } from 'components/common'
import { useResource, useCurrent } from 'components/hooks'
import SupplierForm from './SupplierForm'

function Edit() {
  const supplierRate = useCurrent(SUPPLIER_RATE)
  const loading = useResource(SUPPLIER_RATE, 'update', 'loading')
  
  return (
    <TableDetail>
      <SupplierForm
        update
        loading={loading}
        current={supplierRate}
      />
    </TableDetail>
  )
}

export default Edit
