import React from 'react'
import PropTypes from 'prop-types'
import { Price, Detail, PreviewLink, SectionHeader, Date } from 'components/common'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import { EQUIPMENT, ORDER_ITEM } from 'constants/resources'

function Information({
  action,
  title,
  offRentAt,
  equipmentName,
  equipmentId,
  quantity,
  dailyRate,
  weeklyRate,
  monthlyRate,
  rentalProtection,
  envCompensation,
  invoicesSent,
  invoicesReceived,
  internalCode,
  loading
}) {
  const { t } = useTranslation(['common', ORDER_ITEM])

  return (
    <Grid container spacing={2}>
      {title ? <SectionHeader title={title}>{action}</SectionHeader> : null}
      <Grid item xs={12} md={6} data-cy="itemized-info-id">
        <Detail text={internalCode} loading={loading} label={t('common:shared.id')}>
          {internalCode}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="itemized-info-equipment">
        <Detail text={equipmentName} loading={loading} label={t('orderItem:shared.machine')}>
          <PreviewLink resource={EQUIPMENT} id={equipmentId}>
            {equipmentName}
          </PreviewLink>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="itemized-info-quantity">
        <Detail text={quantity} loading={loading} label={t('common:shared.quantity')}>
          {quantity}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="itemized-info-daily-rate">
        <Detail text={dailyRate} loading={loading} label={t('common:shared.dailyRate')}>
          <Price amount={dailyRate} />
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="itemized-info-weekly-rate">
        <Detail text={weeklyRate} loading={loading} label={t('common:shared.weeklyRate')}>
          <Price amount={weeklyRate} />
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="itemized-info-monthly-rate">
        <Detail text={monthlyRate} loading={loading} label={t('common:shared.monthlyRate')}>
          <Price amount={monthlyRate} />
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="itemized-info-rental-protection">
        <Detail text={rentalProtection} loading={loading} label={t('orderItem:shared.rentalProtection')}>
          {rentalProtection}%
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="itemized-info-env-compensation">
        <Detail text={envCompensation} loading={loading} label={t('orderItem:shared.envCompensation')}>
          {envCompensation}%
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="itemized-info-invoices-sent">
        <Detail text={invoicesSent} loading={loading} label={t('orderItem:shared.invoicesSent')}>
          {invoicesSent}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="itemized-info-invoices-received">
        <Detail text={invoicesReceived} loading={loading} label={t('orderItem:shared.invoicesReceived')}>
          {invoicesReceived}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="itemized-info-off-rented">
        <Detail text={offRentAt} loading={loading} label={t('orderItem:shared.offRented')}>
          <Date variant="datetime">{offRentAt}</Date>
        </Detail>
      </Grid>
    </Grid>
  )
}

Information.propTypes = {
  action: PropTypes.node,
  equipmentName: PropTypes.string,
  offRentAt: PropTypes.string,
  title: PropTypes.string,
  equipmentId: PropTypes.string,
  quantity: PropTypes.number,
  deliveryCost: PropTypes.number,
  pickupCost: PropTypes.number,
  dailyRate: PropTypes.number,
  weeklyRate: PropTypes.number,
  monthlyRate: PropTypes.number,
  rentalProtection: PropTypes.number,
  envCompensation: PropTypes.number,
  invoicesSent: PropTypes.number,
  invoicesReceived: PropTypes.number,
  internalCode: PropTypes.string,
  loading: PropTypes.bool
}

Information.defaultProps = {
  action: null,
  equipmentName: '',
  offRentAt: '',
  title: '',
  equipmentId: '',
  quantity: null,
  deliveryCost: null,
  pickupCost: null,
  dailyRate: null,
  weeklyRate: null,
  monthlyRate: null,
  rentalProtection: null,
  envCompensation: null,
  invoicesSent: 0,
  invoicesReceived: 0,
  loading: false,
  internalCode: ''
}

export default Information
