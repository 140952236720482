import api from 'config/axios'
import { buildQueryString } from 'utils'

export const list = async filters => (
  api.get(`/app/companies/?${buildQueryString(filters)}`)
)

export const get = async id => (
  api.get(`/app/companies/${id}/`)
)

export const create = async ({
  name,
  sales_rep,
  metadata,
  has_signed_rpp,
}) => (
  api.post('/app/companies/', {
    name,
    sales_rep,
    metadata,
    has_signed_rpp,
  })
)

export const update = async (id, {
  name,
  sales_rep,
  metadata,
  has_signed_rpp,
}) => (
  api.patch(`/app/companies/${id}/`, {
    name,
    sales_rep,
    metadata,
    has_signed_rpp,
  })
)

export const destroy = async id => (
  api.delete(`/app/companies/${id}/`)
)
