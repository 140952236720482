import React from 'react'
import { Divider } from 'components/ui'
import { useCurrent, useResource } from 'components/hooks'
import { SERVICE_REQUEST_ITEM } from 'constants/resources'
import {
  Destroy, TableDetail,
} from 'components/common'
import { CANCELLED, FINALIZED } from 'constants/status'
import Information from './detail/Information'
import Header from './detail/Header'

function OrderItemDetail() {
  const selected = useCurrent(SERVICE_REQUEST_ITEM)
  const loading = useResource(SERVICE_REQUEST_ITEM, 'current', 'loading')
  const canDestroy = [CANCELLED, FINALIZED].includes(selected.get('status'))

  return (
    <TableDetail>
      <Header
        serviceRequestItem={selected}
        equipmentName={selected.getIn(['equipment', 'name'])}
        loading={loading}
      />
      <Divider spacing={28} />
      <Information
        equipmentName={selected.getIn(['equipment', 'name'])}
        equipmentId={selected.getIn(['equipment', 'id'])}
        quantity={selected.get('quantity')}
        purchaseOrder={selected.getIn(['metadata', 'external_id'])}
        dailyRate={selected.get('daily_rate')}
        weeklyRate={selected.get('weekly_rate')}
        monthlyRate={selected.get('monthly_rate')}
        rentalProtection={selected.get('rental_protection')}
        envCompensation={selected.get('environmental_fee')}
        loading={loading}
      />
      <Divider spacing={20} />
      <Destroy resource={SERVICE_REQUEST_ITEM} id={selected.get('id')} show={canDestroy} />
    </TableDetail>
  )
}

export default OrderItemDetail
