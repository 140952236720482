import React from 'react'
import { DetailHeader, Chip } from 'components/common'
import { serviceRequestItems, serviceRequests } from 'store/action-creators'
import PropTypes from 'prop-types'
import EditButton from 'components/pages/service-request-items/EditButton'
import { Map } from 'immutable'
import { SUPPLIER_RATE, SERVICE_REQUEST } from 'constants/resources'
import { useQuickPreview, useCurrent } from 'components/hooks'
import { NEW } from 'constants/crud'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

function Header({ isEdit, loading, serviceRequestItem, equipmentName, small }) {
  const { t } = useTranslation('common')
  const [openPreview] = useQuickPreview()
  const dispatch = useDispatch()
  const currentServiceRequest = useCurrent(SERVICE_REQUEST)

  const onAddSupplier = () => {
    dispatch(serviceRequestItems.setCurrentId(serviceRequestItem.get('id')))
    openPreview(SUPPLIER_RATE, NEW)
  }

  const onDelete = (event) => {
    event.stopPropagation()
    if (window && window.confirm(t('shared.deletionConfirm'))) {
      const itemIndex = currentServiceRequest.get('service_request_items').findIndex((item) => item.get('id') === serviceRequestItem.get('id'))
      const serviceRequest = currentServiceRequest.toJS()
      serviceRequest.service_request_items.splice(itemIndex, 1)
      
      dispatch(serviceRequestItems.delete(serviceRequestItem.get('id'),() => {dispatch(serviceRequests.setCurrentData(serviceRequest))}))
      
    }
  }

  return (
    <DetailHeader title={equipmentName} loading={loading} variant={small && 'small'}>
      <Chip onClick={onAddSupplier} outline label={'+ ' + t('common:resources.suppliers.singular')} />
      {isEdit ? <EditButton serviceRequestItem={serviceRequestItem} /> : null}
      <Chip onClick={onDelete} outline icon="delete" label={t('shared.delete')} />
    </DetailHeader>
  )
}

Header.propTypes = {
  equipmentName: PropTypes.string,
  loading: PropTypes.bool,
  isEdit: PropTypes.bool,
  small: PropTypes.bool,
  serviceRequestItem: PropTypes.instanceOf(Map).isRequired
}

Header.defaultProps = {
  equipmentName: '',
  serviceRequestItemId: '',
  loading: false,
  isEdit: false,
  small: false
}

export default Header
