import React from 'react'
import { SERVICE_REQUEST } from 'constants/resources'
import { NEW } from 'constants/crud'
import { serviceRequests } from 'store/action-creators'
import { CANCELLED, CREATED, FINALIZED } from 'constants/status'
import { useDispatch } from 'react-redux'
import { useQuickPreview } from 'components/hooks'
import { useTranslation } from 'react-i18next'
import { Chip } from 'components/common'
import PropTypes from 'prop-types'
import { Map } from 'immutable'

function OffRentButton({
  status,
  id,
  order,
}) {
  const { t } = useTranslation('common', SERVICE_REQUEST)
  const [openPreview] = useQuickPreview()
  const dispatch = useDispatch()

  const handleChipClick = () => {
    openPreview(SERVICE_REQUEST, NEW)
    dispatch(serviceRequests.setCreateData({
      designation: 'off-rent',
      multiple: true,
      order: {
        id,
        items: order.get('items'),
      },
      metadata: {
        contacts: order.getIn(['metadata', 'contacts']),
      },
      sales_rep: order.getIn(['sales_rep', 'id']),
      address_line1: order.get('address_line1'),
      address_line2: order.get('address_line2'),
      address_zip: order.get('address_zip'),
      address_city: order.get('address_city'),
      address_state: order.get('address_state'),
      address_country: {code: order.getIn(['address_country', 'code'])},
    }))
  }

  if ([CANCELLED, FINALIZED, CREATED].includes(status)) return null

  return (
    <Chip
      outline
      icon="flag"
      onClick={handleChipClick}
      label={t('serviceRequest:shared.off-rent')}
    />
  )
}

OffRentButton.propTypes = {
  id: PropTypes.string,
  status: PropTypes.string,
  order: PropTypes.instanceOf(Map),
}

OffRentButton.defaultProps = {
  id: '',
  status: '',
  order: new Map(),
}


export default OffRentButton
