import {
  CATEGORY,
  COMPANY,
  EQUIPMENT,
  ORDER,
  ORDER_ITEM,
  SERVICE_CALL,
  SERVICE_REQUEST,
  SUPPLIER,
  SUPPLIER_REVIEW,
  USER,
  NOTIFICATION,
  ORDER_SERVICE,
} from 'constants/resources'

export const namespaces = [
  CATEGORY,
  'common',
  COMPANY,
  EQUIPMENT,
  'error',
  NOTIFICATION,
  ORDER,
  ORDER_ITEM,
  ORDER_SERVICE,
  SERVICE_CALL,
  SERVICE_REQUEST,
  'session',
  SUPPLIER,
  SUPPLIER_REVIEW,
  USER,
  'transportConventions'
]

export const defaultNamespace = 'common'

export default namespaces
