import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { Grid } from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'
import { PriceField, TransportSelect } from 'components/common'
import { SERVICE_REQUEST } from 'constants/resources'
import { useTranslation } from 'react-i18next'
import { formatSentence } from 'utils/capitalize'
import { translateTransportSubstring } from 'constants/transports'
import { DeleteXButton } from 'components/ui'

function TransportRow ({ transport, rate, idx, onChange, onDelete, disabled, helperText }) {
  const { t } = useTranslation(['common', SERVICE_REQUEST, 'error', 'transportConventions'])
  const [isFirstRender, setFirstRender] = useState(true)
  const transportConventions = [
    { label: t('transportConventions:deliveryCharge'), value: 'DELIVERY' },
    { label: t('transportConventions:pickupCharge'), value: 'PICKUP' },
    { label: t('transportConventions:displacementCharge'), value: 'DISPLACEMENT' },
    { label: t('transportConventions:freight'), value: 'FREIGHT' },
  ]

  const getTransportLabelByValue = () => {
    const value = transport
    const option = transportConventions.find(opt => opt.value === value)
    return option ? option.label : formatSentence(translateTransportSubstring(value, 'fr', 'en'))
  }
  
  const nameValues = {
    "transport": "transport",
    "rate": "rate"
  }

  const { control } = useForm({
    mode: 'onBlur',
    defaultValues: {
      transport_row: {
        [nameValues['transport']]: getTransportLabelByValue(),
        [nameValues['rate']]: rate
      }
    }
  })
  const { register, errors } = useForm()
  const onInputChange = (results, name) => {
    if (isFirstRender && results[0] === '') {
      setFirstRender(false)
      return
    }
    onChange(idx, results[0], name)
  }

  const handleTransportChange = (results) => {
    const [option] = results
    const value = (option && option.label) || option
    onInputChange([value], nameValues["transport"])
    return value
  }

  const handleRateChange = (results) => {
    const [rate] = results
    const value = Number(rate)
    onInputChange([value], nameValues["rate"])
  }

  return (
    <Grid container spacing={2}>
      <DeleteXButton onDelete={() => onDelete(idx)}/>
      <Grid item xs={8} md={8}>
        <Controller
          as={TransportSelect}
          control={control}
          freeSolo
          autoSelect={false}
          clearOnBlur={false}
          inputRef={register(`transport_row.${nameValues["transport"]}`)}
          error={errors.transport_row?.transport?.message}
          name={`transport_row.${nameValues["transport"]}`}
          label={t('serviceRequest:pdf.transport')}
          placeholder={t('serviceRequest:pdf.delivery')}
          id="new-service-request-transport-type"
          data-cy="transport-row-transport"
          onChange={handleTransportChange}
          disabled={disabled}
        /> 
      </Grid>
      <Grid item xs={4} md={4}>
        <Controller
          as={PriceField}
          control={control}
          inputRef={register({
            required: {
              value: true,
              message: t('error:transportRate.required'),
            },
          },)}
          error={errors.transport_row?.transport_rate?.message}
          name={`transport_row.${nameValues["rate"]}`}
          type="text"
          label={t('serviceRequest:pdf.rate')}
          placeholder="200.00"
          id="new-service-request-transport-rate"
          onChange={handleRateChange}
          data-cy="transport-row-rate"
          disabled={disabled}
          helperText={helperText}
        />
      </Grid>
    </Grid>
  )
}

TransportRow.propTypes = {
  loading: PropTypes.bool,
  onChange: PropTypes.any,
  onDelete: PropTypes.any,
  currentQuote: PropTypes.instanceOf(Map),
  disabled: PropTypes.bool,
}

TransportRow.defaultProps = {
  loading: false,
  onChange: null,
  onDelete: null,
  currentQuote: new Map(),
  disabled: false,
}

export default TransportRow
