import { takeLatest, call, select, put } from 'redux-saga/effects'
import { SERVICE_REQUEST } from 'constants/resources'
import { LIST, LIST_RESET, GET, GET_STORE, CREATE, UPDATE, DELETE } from 'store/actions/factory'
import { notify } from 'utils'
import i18n from 'config/i18n'
import {
  get as getService,
  create as createService,
  update as updateService,
  updateAttachment as updateAttachmentService
} from 'services/service-requests'
import { create as createItemService } from 'services/service-request-items'
import { create as factoryCreate, list, resetList, get, destroy } from './factory'
import { get as getOrderService, update as updateOrderService } from 'services/orders'
import { EDIT, NEW } from 'constants/crud'
import actions, { serviceRequests } from 'store/action-creators'
import nprogress from './nprogress'
import { closePreview } from './preview'
import { getStore } from './storeFactory'
import { create as createSupplierRateService } from 'services/supplier-rate'

function* create({ payload }) {
  const { body } = payload
  if (body?.designation === 'extra') {
    yield createExtra(payload)
  } else {
    if (body.designation === 'rental-price' || body.designation === 'order') {
      if (body?.isRequestTransfer) {
        yield requestTransfer(payload)
      } else {
        yield createServiceRequest(payload)
      }
    } else {
      yield factoryCreate({ payload, resource: SERVICE_REQUEST })
    }
  }
}

function* createServiceRequest(payload) {
  yield call(nprogress, true)
  yield put(serviceRequests.setCreateLoading(true))
  const { body, onSuccess, onError } = payload
  const { attachment, items } = body
  const response = yield call(createService, body)
  if (response) {
    const parsedItemsData = Object.keys(items)?.map((key) => {
      return {
        service_request: response.id,
        equipment: items[key].equipment.value,
        quantity: items[key].quantity
      }
    })
    if (parsedItemsData && parsedItemsData.length > 0) {
      const responseItems = yield call(createItemService, parsedItemsData)
      if (responseItems) {
        const serviceRequest = {
          ...response,
          service_request_items: [...response.service_request_items, ...responseItems]
        }
        yield put(serviceRequests.setCurrentData(serviceRequest))
        notify({
          text: i18n.t('common:shared.successCreate', { resource: response.internal_code })
        })
      }
    }
    yield put(serviceRequests.prependListItem(response))
    yield call(closePreview, SERVICE_REQUEST, NEW)
    yield put(serviceRequests.setCurrentData(response))
    yield put(serviceRequests.get(response.id))
    yield actions.navigation.setQuery({ id: response.id })
    if (attachment) {
      const attachmentResponse = yield call(updateAttachmentService, response.id, attachment)
      yield put(serviceRequests.setCurrentData(attachmentResponse))
      yield put(serviceRequests.updateListItem(attachmentResponse))
    }
    if (onSuccess) yield call(onSuccess, response)
  } else if (onError) yield call(onError)
  yield call(nprogress, false)
  yield put(serviceRequests.setCreateLoading(false))
}

function* requestTransfer(payload) {
  yield call(nprogress, true)
  yield put(serviceRequests.setCreateLoading(true))
  const { body, onSuccess, onError } = payload
  const { attachment } = body
  const response = yield call(createService, body)
  let transferSuccess = true
  if (response) {
    for (let i = 0; i < body.items.length; i++) {
      const data = body.items[i]
      const itemBody = {
        service_request: response.id,
        equipment: data.equipment.id,
        quantity: data.quantity,
        daily_rate: data.daily_rate,
        weekly_rate: data.weekly_rate,
        monthly_rate: data.monthly_rate,
        rental_protection: data.rental_protection,
        environmental_fee: data.environmental_fee,
        transport: data.transport,
        metadata: data.metadata
      }
      const itemResponse = yield call(createItemService, itemBody)
      if (!itemResponse) {
        transferSuccess = false
      } else {
        for (let j = 0; j < body.items[i].supplier_rates.length; j++) {
          const data = body.items[i].supplier_rates[j]
          const supplierRateBody = {
            service_request_item: itemResponse.id,
            supplier: data.supplier.id,
            daily_rate: data.daily_rate,
            weekly_rate: data.weekly_rate,
            monthly_rate: data.monthly_rate,
            transport: data.transport,
            notes: data.notes,
            metadata: data.metadata,
            is_selected: data.is_selected,
            is_selected_transport: data.is_selected_transport
          }
          const supplierRateResponse = yield call(createSupplierRateService, supplierRateBody)
          if (!supplierRateResponse) {
            transferSuccess = false
          }
        }
      }
    }
    if (response && transferSuccess) {
      notify({
        text: i18n.t('common:shared.successCreate', { resource: response.internal_code })
      })
      yield put(serviceRequests.prependListItem(response))
      yield put(actions.navigation.removeQuery('id'))
      yield put(serviceRequests.setCurrentData(response))
      yield put(serviceRequests.setCurrentId(response.id))
      yield call(closePreview, SERVICE_REQUEST, NEW)
      yield put(serviceRequests.get(response.id))
      yield put(actions.navigation.setQuery({ id: response.id }))
      if (attachment) {
        const attachmentResponse = yield call(updateAttachmentService, response.id, attachment)
        yield put(serviceRequests.setCurrentData(attachmentResponse))
        yield put(serviceRequests.updateListItem(attachmentResponse))
      }
      if (onSuccess) yield call(onSuccess, response)
    } else if (onError) yield call(onError)
    if (onSuccess) yield call(onSuccess, response)
  } else if (onError) yield call(onError)

  yield call(nprogress, false)
  yield put(serviceRequests.setCreateLoading(false))
}

function* createExtra(payload) {
  yield call(nprogress, true)
  yield put(serviceRequests.setCreateLoading(true))
  const { body, onSuccess, onError } = payload
  const response = yield call(createService, body)
  if (response) {
    notify({
      text: i18n.t('common:shared.successCreate', { resource: response.internal_code })
    })
    yield call(closePreview, SERVICE_REQUEST, NEW)
    if (!body.notFromExtraForm) {
      yield put(serviceRequests.prependListItem(response))
      yield put(serviceRequests.setCurrentData(response))
    }
    if (onSuccess) yield call(onSuccess, response)
  } else if (onError) yield call(onError)
  yield call(nprogress, false)

  yield put(serviceRequests.setCreateLoading(false))
  yield updateItemConnectedToExtra(body, response)
}

function* update({ payload }) {
  yield call(nprogress, true)
  const { body, onSuccess, onError } = payload
  const { attachment, items, index } = body
  yield put(serviceRequests.setUpdateLoading(true))
  const id = yield select((store) => store.getIn([SERVICE_REQUEST, 'current', 'id']))
  const response = yield call(updateService, id, body)
  let attachmentResponse = null
  if (attachment) {
    attachmentResponse = yield call(updateAttachmentService, id, attachment)
  }
  if (response) {
    if (items && Object.keys(items).length > index) {
      const serviceRequestItemList = Object.keys(items)
        .map((key, i) => {
          if (i >= index) {
            return {
              service_request: response.id,
              equipment: items[key].equipment.value,
              quantity: items[key].quantity
            }
          } else {
            return undefined
          }
        })
        .filter((e) => e !== undefined)
      if (serviceRequestItemList && serviceRequestItemList.length > 0) {
        const responseItems = yield call(createItemService, serviceRequestItemList)
        if (responseItems) {
          const serviceRequest = {
            ...response,
            service_request_items: [...response.service_request_items, ...responseItems]
          }
          yield put(serviceRequests.setCurrentData(serviceRequest))
          notify({
            text: i18n.t('common:shared.successUpdate', { resource: response.internal_code })
          })
        }
      }
    } else {
      yield put(serviceRequests.setCurrentData(response))
      notify({
        text: i18n.t('common:shared.successUpdate', { resource: response.internal_code })
      })
    }
    yield put(serviceRequests.updateListItem(response))
    yield call(closePreview, SERVICE_REQUEST, EDIT)
    if (onSuccess) yield call(onSuccess, response)
    yield call(nprogress, false)
  } else if (onError) yield call(onError)
  if (attachmentResponse) {
    yield put(serviceRequests.setCurrentData(attachmentResponse))
  } else if (onError) yield call(onError)

  yield call(nprogress, false)
  yield put(serviceRequests.setUpdateLoading(false))
}

function* updateItemConnectedToExtra(body, response) {
  const getOrderResponse = yield call(getOrderService, body.metadata.contract_reference_id)
  getOrderResponse.metadata.extra_id ? getOrderResponse.metadata.extra_id.push(response.id) : (getOrderResponse.metadata.extra_id = [response.id])
  let patchData = {}
  patchData.metadata = getOrderResponse.metadata
  yield call(updateOrderService, body.metadata.contract_reference_id, patchData)
  if (body?.metadata?.service_call_reference_id) {
    const getServiceCallResponse = yield call(getService, body.metadata.service_call_reference_id)
    getServiceCallResponse.metadata.extra_id
      ? getServiceCallResponse.metadata.extra_id.push(response.id)
      : (getServiceCallResponse.metadata.extra_id = [response.id])
    patchData.metadata = getServiceCallResponse.metadata
    yield call(updateService, body.metadata.service_call_reference_id, patchData)
  }
}

export default function* serviceRequestSaga() {
  yield takeLatest(LIST_RESET(SERVICE_REQUEST), resetList)
  yield takeLatest(LIST(SERVICE_REQUEST), list)
  yield takeLatest(GET(SERVICE_REQUEST), get)
  yield takeLatest(GET_STORE(SERVICE_REQUEST), getStore)
  yield takeLatest(CREATE(SERVICE_REQUEST), create)
  yield takeLatest(UPDATE(SERVICE_REQUEST), update)
  yield takeLatest(DELETE(SERVICE_REQUEST), destroy)
}
