import React from 'react'
import PropTypes from 'prop-types'
import { Date, Detail, PreviewLink, SectionHeader } from 'components/common'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import { COMPANY, ORDER, SERVICE_REQUEST, USER } from 'constants/resources'

function Information({
  internalCode,
  action,
  title,
  companyName,
  companyId,
  purchaseOrder,
  rentallPurchaseOrder,
  startDate,
  deliveryDate,
  billingDate,
  notes,
  loading,
  salesRepName,
  salesRepId,
  assigneeName,
  assigneeId,
  id,
  duration,
  order
}) {
  const { t } = useTranslation(['common', ORDER])

  return (
    <Grid container spacing={2}>
      {title ? <SectionHeader title={title}>{action}</SectionHeader> : null}
      <Grid item xs={12} md={6} data-cy="contract-info-id">
        <Detail text={internalCode?.slice(3)} loading={loading} label={t('common:shared.id')}>
          <PreviewLink resource={ORDER} id={id}>
            <span>{internalCode?.slice(3)}</span>
          </PreviewLink>
        </Detail>
      </Grid>

      {order && (
        <Grid item xs={12} md={6} data-cy="contract-info-order">
          <Detail text={order.get('code')} loading={loading} label={t('serviceRequest:shared.order-id')}>
            <PreviewLink resource={SERVICE_REQUEST} id={order.get('id')}>
              <span>{order.get('code')}</span>
            </PreviewLink>
          </Detail>
        </Grid>
      )}

      <Grid item xs={12} md={6} data-cy="contract-info-handled-by">
        <Detail text={assigneeName} loading={loading} label={t('common:shared.handledBy')}>
          <PreviewLink resource={USER} id={assigneeId}>
            <span>{assigneeName}</span>
          </PreviewLink>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="contract-info-sales-rep">
        <Detail text={salesRepName} loading={loading} label={t('common:shared.salesRep')}>
          <PreviewLink resource={USER} id={salesRepId}>
            <span>{salesRepName}</span>
          </PreviewLink>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="contract-info-company">
        <Detail text={companyName} loading={loading} label={t('common:resources.companies.singular')}>
          <PreviewLink resource={COMPANY} id={companyId}>
            <span>{companyName}</span>
          </PreviewLink>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="contract-info-start-date">
        <Detail text={startDate} loading={loading} label={t('common:shared.startDate')}>
          <Date variant="datetime">{startDate}</Date>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="contract-info-delivery-date">
        <Detail text={deliveryDate} loading={loading} label={t('common:shared.deliveryDate')}>
          <Date variant="datetime">{deliveryDate}</Date>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="contract-info-billing-date">
        <Detail text={billingDate} loading={loading} label={t('order:shared.billingDate')}>
          <Date>{billingDate}</Date>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="contract-info-duration">
        <Detail text={duration} loading={loading} label={t('common:shared.duration')}>
          {duration}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="contract-info-customer-po">
        <Detail text={purchaseOrder} loading={loading} label={t('order:shared.customerPO')}>
          {purchaseOrder}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="contract-rentall-po">
        <Detail text={rentallPurchaseOrder || internalCode?.slice(3)} loading={loading} label={t('order:shared.rentallPO')}>
          {rentallPurchaseOrder || internalCode?.slice(3)}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="contract-info-notes">
        <Detail text={notes} loading={loading} label={t('common:shared.notes')}>
          {notes}
        </Detail>
      </Grid>
    </Grid>
  )
}

Information.propTypes = {
  action: PropTypes.node,
  companyName: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
  companyId: PropTypes.string,
  purchaseOrder: PropTypes.string,
  rentallPurchaseOrder: PropTypes.string,
  startDate: PropTypes.string,
  deliveryDate: PropTypes.string,
  billingDate: PropTypes.string,
  notes: PropTypes.string,
  loading: PropTypes.bool,
  internalCode: PropTypes.string,
  salesRepName: PropTypes.string,
  salesRepId: PropTypes.string,
  duration: PropTypes.number
}

Information.defaultProps = {
  action: null,
  companyName: '',
  id: '',
  title: '',
  companyId: '',
  purchaseOrder: '',
  rentallPurchaseOrder: '',
  startDate: '',
  billingDate: '',
  notes: '',
  loading: false,
  internalCode: '',
  salesRepName: '',
  salesRepId: '',
  duration: null
}

export default Information
