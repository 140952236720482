import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Divider } from 'components/ui'
import { Flex, Input, AddressFields, Button, PurchaseOrderField } from 'components/common'
import { companies, orders } from 'store/action-creators'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import { CompanySelect } from 'components/pages/companies'
import moment from 'moment'
import { useForm, Controller } from 'react-hook-form'
import { DATE } from 'constants/formats'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { ORDER } from 'constants/resources'
import SelectAdmin from 'components/pages/users/SelectAdmin'
import { useMultipleContacts, useNotification } from 'components/hooks'
import Status from './Status'
import RequestStatus from 'components/pages/service-requests/Status'
import Checkbox from 'components/common/Checkbox'

function Form({ current, loading, update }) {
  const { t } = useTranslation(['common', ORDER, 'error'])
  const notify = useNotification()
  const dispatch = useDispatch()
  const items = current.get('service_request_items')
  const isRequestTransfer = current.get('designation') === ORDER && !update
  const [isItemCheckedArr, setItemCheckedArr] = useState(new Array(isRequestTransfer ? items.size : 0).fill(true))

  const { handleSubmit, control, errors, setValue, getValues } = useForm({ mode: 'onBlur' })
  const { fields, onBlur, cleanContacts } = useMultipleContacts({
    initialCount: current.getIn(['metadata', 'contacts'])?.size || 1,
    getValues
  })

  const onSubmit = (body) => {
    if (current.get('object') !== 'service_request' && !body.metadata.rentall_external_id) {
      body.metadata.rentall_external_id = current.get('internal_code')?.slice(3)
    }
    let requestBody = cleanContacts(body)

    if (update) {
      if (current.getIn(['metadata', 'order', 'id'])) {
        requestBody = {
          ...requestBody,
          metadata: {
            ...requestBody.metadata,
            order: {
              id: current.getIn(['metadata', 'order', 'id']),
              code: current.getIn(['metadata', 'order', 'code'])
            }
          }
        }
      }
      dispatch(orders.update(requestBody))
    } else if (isRequestTransfer) {
      const checkedItems = items.filter((_, index) => isItemCheckedArr[index])
      const body = {
        ...requestBody,
        items: checkedItems.toJS(),
        metadata: {
          ...requestBody.metadata,
          order: {
            id: current.get('id'),
            code: current.get('internal_code')
          }
        },
        isRequestTransfer: true
      }
      for (let i = 0; i < checkedItems.size; i++) {
        if (!checkedItems.toJS()[i].supplier_rates.find((rate) => rate.is_selected)) {
          notify({ type: 'error', text: t('error:requestTransfer.noSelectedSupplier') })
          return
        }
      }
      dispatch(orders.create(body))
    } else {
      dispatch(orders.create(requestBody))
    }
  }

  const handleCompanyChange = (value) => {
    const [company] = value
    if (!update && company && !isRequestTransfer) {
      dispatch(
        companies.get(company, (val) => {
          setValue('sales_rep', val.sales_rep?.id)
        })
      )
    }
    return company
  }
  const handleStartDateChange = (value) => {
    const [start_date] = value
    if (!update && !isRequestTransfer) {
      setValue('delivery_date', start_date)
    }
    return start_date
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex align="center" justify="space-between">
        <h1>{t('common:shared.information')}</h1>
        {current.get('status') && isRequestTransfer ? (
          <RequestStatus defaultValue={current.get('status')} />
        ) : (
          <Status value={current.get('status')} />
        )}
      </Flex>
      <Divider spacing={20} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Controller
            as={CompanySelect}
            name="company"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('error:company.required')
              }
            }}
            error={errors.company?.message}
            id="new-order-company"
            onChange={handleCompanyChange}
            defaultValue={current.getIn(['company', 'id'])}
            disabled={loading}
            helperText={current.getIn(['metadata', 'company'])}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={SelectAdmin}
            control={control}
            error={errors.assignee?.message}
            name="assignee"
            label={t('common:shared.handledBy')}
            placeholder="Jane Doe"
            id="new-order-assignee"
            defaultValue={current.getIn(['assignee', 'id'])}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={SelectAdmin}
            control={control}
            error={errors.sales_rep?.message}
            name="sales_rep"
            label={t('common:shared.salesRep')}
            placeholder="John Doe"
            id="new-order-sales-rep"
            defaultValue={current.getIn(['sales_rep', 'id'])}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            name="start_date"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('error:date.required')
              }
            }}
            error={errors.start_date?.message}
            onChange={handleStartDateChange}
            type="datetime"
            label={t('common:shared.startDate')}
            placeholder={moment().format(DATE)}
            id="new-order-start-date"
            defaultValue={current.get('start_date')}
            disabled={loading}
            helperText={current.getIn(['metadata', 'delivery_date'])}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            name="delivery_date"
            control={control}
            error={errors.start_date?.message}
            type="datetime"
            label={t('common:shared.deliveryDate')}
            placeholder={moment().format(DATE)}
            id="new-order-delivery-date"
            defaultValue={current.get('delivery_date')}
            disabled={loading}
            helperText={current.getIn(['metadata', 'delivery_date'])}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            name="billing_date"
            control={control}
            error={errors.billing_date?.message}
            type="date"
            label={t('order:shared.billingDate')}
            placeholder={moment().add(9, 'd').format(DATE)}
            id="new-order-billing-date"
            defaultValue={current.get('billing_date')}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={PurchaseOrderField}
            name="external_id"
            control={control}
            error={errors.external_id?.message}
            label={t('order:shared.customerPO')}
            id="new-order-external-id"
            defaultValue={current.get('external_id') || current.getIn(['metadata', 'purchase_order'])}
            disabled={loading}
          />
        </Grid>
        {current.get('object') !== 'service_request' && (
          <Grid item xs={12} md={6}>
            <Controller
              as={PurchaseOrderField}
              name="metadata.rentall_external_id"
              control={control}
              error={errors.metadata?.rentall_external_id?.message || errors.internal_code?.message}
              label={t('order:shared.rentallPO')}
              id="new-order-rentall-external-id"
              defaultValue={current.getIn(['metadata', 'rentall_external_id']) || current.get('internal_code')?.slice(3)}
              disabled={loading}
            />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            control={control}
            name="metadata.duration"
            defaultValue={current.getIn(['metadata', 'duration'])}
            error={errors.metadata?.duration?.message}
            type="number"
            label={t('common:shared.duration')}
            placeholder={t('order:new.duration.placeholder')}
            id="new-order-duration"
            disabled={loading}
          />
        </Grid>
        {fields.map((i) => (
          <>
            <Grid item xs={12} md={6}>
              <Controller
                as={Input}
                name={`metadata.contacts[${i}].name`}
                control={control}
                error={errors.metadata?.contacts[i]?.name?.message}
                type="text"
                label={t('order:shared.siteContact')}
                placeholder={t('order:new.siteContact.placeholder')}
                id="new-service-request-contact-name"
                defaultValue={current.getIn(['metadata', 'contacts', i, 'name'])}
                disabled={loading}
                onBlur={() => {
                  onBlur(i)
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                as={Input}
                name={`metadata.contacts[${i}].number`}
                control={control}
                error={errors.metadata?.contacts[i]?.number?.message}
                type="tel"
                label={t('common:shared.contactNumber')}
                id="new-service-request-contact-number"
                defaultValue={current.getIn(['metadata', 'contacts', i, 'number'])}
                disabled={loading}
                onBlur={() => {
                  onBlur(i)
                }}
              />
            </Grid>
          </>
        ))}
        <AddressFields
          control={control}
          streetError={errors.address_line1?.message}
          streetDetailError={errors.address_line2?.message}
          zipcodeError={errors.address_zip?.message}
          cityError={errors.address_city?.message}
          stateError={errors.address_state?.message}
          countryError={errors.address_country?.message}
          streetDefaultValue={current.get('address_line1')}
          streetDetailDefaultValue={current.get('address_line2')}
          zipcodeDefaultValue={current.get('address_zip')}
          cityDefaultValue={current.get('address_city')}
          stateDefaultValue={current.get('address_state')}
          countryDefaultValue={current.getIn(['address_country', 'code'])}
          loading={loading}
        />
        {isRequestTransfer && (
          <>
            {[...Array(items.size)].map((x, i) => (
              <Grid item xs={4} md={12}>
                <Checkbox
                  name="items"
                  disabled={loading}
                  checked={isItemCheckedArr[i]}
                  onClick={() => setItemCheckedArr([...isItemCheckedArr.slice(0, i), !isItemCheckedArr[i], ...isItemCheckedArr.slice(i + 1)])}
                  label={`${items.getIn([i, 'quantity'])} x ${items.getIn([i, 'equipment', 'name'])}`}
                  data-cy="item-checkbox"
                />
              </Grid>
            ))}
          </>
        )}
        <Grid item xs={12} md={12}>
          <Controller
            as={Input}
            name="notes"
            control={control}
            error={errors.notes?.message}
            type="text"
            label={t('common:shared.notes')}
            placeholder={t('order:new.notes.placeholder')}
            multiline
            id="new-order-notes"
            defaultValue={current.get('notes')}
            disabled={loading}
          />
        </Grid>
      </Grid>
      <Divider spacing={20} />
      <Button type="submit" fullSize data-cy="new-order-submit-btn">
        {update ? t('order:edit.submit') : t('order:new.submit')}
      </Button>
    </form>
  )
}

Form.propTypes = {
  loading: PropTypes.bool,
  update: PropTypes.bool,
  current: PropTypes.instanceOf(Map)
}

Form.defaultProps = {
  loading: false,
  update: false,
  current: new Map()
}

export default Form
