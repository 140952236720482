import algoliasearch from 'algoliasearch/lite'
import { useSelector } from 'react-redux'
import { APP } from 'constants/algolia'

function useAlgolia() {
  const algoliaToken = useSelector(state => state.getIn(['session', 'algoliaToken']))
  const searchClient = algoliasearch(APP, algoliaToken)

  return searchClient
}

export default useAlgolia
