import React from 'react'
import { SupplierFormNew as OrderItemSupplierNew } from 'components/pages/order-items'
import { SupplierFormNew as ServiceRequestItemSupplierNew } from 'components/pages/service-request-items'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { supplierRates } from 'store/action-creators'
function Form() {
  const dispatch = useDispatch()
  dispatch(supplierRates.setCurrentId(''))
  dispatch(supplierRates.clearCurrentData())
  const location = useLocation()
  if (location.pathname === '/orders') return <OrderItemSupplierNew />
  if (location.pathname === '/requests') return <ServiceRequestItemSupplierNew />

  return null
}

export default Form
