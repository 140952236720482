import React from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from 'components/common'
import { SERVICE_REQUEST } from 'constants/resources'
import { ExtraContent, ExtraControls, ExtraHeader } from 'components/pages/extras'
import {
  SERVICE_REQUEST_INDEX,
  SERVICE_REQUEST_CREATED_ASC_INDEX,
  SERVICE_REQUEST_CREATED_DESC_INDEX,
} from 'constants/algolia'
import { withAlgolia } from 'components/hocs'

function ExtraTable() {
  const { t } = useTranslation('common')
  const columns = {
    sm: '1fr 1fr',
    md: '1fr 1fr 1fr 1fr',
    lg: '0.5fr 0.5fr 1fr 1fr 1fr 1.25fr 1fr 1fr 1fr',
    xl: '0.5fr 0.5fr 1fr 1fr 1fr 1.25fr 1fr 1fr 1fr',
  }

  return (
    <Table 
      title={t('resources.extras.plural')}
      controls={<ExtraControls />}
      resource={SERVICE_REQUEST}
      header={(
        <ExtraHeader 
          columns={columns}
          defaultRefinement={SERVICE_REQUEST_INDEX}
          items={[
            SERVICE_REQUEST_CREATED_ASC_INDEX,
            SERVICE_REQUEST_CREATED_DESC_INDEX,
          ]}
        />
      )}
      content={<ExtraContent columns={columns} />}
    />
  )
}

export default withAlgolia(SERVICE_REQUEST_INDEX)(ExtraTable)
