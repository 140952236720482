import React, { useState } from 'react'
import MaterialMenu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { Icon } from 'components/ui'
import styled from 'styled-components'
import actions from 'store/action-creators'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const Box = styled.div`
  width: 200px;
  height: 56px;
  color: ${({ theme }) => theme.color.secondary};
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 12px;
  font-size: 14px;
  box-sizing: border-box;
  font-weight: 400; 
  transition: 100ms ease-in;
  position: relative;
  cursor: pointer;
  > span {
    margin-right: 0.25em;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.13);
  }
`

function QuickFilters({ resource, options }) {
  const { t } = useTranslation('common')
  const [anchor, setAnchor] = useState(null)
  const dispatch = useDispatch()

  const handleOpen = (event) => {
    event.stopPropagation()
    setAnchor(event.currentTarget)
  }

  const handleClose = (event) => {
    event.stopPropagation()
    setAnchor(null)
  }

  const handleChange = val => (event) => {
    if (Array.isArray(val)) {
      val.forEach((filter) => {
        dispatch(actions[resource].addListSearchFacetFilters(filter))
      })
    } else {
      dispatch(actions[resource].addListSearchFacetFilters(val))
    }
    handleClose(event)
  }

  return (
    <div className="desktop-only">
      <Box onClick={handleOpen} id="quick-filter-btn">
        <Icon name="flash_on" size={18} />
        {t('shared.quickFilters')}
      </Box>
      <MaterialMenu
        anchorEl={anchor}
        keepMounted
        getContentAnchorEl={null}
        open={!!anchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {
          options.map(option => (
            <MenuItem
              key={option.label}
              onClick={handleChange(option.value)}
            >
              {option.label}
            </MenuItem>
          ))
        }
      </MaterialMenu>
    </div>
  )
}

QuickFilters.propTypes = {
  resource: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
}


export default QuickFilters
