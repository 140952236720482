import React from 'react'
import { Input } from 'components/common'
import InputAdornment from '@material-ui/core/InputAdornment'

function PriceField(props) {
  return (
    <Input
      type="number"
      placeholder="89.99"
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
      {...props}
    />
  )
}


export default PriceField
