import React from 'react'
import { SupplierFormEdit as OrderItemSupplierEdit } from 'components/pages/order-items'
import { SupplierFormEdit as ServiceRequestItemSupplierEdit } from 'components/pages/service-request-items'
import { useLocation } from 'react-router-dom'
function Form() {
  const location = useLocation()
  if (location.pathname === '/orders') return <OrderItemSupplierEdit />
  if (location.pathname === '/requests') return <ServiceRequestItemSupplierEdit />

  return null
}

export default Form
