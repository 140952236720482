import React from 'react'
import { useTranslation } from 'react-i18next'
import { Chip } from 'components/common'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { serviceRequests } from 'store/action-creators'
import { CANCELLED, DEAD, RESOLVED } from 'constants/status'
import { Map } from 'immutable'
import { NEW } from 'constants/crud'
import { ORDER, RENTAL_PRICE } from 'constants/designations'
import { useQuickPreview } from 'components/hooks'
import { SERVICE_REQUEST } from 'constants/resources'

function CreateOrderButton({ id, status, designation, serviceRequest }) {
  const { t } = useTranslation(SERVICE_REQUEST)
  const dispatch = useDispatch()
  const [openPreview] = useQuickPreview()

  const handleChipClick = () => {
    openPreview(SERVICE_REQUEST, NEW)
    dispatch(serviceRequests.setCreateData(serviceRequest.set('designation', ORDER)))
  }

  if (RENTAL_PRICE !== designation || [CANCELLED, RESOLVED, DEAD].includes(status)) {
    return null
  }

  return <Chip outline icon="add" onClick={handleChipClick} label={t('serviceRequest:shared.order')} />
}

CreateOrderButton.propTypes = {
  status: PropTypes.string,
  id: PropTypes.string,
  designation: PropTypes.string,
  serviceRequest: PropTypes.instanceOf(Map)
}

CreateOrderButton.defaultProps = {
  status: '',
  id: '',
  designation: '',
  serviceRequest: new Map()
}

export default CreateOrderButton
