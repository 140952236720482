import React from 'react'
import { DetailHeader, Chip } from 'components/common'
import PropTypes from 'prop-types'
import Status from 'components/pages/order-items/Status'
import PlaceService from 'components/pages/order-items/PlaceService'
import EditButton from 'components/pages/order-items/EditButton'
import { Map } from 'immutable'
import { SUPPLIER_RATE } from 'constants/resources'
import { useQuickPreview } from 'components/hooks'
import { NEW } from 'constants/crud'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { CANCELLED, FINALIZED } from 'constants/status'
import { orderItems, orders } from 'store/action-creators'

function Header({ isEdit, status, loading, orderItemId, orderItem, equipmentName, small }) {
  const { t } = useTranslation('common')
  const [openPreview] = useQuickPreview()
  const order = useSelector((state) => state.getIn(['order', 'current', 'data']))
  const dispatch = useDispatch()
  const onAddSupplier = () => {
    dispatch(orderItems.setCurrentId(orderItemId))
    dispatch(orderItems.setCurrentData(orderItem))
    openPreview(SUPPLIER_RATE, NEW)
  }

  const handleStatusChange = (val) => {
    const itemIndex = order.get('items').findIndex((item) => item.get('id') === orderItemId)
    let confirmed = true
    if ([CANCELLED, FINALIZED].includes(status)) {
      confirmed = window.confirm(t('error:status.warning'))
    }
    if (confirmed) {
      dispatch(orderItems.setCurrentId(orderItemId))
      dispatch(
        orderItems.update({ status: val }, () => {
          dispatch(orders.setCurrentData(order.setIn(['items', itemIndex, 'status'], val)))
        })
      )
    }
  }

  return (
    <DetailHeader title={equipmentName} loading={loading} variant={small && 'small'}>
      <Chip
        onClick={onAddSupplier}
        disabled={(orderItem.get('selected_supplier') && orderItem.get('selected_transport_supplier')) || [CANCELLED, FINALIZED].includes(status)}
        outline
        label={'+ ' + t('common:resources.suppliers.singular')}
      />
      {isEdit ? <EditButton orderItem={orderItem} /> : null}
      <PlaceService
        designation="service-call"
        status={status}
        orderItemId={orderItemId}
        salesRepId={order.getIn(['sales_rep', 'id'])}
        addressLine1={order.get('address_line1')}
        addressLine2={order.get('address_line2')}
        addressZip={order.get('address_zip')}
        addressCity={order.get('address_city')}
        addressState={order.get('address_state')}
        addressCountryCode={order.getIn(['address_country', 'code'])}
        contacts={order.getIn(['metadata', 'contacts'])}
      />
      <Status defaultValue={status} id={orderItemId} onChange={handleStatusChange} />
    </DetailHeader>
  )
}

Header.propTypes = {
  title: PropTypes.string,
  equipmentName: PropTypes.string,
  status: PropTypes.string,
  loading: PropTypes.bool,
  isEdit: PropTypes.bool,
  small: PropTypes.bool,
  orderId: PropTypes.string.isRequired,
  orderItem: PropTypes.instanceOf(Map).isRequired,
  orderItemId: PropTypes.string
}

Header.defaultProps = {
  title: '',
  equipmentName: '',
  status: '',
  orderItemId: '',
  loading: false,
  isEdit: false,
  small: false
}

export default Header
