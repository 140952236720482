import React, { useState, useCallback } from 'react'
import { Input, AddressFields, Button, Flex } from 'components/common'
import { useDispatch, useSelector } from 'react-redux'
import { Divider } from 'components/ui'
import { companies, serviceRequests } from 'store/action-creators'
import { useTranslation } from 'react-i18next'
import SelectAdmin from 'components/pages/users/SelectAdmin'
import { Grid } from '@material-ui/core'
import ItemRow, { AddItemButton } from './ItemRow'
import { useForm, Controller, FormContext } from 'react-hook-form'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { SERVICE_REQUEST } from 'constants/resources'
import { CompanySelect } from 'components/pages/companies'
import { useAttachment, useMultipleContacts } from 'components/hooks'
import { OPERATIONS, SUPERUSER } from 'constants/roles'
import Checkbox from 'components/common/Checkbox'
import { RENTAL_PRICE } from 'constants/designations'
import moment from 'moment'

function Form({ current, loading, update }) {
  const role = useSelector((state) => state.getIn(['session', 'user', 'role']))
  const currentRequest = useSelector((state) => state.getIn(['serviceRequest', 'current']))
  const currentUser = useSelector((state) => state.getIn(['session', 'user', 'id']))
  const serviceRequestItems = current.get('service_request_items')
  const isRequestTransfer = currentRequest.getIn(['data', 'designation']) === RENTAL_PRICE
  const isOperations = [OPERATIONS, SUPERUSER].includes(role)
  const isAdmin = [SUPERUSER].includes(role)
  const { t } = useTranslation([SERVICE_REQUEST, 'error'])
  const dispatch = useDispatch()
  const [attachmentPayload, setFile] = useAttachment()
  const [isItemCheckedArr, setItemCheckedArr] = useState(new Array(isRequestTransfer ? serviceRequestItems.size : 0).fill(true))
  const setupItemsData = () => {
    if (update) {
      var updatedItemsData = {}
      for (let i = 0; i < serviceRequestItems.size; i++) {
        updatedItemsData = {
          ...updatedItemsData,
          [`item_${i}`]: {
            equipment: serviceRequestItems.getIn([i, 'equipment', 'name']),
            quantity: serviceRequestItems.getIn([i, 'quantity']),
            isItemValid: true,
            id: serviceRequestItems.getIn([i, 'id'])
          }
        }
      }
      return updatedItemsData
    } else {
      return { item_0: { equipment: '', quantity: NaN, isItemValid: true } }
    }
  }
  const [itemsData, setItemsData] = useState(setupItemsData())

  const { handleSubmit, control, errors, register, getValues, setValue } = useForm({
    mode: 'onBlur',
    defaultValues: {
      designation: 'order',
      metadata: {
        company: current.getIn(['metadata', 'company']),
        company_id: current.getIn(['metadata', 'company_id']),
        duration: current.getIn(['metadata', 'duration']),
        delivery_date: current.getIn(['metadata', 'delivery_date']),
        purchase_order: current.getIn(['metadata', 'purchase_order']),
        current_supplier: current.getIn(['metadata', 'current_supplier']),
        opsNotes: current.getIn(['metadata', 'opsNotes'])
      },
      state: current.get('state'),
      assignee: current.getIn(['assignee', 'id']),
      notes: current.get('notes'),
      address_line1: current.get('address_line1'),
      address_line2: current.get('address_line2'),
      address_zip: current.get('address_zip'),
      address_city: current.get('address_city'),
      address_state: current.get('address_state'),
      address_country: current.getIn(['address_country', 'code']),
      contacts: current.getIn(['metadata', 'contacts'])?.map((contact) => contact.toJS()),
      turnaround_time: moment.duration(current.get('turnaround_time')).asHours().toFixed(2),
      sales_rep: current.getIn(['sales_rep', 'id']) || currentUser
    }
  })
  const { fields, onBlur, cleanContacts } = useMultipleContacts({
    initialCount: current.getIn(['metadata', 'contacts'])?.size || 1,
    getValues
  })

  const onSubmit = (data) => {
    if (Object.values(itemsData).some((obj) => !obj.isItemValid)) {
      return
    }
    const sendBody = cleanContacts(data)
    const requestBody = { ...attachmentPayload(sendBody) }

    if (update) {
      if (current.getIn(['metadata', 'rental_price', 'id'])) {
        requestBody.metadata.rental_price = {
          id: current.getIn(['metadata', 'rental_price', 'id']),
          code: current.getIn(['metadata', 'rental_price', 'code'])
        }
      } else {
        delete requestBody.metadata.rental_price
      }
      const body = {
        ...requestBody,
        items: itemsData,
        index: serviceRequestItems.size,
        ...(requestBody['turnaround_time'] && { turnaround_time: moment.duration(requestBody['turnaround_time'], 'hours').asMilliseconds() })
      }
      dispatch(serviceRequests.update(body))
    } else {
      if (isRequestTransfer) {
        const checkedItems = serviceRequestItems?.filter((_, index) => isItemCheckedArr[index]).toJS()
        const body = {
          ...requestBody,
          items: checkedItems,
          metadata: {
            ...requestBody.metadata,
            rental_price: {
              id: currentRequest.get('id'),
              code: currentRequest.getIn(['data', 'internal_code'])
            },
            checkedItems: checkedItems,
            slack_notification_items: serviceRequestItems
              ?.filter((_, index) => isItemCheckedArr[index])
              ?.map((item) => {
                return {
                  equipment: item.getIn(['equipment', 'name']),
                  quantity: item.get('quantity')
                }
              })
          },
          isRequestTransfer: true
        }
        dispatch(serviceRequests.create(body))
      } else {
        requestBody.metadata = metadataSlackNotification(requestBody.metadata)
        const body = { ...requestBody, items: itemsData }
        dispatch(serviceRequests.create(body))
      }
    }
  }
  const metadataSlackNotification = (metadata) => {
    return {
      ...metadata,
      slack_notification_items: Object.keys(itemsData)?.map((key) => {
        return {
          equipment: itemsData[key].equipment.label,
          quantity: itemsData[key].quantity
        }
      })
    }
  }
  const handleCompanyChange = (results) => {
    const [option] = results
    if (!option) return undefined

    // Set ID for backend to recognize new/existing company
    setValue('metadata.company_id', option?.value)
    if (!update && option.value) {
      dispatch(
        companies.get(option.value, (val) => {
          setValue('metadata.current_supplier', val.metadata?.current_supplier)
        })
      )
    }
    return (option && option.label) || option
  }

  const onChange = useCallback(
    (key, rowData, field) => {
      switch (field) {
        case 'equipment':
          if (typeof rowData === 'object' && rowData !== null) {
            setItemsData((prevState) => {
              return {
                ...prevState,
                [key]: {
                  ...prevState[key],
                  [field]: rowData,
                  isItemValid: true
                }
              }
            })
          } else {
            if (rowData === itemsData[key].equipment.label) {
              return
            }
            setItemsData((prevState) => {
              return {
                ...prevState,
                [key]: {
                  ...prevState[key],
                  isItemValid: false
                }
              }
            })
          }
          break
        case 'quantity':
          setItemsData((prevState) => {
            return {
              ...prevState,
              [key]: {
                ...prevState[key],
                [field]: rowData
              }
            }
          })
          break
        default:
          break
      }
    },
    [itemsData]
  )

  const onDelete = useCallback((key) => {
    setItemsData((prevState) => {
      if (Object.keys(prevState).length > 1) {
        delete prevState[key]
      }
      return {
        ...prevState
      }
    })
  }, [])

  const addItemRow = () => {
    let keys = Object.keys(itemsData),
      keysIndex = keys.map(function (d) {
        return d.replace('item_', '')
      })
    let maxIndex = 0
    if (keysIndex.length > 0) {
      maxIndex = Math.max(...keysIndex.map((i) => Number(i)))
    }
    setItemsData({
      ...itemsData,
      [`item_${maxIndex + 1}`]: { equipment: '', quantity: '', isItemValid: true }
    })
  }

  return (
    <FormContext handleSubmit={handleSubmit} control={control} errors={errors} register={register} getValues={getValues} setValue={setValue}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex align="center" justify="space-between">
          <h1>{t('common:shared.information')}</h1>
        </Flex>
        <Divider spacing={20} />
        <input type="hidden" name="designation" value="order" ref={register} />
        <input type="hidden" name="metadata.company_id" ref={register} />
        <input type="hidden" name="metadata.opsNotes" ref={register} />

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Controller
              as={CompanySelect}
              name="metadata.company"
              error={errors?.metadata?.company?.message}
              defaultValue={current.getIn(['metadata', 'company'])}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t('error:company.required')
                }
              }}
              required
              freeSolo
              id="new-service-request-company"
              autoSelect={false}
              clearOnBlur={false}
              onChange={handleCompanyChange}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              as={SelectAdmin}
              control={control}
              error={errors.assignee?.message}
              name="assignee"
              placeholder="Jane Doe"
              id="new-service-request-assignee"
              defaultValue={current.getIn(['assignee', 'id'])}
              disabled={loading || !update}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              as={SelectAdmin}
              control={control}
              error={errors.sales_rep?.message}
              name="sales_rep"
              label={t('common:shared.salesRep')}
              placeholder="John Doe"
              id="new-service-request-sales-rep"
              defaultValue={current.getIn(['sales_rep', 'id'])}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              as={Input}
              control={control}
              name="metadata.duration"
              error={errors.metadata?.duration?.message}
              defaultValue={current.getIn(['metadata', 'duration'])}
              type="number"
              required
              rules={{
                required: {
                  value: true,
                  message: t('error:duration.required')
                }
              }}
              label={t('common:shared.duration')}
              placeholder={t('serviceRequest:new.duration.placeholder')}
              id="new-service-request-duration"
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              as={Input}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t('error:deliveryDate.required')
                }
              }}
              name="metadata.delivery_date"
              error={errors.metadata?.delivery_date?.message}
              defaultValue={current.getIn(['metadata', 'delivery_date'])}
              type="datetime"
              label={t('serviceRequest:shared.deliveryDate')}
              placeholder={t('serviceRequest:new.deliveryDate.placeholder')}
              id="new-service-request-deliveryDate"
              required
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              as={Input}
              control={control}
              name="metadata.purchase_order"
              error={errors.metadata?.purchase_order?.message}
              defaultValue={current.getIn(['metadata', 'purchase_order'])}
              type="text"
              label={t('serviceRequest:shared.purchaseOrder')}
              placeholder={t('serviceRequest:new.purchaseOrder.placeholder')}
              id="new-service-request-purchaseOrder"
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              as={Input}
              control={control}
              name="metadata.current_supplier"
              error={errors.metadata?.current_supplier?.message}
              defaultValue={current.getIn(['metadata', 'current_supplier'])}
              type="text"
              label={t('serviceRequest:shared.currentSupplier')}
              id="new-service-request-currentSupplier"
              placeholder={t('serviceRequest:new.currentSupplier.placeholder')}
              disabled={loading}
            />
          </Grid>
          {fields.map((i) => (
            <>
              <Grid item xs={12} md={6}>
                <Controller
                  as={Input}
                  name={`metadata.contacts[${i}].name`}
                  control={control}
                  error={errors.metadata?.contacts?.[i]?.name?.message}
                  type="text"
                  label={t('order:shared.siteContact')}
                  placeholder={t('order:new.siteContact.placeholder')}
                  id="new-service-request-contact-name"
                  defaultValue={current.getIn(['metadata', 'contacts', i, 'name'])}
                  disabled={loading}
                  onBlur={() => {
                    onBlur(i)
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  as={Input}
                  name={`metadata.contacts[${i}].number`}
                  control={control}
                  error={errors.metadata?.contacts?.[i]?.number?.message}
                  type="tel"
                  label={t('common:shared.contactNumber')}
                  id="new-service-request-contact-number"
                  defaultValue={current.getIn(['metadata', 'contacts', i, 'number'])}
                  disabled={loading}
                  onBlur={() => {
                    onBlur(i)
                  }}
                />
              </Grid>
            </>
          ))}

          <AddressFields
            control={control}
            streetError={errors.address_line1?.message}
            streetDetailError={errors.address_line2?.message}
            zipcodeError={errors.address_zip?.message}
            cityError={errors.address_city?.message}
            stateError={errors.address_state?.message}
            countryError={errors.address_country?.message}
            streetDefaultValue={current.get('address_line1')}
            streetDetailDefaultValue={current.get('address_line2')}
            zipcodeDefaultValue={current.get('address_zip')}
            cityDefaultValue={current.get('address_city')}
            stateDefaultValue={current.get('address_state')}
            countryDefaultValue={current.getIn(['address_country', 'code'])}
            loading={loading}
            streetRequired
            zipcodeRequired
            cityRequired
            stateRequired
            countryRequired
          />

          <Grid item xs={12} md={12}>
            <h3>{t('common:resources.equipment.plural')}</h3>
          </Grid>
          {!update && !isRequestTransfer && (
            <>
              {Object.keys(itemsData).map((key) => {
                return <ItemRow key={key} rowKey={key} onChange={onChange} onDelete={onDelete} isItemValid={itemsData[key].isItemValid} />
              })}
              <AddItemButton id="add-item-row-btn" onClick={() => addItemRow()} type="button">
                + {t('serviceRequest:items.add')}
              </AddItemButton>
            </>
          )}
          {update && !isRequestTransfer && (
            <>
              {Object.keys(itemsData).map((key, index) => {
                return (
                  <ItemRow
                    equipment={itemsData[key].equipment}
                    quantity={itemsData[key].quantity}
                    isItemValid={itemsData[key].isItemValid}
                    key={key}
                    rowKey={key}
                    onChange={update && serviceRequestItems && index < serviceRequestItems.size ? undefined : onChange}
                    onDelete={update && serviceRequestItems && index < serviceRequestItems.size ? undefined : onDelete}
                    disabled={(update && serviceRequestItems && index < serviceRequestItems.size) || loading}
                  />
                )
              })}
              <AddItemButton id="add-item-row-btn" onClick={() => addItemRow()} type="button" disabled={loading}>
                + {t('serviceRequest:items.add')}
              </AddItemButton>
            </>
          )}
          {isRequestTransfer && (
            <>
              {[...Array(serviceRequestItems.size)].map((x, i) => (
                <Grid item xs={4} md={12}>
                  <Checkbox
                    name="items"
                    disabled={loading}
                    checked={isItemCheckedArr[i]}
                    onClick={() => setItemCheckedArr([...isItemCheckedArr.slice(0, i), !isItemCheckedArr[i], ...isItemCheckedArr.slice(i + 1)])}
                    label={`${serviceRequestItems.getIn([i, 'quantity'])} x ${serviceRequestItems.getIn([i, 'equipment', 'name'])}`}
                    data-cy="item-checkbox"
                  />
                </Grid>
              ))}
            </>
          )}
          {isAdmin && update && (
            <Grid item xs={12} md={12}>
              <Controller
                as={Input}
                control={control}
                error={errors.turnaround_time?.message}
                name="turnaround_time"
                type="number"
                placeholder="0.00"
                label={t('serviceRequest:shared.turnaroundTime')}
                id="new-service-request-turnaround-time"
                defaultValue={moment.duration(current.get('turnaround_time')).asHours().toFixed(2)}
                disabled={loading}
              />
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            <Controller
              as={Input}
              control={control}
              error={errors.notes?.message}
              name="notes"
              type="text"
              multiline
              label={t('common:shared.notes')}
              placeholder={t('serviceRequest:new.notes.placeholder')}
              id="new-service-request-notes"
              defaultValue={current.get('notes')}
              disabled={loading}
            />
          </Grid>
          {isOperations ? (
            <div>
              <Grid item xs={12} md={12}>
                <Controller
                  as={Input}
                  control={control}
                  error={errors.metadata?.opsNotes?.message}
                  name="metadata.opsNotes"
                  type="text"
                  multiline
                  label={t('serviceRequest:new.opsNotes.label')}
                  placeholder={t('serviceRequest:new.opsNotes.placeholder')}
                  id="new-service-request-opsNotes"
                  defaultValue={current.getIn(['metadata', 'opsNotes'])}
                  disabled={loading}
                  required
                  rules={{
                    required: {
                      value: true,
                      message: t('error:opsNote.required')
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Input
                  type="file"
                  max={15}
                  label={t('serviceRequest:shared.opsAttachment')}
                  maxMessage={t('error:file.max')}
                  helperText={t('common:file.helper', { max: 15 })}
                  placeholder={t('common:file.placeholder')}
                  dropPlaceholder={t('common:file.dropPlaceholder')}
                  onChange={setFile}
                  name="opsAttachment"
                  id="new-service-request-opsAttachment"
                />
              </Grid>
            </div>
          ) : null}
        </Grid>
        <Divider spacing={20} />
        <Button type="submit" fullSize disabled={loading}>
          {update ? t('serviceRequest:edit.submitRequest') : t('serviceRequest:new.submitRequest')}
        </Button>
      </form>
    </FormContext>
  )
}

Form.propTypes = {
  loading: PropTypes.bool,
  update: PropTypes.bool,
  current: PropTypes.instanceOf(Map)
}

Form.defaultProps = {
  loading: false,
  update: false,
  current: new Map()
}

export default Form
