import React from 'react'
import { EquipmentRow } from 'components/pages/equipment'
import { TableContent, TableLoading } from 'components/common'
import { connectInfiniteHits, Configure } from 'react-instantsearch-dom'
import { EQUIPMENT } from 'constants/resources'
import { useResource, useStoreAlgolia, useSelectResource, useDeviceColumns, useFacetFilters } from 'components/hooks'
import { equipment } from 'store/action-creators'
import PropTypes from 'prop-types'

function EquipmentContent({ hits, hasMore, refineNext, hasPrevious, refinePrevious, columns }) {
  const selected = useResource(EQUIPMENT, 'current', 'id')
  const list = useStoreAlgolia(EQUIPMENT, equipment, hits)
  const loading = useResource(EQUIPMENT, 'list', 'loading')
  const handleRowClick = useSelectResource(EQUIPMENT)
  const templateColumns = useDeviceColumns(columns, !!selected)
  const facetFilters = useFacetFilters(EQUIPMENT)

  return (
    <TableContent next={refineNext} dataLength={hits.length} hasMore={hasMore}>
      <Configure hitsPerPage={40} facetFilters={facetFilters} />
      <TableLoading loading={loading} />
      {list.map((equip) => (
        <EquipmentRow
          key={equip.get('id')}
          id={equip.get('id')}
          shrink={!!selected}
          templateColumns={templateColumns}
          code={equip.get('code')}
          name={equip.get('name')}
          description={equip.get('description')}
          categoryId={equip.getIn(['category', 'id'])}
          categoryName={equip.getIn(['category', 'name'])}
          subcategoryId={equip.getIn(['subcategory', 'id'])}
          subcategoryName={equip.getIn(['subcategory', 'name'])}
          totalOrders={equip.get('number_of_orders')}
          active={equip.get('id') === selected}
          onClick={() => handleRowClick(equip.get('id'))}
        />
      ))}
    </TableContent>
  )
}

EquipmentContent.propTypes = {
  columns: PropTypes.object.isRequired,
  hits: PropTypes.array.isRequired,
  hasMore: PropTypes.bool.isRequired,
  refineNext: PropTypes.func.isRequired,
  hasPrevious: PropTypes.bool.isRequired,
  refinePrevious: PropTypes.func.isRequired
}

export default connectInfiniteHits(EquipmentContent)
