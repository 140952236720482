import React, { useMemo, useState } from 'react'
import { Menu } from 'components/common'
import { useTranslation } from 'react-i18next'
import {
  CREATED,
  IN_PROGRESS,
  CANCELLED,
  RESOLVED,
  RE_OPENED,
  FUTURE,
  PENDING_PAY,
  DEAD,
  INFO,
  READY,
  APPROVED,
  REJECTED,
  BILLED,
  ESTIMATE
} from 'constants/status'
import PropTypes from 'prop-types'
import { SERVICE_REQUEST } from 'constants/resources'
import { useStatusColors } from 'components/hooks'
import { useDispatch } from 'react-redux'
import { serviceRequestMessages, serviceRequests } from 'store/action-creators'
import { ORDER, PURCHASE_PRICE, RENTAL_PRICE, EXTRA } from 'constants/designations'

function Status({ defaultValue, onChange, serviceRequestId, designation }) {
  const dispatch = useDispatch()
  const { t } = useTranslation([SERVICE_REQUEST, 'error'])
  const color = useStatusColors(SERVICE_REQUEST)
  const [selected, setSelected] = useState(defaultValue)
  const options = useMemo(() => {
    const initialOptions = [{ label: t(`status.${CREATED}`), value: CREATED, color: color[CREATED] }]
    if (designation === EXTRA) {
      initialOptions.push(
        { label: t(`status.${INFO}`), value: INFO, color: color[INFO] },
        { label: t(`status.${ESTIMATE}`), value: ESTIMATE, color: color[ESTIMATE] },
        { label: t(`status.${READY}`), value: READY, color: color[READY] },
        { label: t(`status.${APPROVED}`), value: APPROVED, color: color[APPROVED] },
        { label: t(`status.${REJECTED}`), value: REJECTED, color: color[REJECTED] },
        { label: t(`status.${BILLED}`), value: BILLED, color: color[BILLED] }
      )
    } else {
      initialOptions.push(
        { label: t(`status.${IN_PROGRESS}`), value: IN_PROGRESS, color: color[IN_PROGRESS] },
        { label: t(`status.${PENDING_PAY}`), value: PENDING_PAY, color: color[PENDING_PAY] },
        { label: t(`status.${RESOLVED}`), value: RESOLVED, color: color[RESOLVED] },
        { label: t(`status.${CANCELLED}`), value: CANCELLED, color: color[CANCELLED] },
        { label: t(`status.${RE_OPENED}`), value: RE_OPENED, color: color[RE_OPENED] }
      )
      if ([ORDER, RENTAL_PRICE, PURCHASE_PRICE].includes(designation)) {
        initialOptions.push(
          { label: t(`status.${FUTURE}`), value: FUTURE, color: color[FUTURE] },
          { label: t(`status.${DEAD}`), value: DEAD, color: color[DEAD] }
        )
      }
    }
    return initialOptions
  }, [color, designation, t])

  const handleUpdateSuccess = (response, content) => {
    setSelected(response.status)
    if (content) {
      dispatch(
        serviceRequestMessages.create({
          content,
          service_request: serviceRequestId
        })
      )
    }
  }

  const handleChange = (status) => {
    if (onChange) {
      onChange(status)
      setSelected(status)
    } else {
      let confirmed = true
      let content = ''
      if ([CANCELLED, RESOLVED, DEAD].includes(status)) {
        confirmed = window.confirm(t('error:status.warning'))
      }
      if (status === 're-opened') {
        content = window.prompt(t('serviceRequest:shared.statePrompt'))
        confirmed = !!content
      }
      if (confirmed) {
        setSelected(status)
        dispatch(serviceRequests.update({ status }, (response) => handleUpdateSuccess(response, content)))
      }
    }
  }

  return (
    <Menu
      value={selected}
      // disabled={!canEdit}
      options={options}
      onChange={handleChange}
    />
  )
}

Status.propTypes = {
  defaultValue: PropTypes.string,
  serviceRequestId: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  designation: PropTypes.string
}

Status.defaultProps = {
  defaultValue: '',
  onChange: undefined,
  designation: ''
}

export default Status
