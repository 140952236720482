import React from 'react'
import PropTypes from 'prop-types'
import { Price, Detail, PreviewLink } from 'components/common'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import { SUPPLIER, ORDER_ITEM, SUPPLIER_RATE, SERVICE_REQUEST } from 'constants/resources'
import { EDIT } from 'constants/crud'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Checkbox from '@material-ui/core/Checkbox'
import { Divider } from 'components/ui'
import { Chip } from 'components/common'
import { supplierRates, serviceRequests } from 'store/action-creators'
import { useDispatch } from 'react-redux'
import { useQuickPreview, useCurrent } from 'components/hooks'
import { withStyles } from '@material-ui/core/styles'
const CustomCheckbox = withStyles({
  checked: {
    '& .MuiSvgIcon-root': {
      color: '#8D8D8D'
    }
  }
})(Checkbox)

function SuppliersInformation({
  supplier,
  supplierName,
  supplierId,
  dailyRate,
  weeklyRate,
  monthlyRate,
  transport,
  notes,
  loading,
  checked,
  onSelect
}) {
  const { t } = useTranslation(['common', ORDER_ITEM, 'serviceRequest'])
  const dispatch = useDispatch()
  const [openPreview] = useQuickPreview()
  const currentServiceRequest = useCurrent(SERVICE_REQUEST)

  const onEdit = (event) => {
    event.stopPropagation()

    dispatch(supplierRates.setCurrentData(supplier))
    dispatch(supplierRates.setCurrentId(supplier.get('id')))
    openPreview(SUPPLIER_RATE, EDIT)
  }

  const onDelete = (event) => {
    event.stopPropagation()
    if (window && window.confirm(t('shared.deletionConfirm'))) {
      const rateId = supplier.get('id')
      const itemIndex = currentServiceRequest.get('service_request_items').findIndex(
        (item) =>
          item.get('id') ===
          item
            .get('supplier_rates')
            .find((rate) => rate.get('id') === rateId)
            ?.getIn(['service_request_item', 'id'])
      )
      const rateIndex = currentServiceRequest
        .getIn(['service_request_items', itemIndex, 'supplier_rates'])
        .findIndex((rate) => rate.get('id') === rateId)
      const serviceRequest = currentServiceRequest.toJS()
      serviceRequest.service_request_items[itemIndex].supplier_rates.splice(rateIndex, 1)
      dispatch(supplierRates.delete(rateId))
      dispatch(serviceRequests.setCurrentData(serviceRequest))
    }
  }
  return (
    <Grid container spacing={2}>
      <Accordion style={{ width: '90%', marginLeft: 25, border: checked ? '2px solid black' : null }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div>
              <PreviewLink resource={SUPPLIER} id={supplierId}>
                <p style={{ paddingBottom: 5 }}>{supplierName}</p>
              </PreviewLink>
              <Chip onClick={onEdit} outline icon="edit" label={t('shared.edit')} />
              <Chip onClick={onDelete} outline icon="delete" label={t('shared.delete')} />
            </div>
            <CustomCheckbox
              checked={checked}
              disabled={checked}
              onChange={onSelect}
              size="small"
              inputProps={{ 'aria-label': 'controlled' }}
              data-cy="select-supplier-checkbox"
            />
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ display: 'flex', flexDirection: 'column', margin: 'auto', width: '95%' }}>
            {(dailyRate !== undefined || weeklyRate !== undefined || monthlyRate !== undefined) && (
              <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <Grid item xs={12} md={6}>
                  <Detail text={dailyRate} loading={loading} label={t('common:shared.dailyRate')}>
                    <Price amount={dailyRate} />
                  </Detail>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Detail text={weeklyRate} loading={loading} label={t('common:shared.weeklyRate')}>
                    <Price amount={weeklyRate} />
                  </Detail>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Detail text={monthlyRate} loading={loading} label={t('common:shared.monthlyRate')}>
                    <Price amount={monthlyRate} />
                  </Detail>
                </Grid>
              </div>
            )}
            {[...Array(transport ? transport.size : 0)].map((x, i) => {
              return (
                <div
                  key={transport.getIn([i, 'type']) + transport.getIn([i, 'value'])}
                  style={{ display: 'flex', flexDirection: 'row', width: '100%', paddingTop: i !== 0 && '10px' }}
                >
                  <Grid item xs={12} md={6} data-cy="transport-info-transport">
                    <Detail text={transport.getIn([i, 'type'])} loading={loading} label={t('common:resources.transport.singular')}>
                      {transport.getIn([i, 'type'])}
                    </Detail>
                  </Grid>
                  <Grid item xs={12} md={6} data-cy="transport-info-rate">
                    <Detail text={transport.getIn([i, 'value'])} loading={loading} label={t('serviceRequest:pdf.rate')}>
                      <Price amount={transport.getIn([i, 'value'])} />
                    </Detail>
                  </Grid>
                </div>
              )
            })}
            <Divider spacing={20} />
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
              <Grid item xs={12} md={6} data-cy="itemized-info-notes">
                <Detail text={notes} loading={loading} label={t('common:shared.notes')}>
                  {notes}
                </Detail>
              </Grid>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </Grid>
  )
}

SuppliersInformation.propTypes = {
  supplierName: PropTypes.string,
  supplierId: PropTypes.string,
  dailyRate: PropTypes.number,
  weeklyRate: PropTypes.number,
  monthlyRate: PropTypes.number,
  loading: PropTypes.bool,
  supplierIndex: PropTypes.number,
  itemIndex: PropTypes.number,
  transport: PropTypes.any,
  notes: PropTypes.string,
  checked: PropTypes.bool,
  onSelect: PropTypes.any
}

SuppliersInformation.defaultProps = {
  supplierName: '',
  supplierId: '',
  dailyRate: null,
  weeklyRate: null,
  monthlyRate: null,
  loading: false,
  supplierIndex: 0,
  itemIndex: 0,
  transport: [],
  notes: '',
  checked: true
}

export default SuppliersInformation
