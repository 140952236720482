import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Divider } from 'components/ui'
import { Input, AddressFields, Button } from 'components/common'
import { suppliers } from 'store/action-creators'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { PHONE } from 'constants/patterns'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { SUPPLIER } from 'constants/resources'
import CategorySelect from './CategorySelect'

function Form({ current, loading, update }) {
  const [file, setFile] = useState(null)
  const { t } = useTranslation(['common', SUPPLIER, 'error'])
  const dispatch = useDispatch()
  const { handleSubmit, control, errors } = useForm({ mode: 'onBlur' })

  const onSubmit = (data) => {
    let formData
    if (file) {
      formData = new FormData()
      formData.append('price_sheet', file)
    }
    const body = {
      ...data,
      priceSheet: formData,
    }
    if (update) {
      dispatch(suppliers.update(body))
    } else {
      dispatch(suppliers.create(body))
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h1>{t('common:shared.information')}</h1>
      <Divider spacing={20} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            control={control}
            rules={{
              required: {
                value: true,
                message: t('error:name.required'),
              },
            }}
            error={errors.name?.message}
            name="name"
            type="text"
            label={t('common:shared.name')}
            placeholder={t('supplier:new.name.placeholder')}
            id="new-supplier-name"
            defaultValue={current.get('name')}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            control={control}
            rules={{
              pattern: {
                value: PHONE,
                message: t('error:phone.pattern'),
              },
            }}
            error={errors.main_contact_number?.message}
            name="main_contact_number"
            type="tel"
            label={t('supplier:shared.mainNumber')}
            id="new-supplier-main-phone-number"
            defaultValue={current.get('main_contact_number')}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            control={control}
            error={errors.contact_name?.message}
            name="contact_name"
            type="text"
            label={t('common:shared.contactName')}
            placeholder={t('supplier:new.contact.placeholder')}
            id="new-supplier-contact-name"
            defaultValue={current.get('contact_name')}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            control={control}
            rules={{
              pattern: {
                value: PHONE,
                message: t('error:phone.pattern'),
              },
            }}
            error={errors.contact_number?.message}
            name="contact_number"
            type="tel"
            label={t('common:shared.contactNumber')}
            id="new-supplier-contact-number"
            defaultValue={current.get('contact_number')}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            as={CategorySelect}
            control={control}
            error={errors.categories?.message}
            name="categories"
            id="new-supplier-categories"
            defaultValue={current.get('categories')}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            type="select"
            name="metadata.hasCreditCard"
            control={control}
            error={errors.metadata?.hasCreditCard?.message}
            label={t('supplier:shared.creditCard')}
            placeholder={t('common:shared.yes')}
            id="new-company-hasCreditCard"
            defaultValue={`${current.getIn(['metadata', 'hasCreditCard'])}`}
            disabled={loading}
            options={[
              { label: t('common:shared.yes'), value: 'true' },
              { label: t('common:shared.no'), value: 'false' },
            ]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            type="select"
            name="metadata.hasAccount"
            control={control}
            error={errors.metadata?.hasAccount?.message}
            label={t('company:shared.account')}
            placeholder={t('common:shared.yes')}
            id="new-company-hasAccount"
            defaultValue={`${current.getIn(['metadata', 'hasAccount'])}`}
            disabled={loading}
            options={[
              { label: t('common:shared.yes'), value: 'true' },
              { label: t('common:shared.no'), value: 'false' },
            ]}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            as={Input}
            control={control}
            error={errors.notes?.message}
            name="notes"
            type="text"
            label={t('common:shared.notes')}
            placeholder={t('supplier:new.notes.placeholder')}
            multiline
            id="new-supplier-notes"
            defaultValue={current.get('notes')}
            disabled={loading}
          />
        </Grid>
        <AddressFields
          control={control}
          streetError={errors.address_line1?.message}
          streetDetailError={errors.address_line2?.message}
          zipcodeError={errors.address_zip?.message}
          cityError={errors.address_city?.message}
          stateError={errors.address_state?.message}
          countryError={errors.address_country?.message}
          streetDefaultValue={current.get('address_line1')}
          streetDetailDefaultValue={current.get('address_line2')}
          zipcodeDefaultValue={current.get('address_zip')}
          cityDefaultValue={current.get('address_city')}
          stateDefaultValue={current.get('address_state')}
          countryDefaultValue={current.getIn(['address_country', 'code'])}
          loading={loading}
        />
        <Grid item xs={12} md={12}>
          <Input
            type="file"
            max={15}
            label={t('supplier:shared.priceList')}
            maxMessage={t('error:file.max')}
            helperText={t('common:file.helper', { max: 15 })}
            placeholder={t('common:file.placeholder')}
            dropPlaceholder={t('common:file.dropPlaceholder')}
            onChange={setFile}
            name="price_sheet"
            id="new-supplier-price-sheet"
          />
        </Grid>
      </Grid>
      <Divider spacing={20} />
      <Button
        type="submit"
        fullSize
      >
        {
          update ? t('supplier:edit.submit') : t('supplier:new.submit')
        }
      </Button>
    </form>
  )
}

Form.propTypes = {
  loading: PropTypes.bool,
  update: PropTypes.bool,
  current: PropTypes.instanceOf(Map),
}

Form.defaultProps = {
  loading: false,
  update: false,
  current: new Map(),
}

export default Form
