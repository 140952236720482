const conventions = {
    en: 
    {
        delivery: 'DELIVERY CHARGE',
        pickup: 'PICKUP CHARGE',
        displacement: 'DISPLACEMENT CHARGE',
        freight: 'FREIGHT (AMOUNT NOTED IS HOURLY RATE AND NOT THE FULL AMOUNT)'
    },
    fr: {
        delivery: 'TRANSPORT ALLER',
        pickup: 'TRANSPORT RETOUR',
        displacement: 'FRAIS DE DÉPLACEMENT',
        freight: 'TRANSPORT (LE MONTANT NOTÉ EST LE TAUX HORAIRE ET NON LE MONTANT TOTAL)'
    }
}

export const translateTransportSubstring = (str, langFrom, langTo) => {
    if (!str) return str
    var result = str
        .replace(conventions[langFrom].delivery, conventions[langTo].delivery)
        .replace(conventions[langFrom].pickup, conventions[langTo].pickup)
        .replace(conventions[langFrom].displacement, conventions[langTo].displacement)
        .replace(conventions[langFrom].freight, conventions[langTo].freight)
    return result
}