import api from 'config/axios'
import { buildQueryString } from 'utils'

export const list = async filters => (
  api.get(`/app/service_requests/messages/?${buildQueryString(filters)}`)
)

export const get = async id => (
  api.get(`/app/service_requests/messages/${id}/`)
)

export const create = async ({
  content,
  service_request,
}) => (
  api.post('/app/service_requests/messages/', {
    content,
    service_request,
  })
)

export const update = async (id, {
  content,
  service_request,
}) => (
  api.patch(`/app/service_requests/messages/${id}/`, {
    content,
    service_request,
  })
)

export const destroy = async id => (
  api.delete(`/app/service_requests/messages/${id}/`)
)

export const updateAttachment = async (id, body) => (
  api.patch(`/app/service_requests/messages/${id}/`, body)
)

export const createQuote = async (lang, {
  content,
  service_request,
  item_id_list,
}) => (
  api.post(`/app/service_requests/messages/?pdf=1&lang=${lang}`, {
    content,
    service_request,
    item_id_list
    })
)
