import React from 'react'
import PropTypes from 'prop-types'
import { Price, Detail } from 'components/common'
import Grid from '@material-ui/core/Grid'
import { SERVICE_REQUEST } from 'constants/resources'
import { useTranslation } from 'react-i18next'

function TransportInformation({
  type,
  rate,
  loading,
}) {
  const { t } = useTranslation([SERVICE_REQUEST, 'common'])
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} data-cy="transport-info-type">
        <Detail
          text={type}
          loading={loading}
          label={t('common:shared.type')}
        >
          {type}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="transport-info-rate">
        <Detail
          text={rate}
          loading={loading}
          label={t('serviceRequest:pdf.rate')}
        >
          <Price amount={rate} />
        </Detail>
      </Grid>
    </Grid>
  )
}


TransportInformation.propTypes = {
  action: PropTypes.node,
  title: PropTypes.string,
  transport: PropTypes.string,
  rate: PropTypes.number,
  loading: PropTypes.bool,
}

TransportInformation.defaultProps = {
  action: null,
  title: '',
  transport: '',
  rate: null,
  loading: false
}

export default TransportInformation
