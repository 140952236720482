import React from 'react'
import { SERVICE_REQUEST } from 'constants/resources'
import { Divider } from 'components/ui'
import { AddressSection, ContactSection, Destroy } from 'components/common'
import ServiceCallInformation from './ServiceCallInformation'
import UpdatesHistory from './UpdatesHistory'
import ClientInputSection from './ClientInputSection'

function ServiceCallDetail({
  selected,
  loading,
  messages,
}) {
  return (
    <>
      <ServiceCallInformation
        internalCode={selected.get('internal_code')}
        companyName={selected.getIn(['order_item', 'order', 'company', 'name'])}
        companyId={selected.getIn(['order_item', 'order', 'company', 'id'])}
        assigneeName={selected.getIn(['assignee', 'name'])}
        assigneeId={selected.getIn(['assignee', 'id'])}
        salesRepName={selected.getIn(['sales_rep', 'name'])}
        salesRepId={selected.getIn(['sales_rep', 'id'])}
        createdByName={selected.getIn(['created_by', 'name'])}
        createdById={selected.getIn(['created_by', 'id'])}
        equipmentName={selected.getIn(['order_item', 'equipment', 'name'])}
        equipmentId={selected.getIn(['order_item', 'equipment', 'id'])}
        orderItemCode={selected.getIn(['order_item', 'internal_code'])}
        orderItemId={selected.getIn(['order_item', 'id'])}
        notes={selected.get('notes')}
        createdAt={selected.get('created')}
        resolveBy={selected.get('resolve_by')}
        status={selected.get('status')}
        designation={selected.get('designation')}
        turnaroundTime={selected.get('turnaround_time')}
        opsNotes={selected.getIn(['metadata', 'opsNotes'])}
        issue={selected.getIn(['metadata', 'issue'])}
        duration={selected.getIn(['metadata', 'duration'])}
        troubles={selected.getIn(['metadata', 'troubles'])}
        unit={selected.getIn(['metadata', 'unit'])}
        hours={selected.getIn(['metadata', 'hours'])}
        loading={loading}
      />
      <Divider spacing={20} />
      <ClientInputSection
        contactName={selected.getIn(['metadata', 'contact_name'])}
        contactNumber={selected.getIn(['metadata', 'contact_number'])}
        voiceNoteExpiration={selected.get('voice_note_expires_at')}
        voiceNoteUrl={selected.get('voice_note')}
        comments={selected.get('comments')}
        loading={loading}
      />
      <Divider spacing={20} />
      <ContactSection
        list={selected.getIn(['metadata', 'contacts'])}
        loading={loading}
      />
      <Divider spacing={20} />
      <AddressSection
        street={selected.get('address_line1')}
        streetDetails={selected.get('address_line2')}
        city={selected.get('address_city')}
        state={selected.get('address_state')}
        zipcode={selected.get('address_zip')}
        country={selected.getIn(['address_country', 'name'])}
        loading={loading}
      />
      <Divider spacing={20} />
      <UpdatesHistory
        messages={messages}
        statusHistory={selected.get('status_history')}
        stateHistory={selected.get('state_history')}
        loading={loading}
        serviceRequestId={selected.get('id')}
      />
      <Destroy resource={SERVICE_REQUEST} id={selected.get('id')} />
    </>
  )
}


export default ServiceCallDetail
