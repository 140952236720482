import accent from './accent'

export default {
  accent: accent['400'],
  accent100: accent['100'],
  accent200: accent['200'],
  accent300: accent['300'],
  accent400: accent['400'],
  accent500: accent['500'],
  accent600: accent['600'],
  accent700: accent['700'],
  accent800: accent['800'],
  blue: '#4D66FF',
  purple: '#7e57ff',
  red: '#FF2F24',
  green: '#72DA78',
  gray: "#808080"
}
