import React from 'react'
import PropTypes from 'prop-types'
import { connectInfiniteHits, Configure } from 'react-instantsearch-dom'
import { SERVICE_REQUEST } from 'constants/resources'
import { TableContent, TableLoading } from 'components/common'
import { serviceRequests } from 'store/action-creators'
import {
  useResource, useStatusColors, useSelectResource,
  useDeviceColumns, useStoreAlgolia, useFacetFilters,
  useFilters,
} from 'components/hooks'
import { ExtraRow } from 'components/pages/extras'


function ExtraContent({
  hits, hasMore, refineNext, columns,
}) {
  const statusColors = useStatusColors(SERVICE_REQUEST)
  const selected = useResource(SERVICE_REQUEST, 'current', 'id')
  const list = useStoreAlgolia(SERVICE_REQUEST, serviceRequests, hits)
  const loading = useResource(SERVICE_REQUEST, 'list', 'loading')
  const handleRowClick = useSelectResource(SERVICE_REQUEST)
  const templateColumns = useDeviceColumns(columns, !!selected)
  const facetFilters = useFacetFilters(SERVICE_REQUEST)
  const filters = useFilters(SERVICE_REQUEST)

  return (
    <>
      <TableContent
        next={refineNext}
        dataLength={hits.length}
        hasMore={hasMore}
      >
        <Configure
          hitsPerPage={40}
          filters={filters ? `designation:extra AND ${filters}` : 'designation:extra'}
          facetFilters={facetFilters}
        />
        <TableLoading loading={loading} />
        {
          list.filter(req => !req.get('is_redacted')).map(serviceRequest => (
            <ExtraRow
              statusColor={statusColors[serviceRequest.get('status')]}
              key={serviceRequest.get('id')}
              id={serviceRequest.get('id')}
              shrink={!!selected}
              templateColumns={templateColumns}
              internalCode={serviceRequest.get('internal_code')}
              status={serviceRequest.get('status')}
              companyName={serviceRequest.getIn(['metadata', 'company'])}
              companyId={serviceRequest.getIn(['metadata', 'company_id'])}
              assigneeName={serviceRequest.getIn(['assignee', 'name'])}
              assigneeId={serviceRequest.getIn(['assignee', 'id'])}
              salesRepName={serviceRequest.getIn(['sales_rep', 'name'])}
              salesRepId={serviceRequest.getIn(['sales_rep', 'id'])}
              createdAt={serviceRequest.get('created')}
              address={serviceRequest.get('address_line1')}
              contractReference={serviceRequest.getIn(['metadata', 'contract_reference'])}
              contractReferenceId={serviceRequest.getIn(['metadata', 'contract_reference_id'])}
              occurrenceDate={serviceRequest.getIn(['metadata', 'occurrence_date'])}
              active={serviceRequest.get('id') === selected}
              onClick={() => handleRowClick(serviceRequest.get('id'))}
            />
          ))
        }
      </TableContent>
    </>
  )
}

ExtraContent.propTypes = {
  columns: PropTypes.object.isRequired,
}

export default connectInfiniteHits(ExtraContent)
