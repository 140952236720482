import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { supplierRates } from 'store/action-creators'
import { Button, PriceField, Flex, OutlinedCard, SectionHeader, Checkbox, Input } from 'components/common'
import Grid from '@material-ui/core/Grid'
import { SupplierSelect } from 'components/pages/suppliers'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ORDER_ITEM } from 'constants/resources'
import { Divider } from 'components/ui'
import { TransportRow } from '../service-request-items'
import { AddItemButton } from '../service-requests/ItemRow'
import { v4 as uuidv4 } from 'uuid'
import { useCurrent, useNotification } from 'components/hooks'
import { ORDER } from 'constants/resources'
import { orders } from 'store/action-creators'
import { fromJS } from 'immutable'
import { CANCELLED, FINALIZED } from 'constants/status'

function SupplierForm({ update, loading, current }) {
  const { t } = useTranslation(['common', ORDER_ITEM, 'error', 'transportConventions'])
  const dispatch = useDispatch()
  const order = useCurrent(ORDER)
  const isDisabled = [CANCELLED, FINALIZED].includes(current.getIn(['order_item', 'status'])) ?? false
  const [transportData, setTransportData] = useState([])
  const [useTransportSupplier, setTransportSupplier] = useState(
    (!update && !!current?.get('selected_supplier')) || (update && !!current?.getIn(['metadata', 'isTransportSupplier']))
  )
  const { handleSubmit, control, errors, register } = useForm({ mode: 'onBlur' })
  const notify = useNotification()
  useEffect(() => {
    if (update) {
      if (current) {
        const transport = current?.get('transport')
        setTransportData(
          [...Array(transport.size)].map((x, i) => {
            return {
              transport: transport.getIn([i, 'type']),
              rate: transport.getIn([i, 'value']),
              id: uuidv4()
            }
          })
        )
      }
    } else {
      if (current.get('selected_transport_supplier')) {
        const transport = current.getIn(['selected_transport_supplier', 'transport'])
        setTransportData(
          [...Array(transport.size)].map((x, i) => {
            return {
              transport: transport.getIn([i, 'type']),
              rate: transport.getIn([i, 'value']),
              id: uuidv4()
            }
          })
        )
      } else {
        setTransportData([
          { transport: t('transportConventions:deliveryCharge'), rate: null, id: uuidv4() },
          { transport: t('transportConventions:pickupCharge'), rate: null, id: uuidv4() }
        ])
      }
    }
  }, [t, current, update])

  const handleUpdateSuccess = (response) => {
    const itemIndex = order.get('items').findIndex((item) => item.get('id') === response.id)
    if (response.metadata.isTransportSupplier) {
      dispatch(orders.setCurrentData(order.setIn(['items', itemIndex, 'selected_transport_supplier'], fromJS(response))))
    } else {
      dispatch(orders.setCurrentData(order.setIn(['items', itemIndex, 'selected_supplier'], fromJS(response))))
    }
  }

  const handleCreateSuccess = (response) => {
    const itemIndex = order.get('items').findIndex((item) => item.get('id') === response.order_item.id)
    dispatch(orders.setCurrentData(order.setIn(['items', itemIndex, 'selected_supplier'], fromJS(response))))
  }

  const handleCreateTransportSuccess = (response) => {
    const itemIndex = order.get('items').findIndex((item) => item.get('id') === response.order_item.id)
    dispatch(orders.setCurrentData(order.setIn(['items', itemIndex, 'selected_transport_supplier'], fromJS(response))))
  }

  const handleCreateWithTransportSuccess = (response) => {
    const selected_supplier = response[0]
    const selected_transport_supplier = response[1]
    const itemIndex = order.get('items').findIndex((item) => item.get('id') === selected_supplier.order_item.id)
    dispatch(orders.setCurrentData(order.setIn(['items', itemIndex, 'selected_supplier'], fromJS(selected_supplier))))
    dispatch(orders.setCurrentData(order.setIn(['items', itemIndex, 'selected_transport_supplier'], fromJS(selected_transport_supplier))))
  }

  const itemHasValidRate = (data) => {
    return !!(data.daily_rate || data.weekly_rate || data.monthly_rate)
  }

  const onSubmit = (data) => {
    if ((data.supplier && !itemHasValidRate(data)) || (itemHasValidRate(data) && !data.supplier)) {
      notify({ type: 'error', text: t('error:ratesRequired') })
      return
    }
    let body = {
      order_item: data.order_item,
      supplier: data.supplier,
      daily_rate: data.daily_rate,
      weekly_rate: data.weekly_rate,
      monthly_rate: data.monthly_rate,
      transport: transportData
        .filter((t) => t.rate !== null)
        .map((t) => {
          return { type: t.transport, value: t.rate }
        }),
      notes: data.notes,
      metadata: {}
    }
    if (update) {
      body = { ...body, id: current.get('id') }
      dispatch(supplierRates.update(body, handleUpdateSuccess))
    } else {
      if (current.get('selected_supplier') || (useTransportSupplier && !data.supplier)) {
        const transportBody = {
          order_item: body.order_item,
          supplier: data.transport_supplier,
          transport: body.transport,
          notes: body.notes,
          metadata: {
            isTransportSupplier: true
          },
          is_selected_transport: true
        }
        dispatch(supplierRates.create(transportBody, handleCreateTransportSuccess))
      } else if (useTransportSupplier) {
        const itemBody = {
          order_item: data.order_item,
          supplier: data.supplier,
          daily_rate: data.daily_rate,
          weekly_rate: data.weekly_rate,
          monthly_rate: data.monthly_rate,
          notes: data.notes,
          is_selected: true
        }
        const transportBody = {
          order_item: data.order_item,
          supplier: data.transport_supplier,
          transport: transportData
            .filter((t) => t.rate !== null)
            .map((t) => {
              return { type: t.transport, value: t.rate }
            }),
          notes: data.notes,
          metadata: {
            isTransportSupplier: true
          },
          is_selected_transport: true
        }
        dispatch(supplierRates.createWithTransport({ item: itemBody, transport: transportBody }, handleCreateWithTransportSuccess))
      } else {
        body = { ...body, is_selected: true }
        dispatch(supplierRates.create(body, handleCreateSuccess))
      }
    }
  }

  const onChange = useCallback((index, value, name) => {
    setTransportData((prevState) => {
      if (value) {
        return [
          ...prevState.slice(0, index),
          {
            ...prevState[index],
            [name]: value
          },
          ...prevState.slice(index + 1)
        ]
      } else {
        return [
          ...prevState.slice(0, index),
          {
            ...prevState[index],
            [name]: value === 0 ? null : ''
          },
          ...prevState.slice(index + 1)
        ]
      }
    })
  }, [])

  const onDelete = useCallback((index) => {
    setTransportData((prevState) => {
      let updatedArr = [...prevState]
      updatedArr.splice(index, 1)
      return prevState.length <= 0 ? [...prevState] : [...updatedArr]
    })
  }, [])

  const addTransportRow = () => {
    let updatedArr = [...transportData]
    updatedArr.push({
      transport: '',
      rate: null,
      id: uuidv4()
    })
    setTransportData(updatedArr)
  }

  const showCheckbox = () => {
    const oneSupplierExists = current.get('selected_supplier') || current.get('selected_transport_supplier')
    const show = !update && !oneSupplierExists
    return show
  }

  const showSupplierSection = () => {
    return !update || !current.getIn(['metadata', 'isTransportSupplier'])
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex align="center" justify="space-between">
        <h1>{t('common:shared.information')}</h1>
      </Flex>
      <Divider spacing={20} />
      <input
        type="hidden"
        name="order_item"
        ref={register}
        defaultValue={current.get('object') === 'supplier_rate' ? current.getIn(['order_item', 'id']) : current.get('id')}
      />
      <Grid container spacing={2}>
        {showSupplierSection() && (
          <>
            <Grid item xs={12} md={12}>
              <h3>{t('common:resources.suppliers.singular')}</h3>
            </Grid>
            <Grid item xs={14} md={7}>
              <Controller
                as={SupplierSelect}
                name="supplier"
                id="new-sr-item-supplier"
                control={control}
                error={errors.supplier?.message}
                defaultValue={update ? current.getIn(['supplier', 'id']) : current?.getIn(['selected_supplier', 'supplier', 'id'])}
                disabled={loading || (!update && current.get('selected_supplier'))}
              />
            </Grid>
            <Grid item xs={12} md={5} />

            <Grid item xs={4} md={4}>
              <Controller
                as={PriceField}
                name="daily_rate"
                control={control}
                error={errors.daily_rate?.message}
                label={t('common:shared.dailyRate')}
                placeholder="69.00"
                id="new-sr-item-supplier-daily-rate"
                defaultValue={update ? current.get('daily_rate') : current?.getIn(['selected_supplier', 'daily_rate'])}
                disabled={loading || (!update && current.get('selected_supplier'))}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <Controller
                as={PriceField}
                name="weekly_rate"
                control={control}
                error={errors.weekly_rate?.message}
                label={t('common:shared.weeklyRate')}
                placeholder="499.00"
                id="new-sr-item-supplier-weekly-rate"
                defaultValue={update ? current.get('weekly_rate') : current?.getIn(['selected_supplier', 'weekly_rate'])}
                disabled={loading || (!update && current.get('selected_supplier'))}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <Controller
                as={PriceField}
                name="monthly_rate"
                control={control}
                error={errors.monthly_rate?.message}
                label={t('common:shared.monthlyRate')}
                placeholder="3999.00"
                id="new-sr-item-supplier-monthly-rate"
                defaultValue={update ? current.get('monthly_rate') : current?.getIn(['selected_supplier', 'monthly_rate'])}
                disabled={loading || (!update && current.get('selected_supplier'))}
              />
            </Grid>
          </>
        )}
        <SectionHeader title={t('common:resources.transport.singular')} />
        {showCheckbox() && (
          <Grid item xs={14} md={7}>
            <Checkbox
              checked={useTransportSupplier}
              onChange={() => setTransportSupplier(!useTransportSupplier)}
              inputProps={{ 'aria-label': 'controlled' }}
              label={t('common:shared.useTransportSupplier')}
              data-cy="transport-supplier-checkbox"
              disabled={loading}
            />
          </Grid>
        )}
        {useTransportSupplier && (
          <Grid item xs={14} md={7}>
            <Controller
              as={SupplierSelect}
              name="transport_supplier"
              id="new-sr-item-transport-supplier"
              control={control}
              error={errors.transport_supplier?.message}
              disabled={loading || (!update && current.get('selected_transport_supplier'))}
              defaultValue={current?.getIn(['supplier', 'id'])}
              rules={{
                required: {
                  value: true,
                  message: t('error:supplierRequired')
                }
              }}
            />
          </Grid>
        )}
        <Divider spacing={10} />
        <>
          {transportData?.map((transportItem, index) => (
            <OutlinedCard>
              <TransportRow
                key={transportItem.id}
                transport={transportItem.transport ?? ''}
                rate={transportItem.rate ?? null}
                idx={index}
                onChange={onChange}
                onDelete={onDelete}
                disabled={loading || (!update && current.get('selected_transport_supplier')) || isDisabled}
              />
            </OutlinedCard>
          ))}
          <AddItemButton disabled={loading} id="add-transport-row-btn" onClick={() => addTransportRow()} type="button">
            + {t('serviceRequest:items.add')}
          </AddItemButton>
        </>
        <Grid item xs={12} md={5} />
        <Divider spacing={25} />

        <Grid item xs={12} md={12}>
          <Controller
            as={Input}
            name="notes"
            control={control}
            error={errors.metadata?.notes?.message}
            type="text"
            label={t('common:shared.notes')}
            placeholder={t('order:new.notes.placeholder')}
            multiline
            id="new-company-notes"
            defaultValue={current?.get('notes')}
            disabled={loading}
          />
        </Grid>
      </Grid>
      <Divider spacing={20} />
      <Button disabled={loading} type="submit" fullSize>
        {update ? t('common:shared.submit.edit') : t('common:shared.submit.new')}
      </Button>
    </form>
  )
}

export default SupplierForm
