import React from 'react'
import { Input } from 'components/common'
import { useTranslation } from 'react-i18next'

function CountrySelect({ defaultValue, ...otherProps }) {
  const { t } = useTranslation('common')

  return (
    <Input
      type="select"
      name="country"
      label={t('address.country.label')}
      placeholder="Canada"
      options={[
        {
          label: 'Canada',
          value: 'CA',
        },
        {
          label: 'United States',
          value: 'US',
        },
      ]}
      defaultValue={defaultValue}
      {...otherProps}
    />
  )
}

export default CountrySelect
