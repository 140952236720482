import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import actions from 'store/action-creators'
import TextField from 'components/ui/TextField'
import { DatePicker } from '@material-ui/pickers'
import { DATE } from 'constants/formats'
import InputAdornment from '@material-ui/core/InputAdornment'
import { Icon } from 'components/ui'
import { IconButton, withStyles } from '@material-ui/core'
import { createStyles } from '@material-ui/styles'
import moment from 'moment'

function DateFilter({
  resource, attribute, id, label, onChange, icon, classes,
}) {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const dispatch = useDispatch()


  const renderWrappedWeekDay = (day, selectedDate, dayInCurrentMonth) => {
    const wrapperClassName = []
    const dayClassName = [classes.day]
    if (startDate && endDate) {
      const dayIsBetween = day.isBetween(startDate, endDate)
      const isFirstDay = day.isSame(startDate, 'day')
      const isLastDay = day.isSame(endDate, 'day')
      if (dayIsBetween || isFirstDay || isLastDay) wrapperClassName.push(classes.highlight)
      if (isFirstDay) wrapperClassName.push(classes.firstHighlight)
      if (isLastDay) wrapperClassName.push(classes.endHighlight)
      if (!dayInCurrentMonth) dayClassName.push(classes.nonCurrentMonthDay)
      if (!dayInCurrentMonth && dayIsBetween) dayClassName.push(classes.highlightNonCurrentMonthDay)
    } else if (startDate) {
      const isFirstDay = day.isSame(startDate, 'day')
      if (isFirstDay) wrapperClassName.push(classes.dayHighlight, classes.highlight)
    }
    return (
      <div className={wrapperClassName.join(' ')}>
        <IconButton className={dayClassName.join(' ')}>
          <span>
            {day.format('D')}
          </span>
        </IconButton>
      </div>
    )
  }

  const formatWeekSelectLabel = (date, invalidLabel) => {
    if (startDate && endDate) return `${startDate.format('MMM D')} → ${endDate.format('MMM D')}`
    if (startDate) return startDate.format('MMM D')
    return ''
  }

  const handleOnChange = (date) => {
    let start
    let end
    if (startDate && endDate) {
      setStartDate(date)
      start = date
      setEndDate(null)
    } else if (startDate) {
      if (startDate.isAfter(date)) {
        setEndDate(startDate)
        end = startDate
        setStartDate(date)
        start = date
      } else {
        setEndDate(date.endOf('day'))
        end = date.endOf('day')
      }
    } else {
      setStartDate(date)
      start = date
    }
    if (start) {
      const startUnix = moment.utc(start.format('YYYY-MM-DD')).unix()
      dispatch(actions[resource].addListSearchFilters({
        attribute,
        value: startUnix,
        label: start.format('DD-MM-YYYY'),
      }))
    }
    if (end) {
      const endUnix = moment.utc(end.format('YYYY-MM-DD HH:mm:ss')).unix()
      dispatch(actions[resource].addListSearchFilters({
        attribute,
        value: endUnix,
        label: end.format('DD-MM-YYYY'),
      }))
    }
    if (onChange) onChange(date)
    return date
  }

  const handleClearClick = (event) => {
    event.stopPropagation()
    dispatch(actions[resource].emptyListSearchFilters({
      attribute,
    }))
    setStartDate(null)
    setEndDate(null)
  }

  return (
    <DatePicker
      variant="inline"
      disableToolbar
      renderDay={renderWrappedWeekDay}
      autoOk={false}
      id={id}
      shrink={startDate || endDate}
      clearable
      clearLabel="CLEAR"
      inputVariant="filled"
      format={DATE}
      value={startDate}
      labelFunc={formatWeekSelectLabel}
      label={(
        <p style={{ display: 'flex', alignItems: 'center' }}>
          <Icon name={icon} size={18} />
            &nbsp;
            &nbsp;
          <span>{label}</span>
        </p>
        )}
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
      type="text"
      TextFieldComponent={TextField}
      onChange={handleOnChange}
      InputProps={{
        endAdornment: startDate ? (
          <InputAdornment position="end">
            <IconButton
              onClick={handleClearClick}
              edge="end"
            >
              <Icon name="clear" size={16} />
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
    />
  )
}


const styles = createStyles(theme => ({
  dayWrapper: {
    position: 'relative',
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: '0 2px',
    color: 'inherit',
  },
  customDayHighlight: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: '2px',
    right: '2px',
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '50%',
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled,
  },
  highlightNonCurrentMonthDay: {
    color: '#676767',
  },
  highlight: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  firstHighlight: {
    extend: 'highlight',
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  },
  endHighlight: {
    extend: 'highlight',
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  },
  dayHighlight: {
    extend: 'highlight',
    borderRadius: '50%',
  },
}))

DateFilter.propTypes = {
  attribute: PropTypes.string.isRequired,
  icon: PropTypes.string,
  resource: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
}

DateFilter.defaultProps = {
  onChange: null,
  icon: 'calendar_today',
}

export default withStyles(styles)(DateFilter)
