import React from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  Detail, PreviewLink, Date, SectionHeader, Attachment
} from 'components/common'
import {
  COMPANY, USER, SERVICE_REQUEST,
} from 'constants/resources'
import { useSelector } from 'react-redux'
import { OPERATIONS, SUPERUSER } from 'constants/roles'
import moment from 'moment'

function Information({
  assigneeName,
  assigneeId,
  salesRepName,
  salesRepId,
  createdByName,
  createdById,
  internalCode,
  notes,
  loading,
  createdAt,
  companyName,
  companyId,
  title,
  action,
  duration,
  deliveryDate,
  lastQuote,
  quoteType,
  currentSupplier,
  usedFor,
  opsNotes,
  turnaroundTime,
  opsAttachment,
}) {
  const { t } = useTranslation(['common', SERVICE_REQUEST])
  const role = useSelector(state => state.getIn(['session', 'user', 'role']))
  const isOperations = [OPERATIONS, SUPERUSER].includes(role)

  return (
    <Grid container spacing={2}>
      {
        title ? <SectionHeader title={title}>{action}</SectionHeader> : null
      }
      <Grid item xs={12} md={6} data-cy="sr-info-created-by">
        <Detail
          text={createdByName}
          loading={loading}
          label={t('common:shared.createdBy')}
        >
          <PreviewLink id={createdById} resource={USER}>
            {createdByName}
          </PreviewLink>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="sr-info-created-at">
        <Detail
          text={createdAt}
          loading={loading}
          label={t('common:shared.createdAt')}
        >
          <Date variant="datetime">
            {createdAt}
          </Date>
        </Detail>
      </Grid>

      <Grid item xs={12} md={6} data-cy="sr-info-id">
        <Detail
          text={internalCode}
          loading={loading}
          label={t('common:shared.id')}
        >
          {internalCode}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="sr-info-handled-by">
        <Detail
          text={assigneeName}
          loading={loading}
          label={t('common:shared.handledBy')}
        >
          <PreviewLink id={assigneeId} resource={USER}>
            {assigneeName}
          </PreviewLink>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="sr-info-sales-rep">
        <Detail
          text={salesRepName}
          loading={loading}
          label={t('shared.salesRep')}
          empty={t('company:shared.emptySalesRep')}
        >
          <PreviewLink
            resource={USER}
            id={salesRepId}
          >
            <span>
              {salesRepName}
            </span>
          </PreviewLink>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="sr-info-company">
        <Detail
          text={companyName}
          loading={loading}
          label={t('common:resources.companies.singular')}
        >
          <PreviewLink id={companyId} resource={COMPANY}>
            {companyName}
          </PreviewLink>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="sr-info-duration">
        <Detail
          text={duration}
          loading={loading}
          label={t('common:shared.duration')}
        >
          {duration}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="sr-info-delivery-date">
        <Detail
          text={deliveryDate}
          loading={loading}
          label={t('serviceRequest:shared.deliveryDate')}
        >
          <Date variant="datetime">
            {deliveryDate}
          </Date>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="sr-info-last-quote">
        <Detail
          text={lastQuote}
          loading={loading}
          label={t('serviceRequest:shared.lastQuote')}
        >
          {lastQuote}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="sr-info-price-type">
        <Detail
          text={quoteType}
          loading={loading}
          label={t('serviceRequest:shared.priceType')}
        >
          {quoteType}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="sr-info-supplier">
        <Detail
          text={currentSupplier}
          loading={loading}
          label={t('serviceRequest:shared.currentSupplier')}
        >
          {currentSupplier}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="sr-info-used-for">
        <Detail
          text={usedFor}
          loading={loading}
          label={t('serviceRequest:new.usedFor.label')}
        >
          {usedFor}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="sr-info-turnaround-time">
        <Detail
          text={turnaroundTime && moment.duration(turnaroundTime).asHours().toFixed(2)}
          loading={loading}
          label={t('serviceRequest:shared.turnaroundTime')}
        >
          {turnaroundTime && `${moment.duration(turnaroundTime).asHours().toFixed(2)} ${t('serviceRequest:shared.hours')}`}
        </Detail>
      </Grid>
      <Grid item xs={12} md={12} data-cy="sr-info-description">
        <Detail
          text={notes}
          loading={loading}
          label={t('common:shared.notes')}
        >
          {notes}
        </Detail>
      </Grid>
      {
        isOperations ? (
          <Grid item xs={12} md={12} data-cy="sr-info-ops-notes">
            <Detail
              text={opsNotes}
              loading={loading}
              label={t('serviceRequest:new.opsNotes.label')}
            >
              {opsNotes}
            </Detail>
            {
              opsAttachment ? (
                <Attachment 
                  attachment={opsAttachment}
                />
              ) : null
            }
          </Grid>
        ) : null
      }
    </Grid>
  )
}

Information.propTypes = {
  action: PropTypes.node,
  title: PropTypes.string,
  createdAt: PropTypes.string,
  companyName: PropTypes.string,
  companyId: PropTypes.string,
  assigneeName: PropTypes.string,
  assigneeId: PropTypes.string,
  salesRepName: PropTypes.string,
  salesRepId: PropTypes.string,
  createdByName: PropTypes.string,
  createdById: PropTypes.string,
  notes: PropTypes.string,
  loading: PropTypes.bool,
  internalCode: PropTypes.string,
  duration: PropTypes.string,
  deliveryDate: PropTypes.string,
  lastQuote: PropTypes.string,
  quoteType: PropTypes.string,
  currentSupplier: PropTypes.string,
  turnaroundTime: PropTypes.number,
  opsAttachment: PropTypes.string,
}

Information.defaultProps = {
  action: null,
  title: '',
  createdAt: '',
  companyName: '',
  companyId: '',
  assigneeName: '',
  assigneeId: '',
  salesRepName: '',
  salesRepId: '',
  createdByName: '',
  createdById: '',
  notes: '',
  loading: false,
  internalCode: '',
  duration: '',
  deliveryDate: '',
  lastQuote: '',
  quoteType: '',
  currentSupplier: '',
  turnaroundTime: 0,
  opsAttachment: '',
}

export default Information
