import React from 'react'
import {
  Chip, TableRow, PreviewLink, TableCell, Date, FacetFilter,
} from 'components/common'
import PropTypes from 'prop-types'
import {
  USER, COMPANY, SERVICE_REQUEST, ORDER
} from 'constants/resources'

function ExtraRow({
  status,
  internalCode,
  address,
  occurrenceDate,
  contractReference,
  contractReferenceId,
  companyName,
  companyId,
  createdAt,
  assigneeName,
  assigneeId,
  active,
  shrink,
  onClick,
  templateColumns,
  statusColor,
  salesRepId,
  salesRepName,
}) {
  const createdAtFormatted = typeof createdAt === 'string' ? createdAt : createdAt * 1000

  return (
    <TableRow templateColumns={templateColumns} active={active} onClick={onClick}>
      <TableCell sm>
        <p>{internalCode}</p>
      </TableCell>
      <TableCell sm>
        <FacetFilter
          filters={{
            attribute: 'status',
            value: status,
            label: status,
          }}
          resource={SERVICE_REQUEST}
        >
          <Chip
            background={statusColor}
            secondary={active}
            label={status}
          />
        </FacetFilter>
      </TableCell>
      <TableCell lg={!shrink}>
        <Date variant="datetime">{createdAtFormatted}</Date>
      </TableCell>
      <TableCell md>
        <PreviewLink hideMobile resource={ORDER} id={contractReferenceId}>
          {contractReference}
        </PreviewLink>
      </TableCell>
      <TableCell md>
        <FacetFilter
          filters={{
            attribute: 'metadata.company_id',
            value: companyId,
            label: companyName,
          }}
          resource={SERVICE_REQUEST}
        >
          <PreviewLink hideMobile resource={COMPANY} id={companyId}>
            {companyName}
          </PreviewLink>
        </FacetFilter>
      </TableCell>
      <TableCell lg={!shrink}>
        <p>{address}</p>
      </TableCell>
      <TableCell lg={!shrink}>
        <Date variant="datetime">{occurrenceDate}</Date>
      </TableCell>
      <TableCell lg={!shrink}>
        <FacetFilter
          filters={{
            attribute: 'serialized_assignee.id',
            value: assigneeId,
            label: assigneeName,
          }}
          resource={SERVICE_REQUEST}
        >
          <PreviewLink hideMobile resource={USER} id={assigneeId}>
            {assigneeName}
          </PreviewLink>
        </FacetFilter>
      </TableCell>
      <TableCell lg={!shrink}>
        <FacetFilter
          filters={{
            attribute: 'serialized_sales_rep.id',
            value: salesRepId,
            label: salesRepName,
          }}
          resource={SERVICE_REQUEST}
        >
          <PreviewLink hideMobile resource={USER} id={salesRepId}>
            {salesRepName}
          </PreviewLink>
        </FacetFilter>
      </TableCell>
    </TableRow>
  )
}

ExtraRow.propTypes = {
  status: PropTypes.string,
  internalCode: PropTypes.string,
  address: PropTypes.string,
  occurrenceDate: PropTypes.string,
  contractReference: PropTypes.string,
  contractReferenceId: PropTypes.string,
  companyName: PropTypes.string,
  companyId: PropTypes.string,
  createdAt: PropTypes.string,
  assigneeName: PropTypes.string,
  assigneeId: PropTypes.string,
  active: PropTypes.bool,
  shrink: PropTypes.bool,
  onClick: PropTypes.func,
  templateColumns: PropTypes.string,
  statusColor: PropTypes.string,
  salesRepId: PropTypes.string,
  salesRepName: PropTypes.string,
}

ExtraRow.defaultProps = {
  status: '',
  internalCode: '',
  address: '',
  occurrenceDate: '',
  contractReference: '',
  contractReferenceId: '',
  companyName: '',
  companyId: '',
  createdAt: '',
  assigneeName: '',
  assigneeId: '',
  active: false,
  shrink: false,
  onClick: null,
  templateColumns: '',
  statusColor: '',
  salesRepId: '',
  salesRepName: '',
}

export default ExtraRow
