import React from 'react'
import { EQUIPMENT } from 'constants/resources'
import { EQUIPMENT_INDEX } from 'constants/algolia'
import { withAlgolia } from 'components/hocs'
import { connectHits } from 'react-instantsearch-dom'
import { AutoCompleteSelect } from 'components/common'

function EquipmentSelect({ hits, value, ...otherProps }) {
  return (
    <AutoCompleteSelect
      hits={hits}
      value={value}
      attributesToRetrieve={['objectID', 'name']}
      objectName={EQUIPMENT}
      keyLabel="name"
      configureFilter="is_active:true"
      configureHitsPerPage={50}
      {...otherProps}
    />
  )
}

export default withAlgolia(EQUIPMENT_INDEX)(connectHits(EquipmentSelect))
