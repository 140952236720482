import React from 'react'
import PropTypes from 'prop-types'
import { Price, Detail, PreviewLink, Chip } from 'components/common'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import { SUPPLIER, ORDER_ITEM, SUPPLIER_RATE } from 'constants/resources'
import { EDIT } from 'constants/crud'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import { Divider } from 'components/ui'
import { useDispatch } from 'react-redux'
import { useQuickPreview } from 'components/hooks'
import { supplierRates } from 'store/action-creators'
function SuppliersInformation({ supplierRate, supplierName, supplierId, dailyRate, weeklyRate, monthlyRate, transport, notes, loading, checked }) {
  const { t } = useTranslation(['common', ORDER_ITEM, 'serviceRequest'])
  const dispatch = useDispatch()
  const [openPreview] = useQuickPreview()

  const onEdit = (event) => {
    event.stopPropagation()
    dispatch(supplierRates.setCurrentData(supplierRate))
    dispatch(supplierRates.setCurrentId(supplierRate.get('id')))
    openPreview(SUPPLIER_RATE, EDIT)
  }

  return (
    <Grid container spacing={2}>
      <Accordion expanded={true} style={{ width: '90%', marginLeft: 25, border: checked ? '2px solid black' : null }}>
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <div>
              <PreviewLink resource={SUPPLIER} id={supplierId}>
                <p style={{ paddingBottom: 5 }}>{supplierName}</p>
              </PreviewLink>
              <Chip onClick={onEdit} outline icon="edit" label={t('shared.edit')} />
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ display: 'flex', flexDirection: 'column', margin: 'auto', width: '95%' }}>
            {(dailyRate !== undefined || weeklyRate !== undefined || monthlyRate !== undefined) && (
              <>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <Grid item xs={12} md={6} data-cy="supplier-daily-rate">
                    <Detail text={dailyRate} loading={loading} label={t('common:shared.dailyRate')}>
                      <Price amount={dailyRate} />
                    </Detail>
                  </Grid>
                  <Grid item xs={12} md={6} data-cy="supplier-weekly-rate">
                    <Detail text={weeklyRate} loading={loading} label={t('common:shared.weeklyRate')}>
                      <Price amount={weeklyRate} />
                    </Detail>
                  </Grid>
                  <Grid item xs={12} md={6} data-cy="supplier-monthly-rate">
                    <Detail text={monthlyRate} loading={loading} label={t('common:shared.monthlyRate')}>
                      <Price amount={monthlyRate} />
                    </Detail>
                  </Grid>
                </div>
                <Divider spacing={20} />
              </>
            )}
            {[...Array(transport ? transport.size : 0)].map((x, i) => {
              return (
                <div
                  key={transport.getIn([i, 'type']) + transport.getIn([i, 'value'])}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    paddingTop: i !== 0 && '10px'
                  }}
                >
                  <Grid item xs={12} md={6} data-cy="transport-info-transport">
                    <Detail text={transport.getIn([i, 'type'])} loading={loading} label={t('common:resources.transport.singular')}>
                      {transport.getIn([i, 'type'])}
                    </Detail>
                  </Grid>
                  <Grid item xs={12} md={6} data-cy="transport-info-rate">
                    <Detail text={transport.getIn([i, 'value'])} loading={loading} label={t('serviceRequest:pdf.rate')}>
                      <Price amount={transport.getIn([i, 'value'])} />
                    </Detail>
                  </Grid>
                </div>
              )
            })}
            <Divider spacing={20} />
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
              <Grid item xs={12} md={6} data-cy="itemized-info-notes">
                <Detail text={notes} loading={loading} label={t('common:shared.notes')}>
                  {notes}
                </Detail>
              </Grid>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </Grid>
  )
}

SuppliersInformation.propTypes = {
  id: PropTypes.string,
  supplierName: PropTypes.string,
  supplierId: PropTypes.string,
  dailyRate: PropTypes.number,
  weeklyRate: PropTypes.number,
  monthlyRate: PropTypes.number,
  loading: PropTypes.bool,
  itemIndex: PropTypes.number,
  transport: PropTypes.any,
  notes: PropTypes.string,
  checked: PropTypes.bool,
  onSelect: PropTypes.any
}

SuppliersInformation.defaultProps = {
  id: '',
  supplierName: '',
  supplierId: '',
  dailyRate: null,
  weeklyRate: null,
  monthlyRate: null,
  loading: false,
  itemIndex: 0,
  transport: [],
  notes: '',
  checked: true
}

export default SuppliersInformation
