import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Divider } from 'components/ui'
import { Input, Button, PercentageField } from 'components/common'
import { companies, users } from 'store/action-creators'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { COMPANY, USER } from 'constants/resources'
import { useQuickPreview, useNotification } from 'components/hooks'
import { NEW } from 'constants/crud'
import SelectAdmin from 'components/pages/users/SelectAdmin'
import Grid from '@material-ui/core/Grid'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from 'components/ui/TextField'

function Form({ current, loading, update }) {
  const { t } = useTranslation(['common', COMPANY, 'error'])
  const dispatch = useDispatch()
  const notify = useNotification()
  const { handleSubmit, control, errors } = useForm({ mode: 'onBlur' })
  const [openPreview] = useQuickPreview()
  const [metadataEmails, setMetadataEmails] = useState(current.getIn(['metadata', 'emails'])?.toJS() || {})

  const handleCreateSuccess = (response) => {
    dispatch(
      users.setCreateData({
        company: { id: response.id }
      })
    )
    openPreview(USER, NEW)
  }

  const onSubmit = (body) => {
    if (metadataEmails.optin && !metadataEmails.list?.length) {
      notify({ type: 'error', text: t('company:emails.error.optinNoEmails') })
      return
    }
    const payload = { ...body, metadata: { ...body.metadata, emails: metadataEmails } }
    payload.metadata.hasAccount = payload.metadata.hasAccount === 'true'
    if (update) {
      dispatch(companies.update(payload))
    } else {
      dispatch(companies.create(payload, handleCreateSuccess))
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h1>{t('common:shared.information')}</h1>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            control={control}
            rules={{
              required: {
                value: true,
                message: t('error:shared.required')
              }
            }}
            error={errors.name?.message}
            type="text"
            name="name"
            label={t('common:shared.name')}
            placeholder={t('company:new.name.placeholder')}
            id="new-company-name"
            defaultValue={current.get('name')}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={SelectAdmin}
            control={control}
            error={errors.sales_rep?.message}
            name="sales_rep"
            label={t('common:shared.salesRep')}
            placeholder="John Doe"
            id="new-company-sales-rep"
            defaultValue={current.getIn(['sales_rep', 'id'])}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={PercentageField}
            name="metadata.rental_protection"
            control={control}
            error={errors.metadata?.rental_protection?.message}
            label={t('orderItem:shared.rentalProtection')}
            placeholder="15"
            id="new-company-rental-protection"
            defaultValue={current.getIn(['metadata', 'rental_protection'])}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            type="select"
            name="has_signed_rpp"
            control={control}
            error={errors.has_signed_rpp?.message}
            label={t('company:shared.signedRPP')}
            placeholder={t('common:shared.yes')}
            id="new-company-has-signed-rpp"
            defaultValue={current.get('has_signed_rpp')?.toString()}
            disabled={loading}
            options={[
              { label: t('common:shared.yes'), value: 'true' },
              { label: t('common:shared.no'), value: 'false' }
            ]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            type="select"
            name="metadata.hasAccount"
            control={control}
            error={errors.metadata?.hasAccount?.message}
            label={t('company:shared.account')}
            placeholder={t('common:shared.yes')}
            id="new-company-hasAccount"
            defaultValue={`${current.getIn(['metadata', 'hasAccount'])}`}
            disabled={loading}
            options={[
              { label: t('common:shared.yes'), value: 'true' },
              { label: t('common:shared.no'), value: 'false' }
            ]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            name="metadata.current_supplier"
            control={control}
            error={errors.metadata?.current_supplier?.message}
            type="text"
            label={t('serviceRequest:shared.currentSupplier')}
            placeholder={t('serviceRequest:new.currentSupplier.placeholder')}
            multiline
            id="new-company-currentSupplier"
            defaultValue={current.getIn(['metadata', 'current_supplier'])}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            name="metadata.notes"
            control={control}
            error={errors.metadata?.notes?.message}
            type="text"
            label={t('common:shared.notes')}
            placeholder={t('order:new.notes.placeholder')}
            multiline
            id="new-company-notes"
            defaultValue={current.getIn(['metadata', 'notes'])}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            type="select"
            name="emails-optin"
            label={t('company:emails.subscription')}
            placeholder={`${t('company:emails.optin')} / ${t('company:emails.optout')}`}
            options={[
              {
                label: t('company:emails.optin'),
                value: true
              },
              {
                label: t('company:emails.optout'),
                value: false
              }
            ]}
            value={metadataEmails.optin}
            onChange={(newValue) => {
              setMetadataEmails({ ...metadataEmails, optin: newValue })
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            multiple
            freeSolo
            disabled={!metadataEmails.optin || loading}
            value={metadataEmails.list || []}
            onChange={(event, newValue) => {
              setMetadataEmails({ ...metadataEmails, list: newValue })
            }}
            id="emails-list"
            options={[]}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('company:emails.list')}
                placeholder="abc@email.com"
                InputLabelProps={{
                  shrink: true
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            type="select"
            name="emails-lang"
            label={t('company:emails.lang')}
            placeholder={t('common:languages.en')}
            options={[
              {
                label: t('common:languages.en'),
                value: 'en'
              },
              {
                label: t('common:languages.fr'),
                value: 'fr'
              }
            ]}
            value={metadataEmails.lang}
            onChange={(newValue) => {
              setMetadataEmails({ ...metadataEmails, lang: newValue })
            }}
          />
        </Grid>
      </Grid>
      <Divider spacing={20} />
      <Button type="submit" fullSize>
        {update ? t('company:edit.submit') : t('company:new.submit')}
      </Button>
    </form>
  )
}

Form.propTypes = {
  loading: PropTypes.bool,
  update: PropTypes.bool,
  current: PropTypes.instanceOf(Map)
}

Form.defaultProps = {
  loading: false,
  update: false,
  current: new Map()
}

export default Form
