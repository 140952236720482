import React from 'react'
import { useTranslation } from 'react-i18next'
import Chip from './Chip'

function Attachment({
  attachment,
}) {
  const { t } = useTranslation()
  const picture = attachment && attachment.match(/\.(?<extension>gif|jpe?g|tiff?|png|webp|bmp)(\?.*)?$/i)
  const video = attachment && attachment.match(/\.(?<extension>mov|avi|wmv|flv|3gp|mp4|mpg)(\?.*)?$/i)
  
  return (
    <div id="attachment">
      {
        attachment ? <br /> : null
      }
      {
        (attachment && picture) ? <img src={attachment} alt="Message attachment" className="message-img" /> : null
      }
      {
        (attachment && video) ? (
          <video className="message-video" controls>
            <source src={attachment} type={`video/${video.groups.extension}`} />
            Your browser does not support the video tag.
          </video>
        ) : null
      }
      {
        (attachment && !video && !picture) ? (
          <a target="_new" href={attachment} className="message-pdf">
            <Chip
              outline
              icon="description"
              label={t('common:shared.clickToDownload')}
            />
          </a>
        ) : null
      }
    </div>
  )
}

export default Attachment
