import React from 'react'
import { Input } from 'components/common'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
// import { CA_ZIPCODE, US_ZIPCODE } from 'constants/masks'

function PostalCodeField({ onChange, ...otherProps }) {
  const { t } = useTranslation('common')
  // const [mask, setMask] = useState('*******')

  const handleChange = (data) => {
    const upcaseZipcode = data.toUpperCase()
    // if (data && /^[a-zA-Z]/.test(data)) {
    //   // setMask(CA_ZIPCODE)
    // } else if (data && /^\d/.test(data)) {
    //   // setMask(US_ZIPCODE)
    // } else {
    //   // setMask('*******')
    // }
    if (onChange) onChange(upcaseZipcode)
    return upcaseZipcode
  }

  return (
    <Input
      type="text"
      // mask={mask}
      name="zipcode"
      label={t('common:address.zipcode.label')}
      placeholder={t('common:address.zipcode.placeholder')}
      id="new-supplier-zipcode"
      onChange={handleChange}
      {...otherProps}
    />
  )
}

PostalCodeField.propTypes = {
  onChange: PropTypes.func,
}

PostalCodeField.defaultProps = {
  onChange: null,
}

export default PostalCodeField
