import React from 'react'
import {
  Input, AddressFields, Button, Flex,
} from 'components/common'
import { useDispatch } from 'react-redux'
import { Divider } from 'components/ui'
import { orderItems, serviceRequests } from 'store/action-creators'
import { useTranslation } from 'react-i18next'
import SelectAdmin from 'components/pages/users/SelectAdmin'
import Grid from '@material-ui/core/Grid'
import { useForm, Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { SERVICE_REQUEST } from 'constants/resources'
import moment from 'moment'
import { DATETIME } from 'constants/formats'
import { useMultipleContacts } from 'components/hooks'
import { OrderActiveSelect } from 'components/pages/order-items'

function SingleOffRentForm({ current, loading, update }) {
  const { t } = useTranslation([SERVICE_REQUEST, 'error'])

  const dispatch = useDispatch()
  const {
    handleSubmit, control, errors, setValue, register, getValues,
  } = useForm({ mode: 'onBlur' })
  const {
    fields, setCount, onBlur, cleanContacts,
  } = useMultipleContacts({
    initialCount: current.getIn(['metadata', 'contacts'])?.size || 1,
    getValues,
  })
  const onSubmit = (body) => {
    if (update) {
      dispatch(serviceRequests.update(cleanContacts(body)))
    } else {
      dispatch(serviceRequests.create(cleanContacts(body)))
    }
  }

  const handleOrderItemChange = ([data]) => {
    if (data) {
      dispatch(orderItems.get(data, (orderItem) => {
        if (orderItem.order) {
          setCount(orderItem.order.metadata?.contacts?.length || 0)
          setValue([
            { address_line1: orderItem.order.address_line1 },
            { address_line2: orderItem.order.address_line2 },
            { address_zip: orderItem.order.address_zip },
            { address_city: orderItem.order.address_city },
            { address_state: orderItem.order.address_state },
            { address_country: orderItem.order.address_country.code },
            { sales_rep: orderItem.order.sales_rep?.id },
          ])
          if (orderItem.order.metadata.contacts) {
            orderItem.order.metadata.contacts.forEach((contact, i) => {
              setValue(`metadata.contacts[${i}].name`, contact.name)
              setValue(`metadata.contacts[${i}].number`, contact.number)
            })
          }
        }
      }))
    } else {
      setCount(1)
      setValue([
        { address_line1: '' },
        { address_line2: '' },
        { address_zip: '' },
        { address_city: '' },
        { address_state: '' },
        { address_country: '' },
        {
          metadata: {
            contacts: [],
          },
        },
      ])
    }
    return data
  }


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex align="center" justify="space-between">
        <h1>{t('common:shared.information')}</h1>
      </Flex>
      <Divider spacing={20} />
      <input type="hidden" name="designation" value="off-rent" ref={register} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Controller
            as={OrderActiveSelect}
            control={control}
            rules={{
              required: {
                value: true,
                message: t('error:orderItem.required'),
              },
            }}
            error={errors.order_item?.message}
            name="order_item"
            onChange={handleOrderItemChange}
            defaultValue={current.getIn(['order_item', 'id'])}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            name="resolve_by"
            defaultValue={current.get('resolve_by')}
            error={errors.resolve_by?.message}
            control={control}
            type="datetime"
            rules={{
              required: {
                value: true,
                message: t('error:date.required'),
              },
            }}
            label={t('serviceRequest:shared.resolvedBy')}
            placeholder={moment().add(9, 'd').format(DATETIME)}
            id="new-order-end-date"
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={SelectAdmin}
            control={control}
            error={errors.assignee?.message}
            name="assignee"
            placeholder="Jane Doe"
            id="new-service-request-assignee"
            defaultValue={current.getIn(['assignee', 'id'])}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={SelectAdmin}
            control={control}
            error={errors.sales_rep?.message}
            name="sales_rep"
            label={t('common:shared.salesRep')}
            placeholder="John Doe"
            id="new-service-request-sales-rep"
            defaultValue={current.getIn(['sales_rep', 'id'])}
            disabled={loading}
          />
        </Grid>
        {
          fields.map(i => (
            <>
              <Grid item xs={12} md={6}>
                <Controller
                  as={Input}
                  name={`metadata.contacts[${i}].name`}
                  control={control}
                  error={errors.metadata?.contacts[i]?.name?.message}
                  type="text"
                  label={t('order:shared.siteContact')}
                  placeholder={t('order:new.siteContact.placeholder')}
                  id="new-service-request-contact-name"
                  defaultValue={current.getIn(['metadata', 'contacts', i, 'name'])}
                  disabled={loading}
                  onBlur={() => { onBlur(i) }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  as={Input}
                  name={`metadata.contacts[${i}].number`}
                  control={control}
                  error={errors.metadata?.contacts[i]?.number?.message}
                  type="tel"
                  label={t('common:shared.contactNumber')}
                  id="new-service-request-contact-number"
                  defaultValue={current.getIn(['metadata', 'contacts', i, 'number'])}
                  disabled={loading}
                  onBlur={() => { onBlur(i) }}
                />
              </Grid>
            </>
          ))
        }
        <AddressFields
          control={control}
          streetError={errors.address_line1?.message}
          streetDetailError={errors.address_line2?.message}
          zipcodeError={errors.address_zip?.message}
          cityError={errors.address_city?.message}
          stateError={errors.address_state?.message}
          countryError={errors.address_country?.message}
          streetDefaultValue={current.get('address_line1')}
          streetDetailDefaultValue={current.get('address_line2')}
          zipcodeDefaultValue={current.get('address_zip')}
          cityDefaultValue={current.get('address_city')}
          stateDefaultValue={current.get('address_state')}
          countryDefaultValue={current.getIn(['address_country', 'code'])}
          loading={loading}
        />
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            control={control}
            error={errors.metadata?.site_hours?.message}
            name="metadata.site_hours"
            type="text"
            multiline
            label={t('serviceRequest:new.offRent.siteHours.label')}
            placeholder={t('serviceRequest:new.offRent.siteHours.placeholder')}
            id="new-service-request-siteHours"
            defaultValue={current.getIn(['metadata', 'site_hours'])}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            control={control}
            error={errors.metadata?.unit_location?.message}
            name="metadata.unit_location"
            type="text"
            multiline
            label={t('serviceRequest:new.offRent.unitLocation.label')}
            placeholder={t('serviceRequest:new.offRent.unitLocation.placeholder')}
            id="new-service-request-unitLocation"
            defaultValue={current.getIn(['metadata', 'unit_location'])}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            control={control}
            error={errors.metadata?.unit_number?.message}
            name="metadata.unit_number"
            type="text"
            label={t('serviceRequest:new.offRent.unitNumber.label')}
            placeholder={t('serviceRequest:new.offRent.unitNumber.placeholder')}
            id="new-service-request-unit-number"
            defaultValue={current.getIn(['metadata', 'unit_number'])}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            control={control}
            error={errors.metadata?.pickup_by?.message}
            name="metadata.pickup_by"
            type="date"
            label={t('serviceRequest:new.offRent.pickupBy.label')}
            placeholder={t('serviceRequest:new.offRent.pickupBy.placeholder')}
            id="new-service-request-pickupBy"
            defaultValue={current.getIn(['metadata', 'pickup_by'])}
            disabled={loading}
          />
        </Grid>
        {
          current.get('comments') ? (
            <Grid item xs={12} md={12}>
              <Controller
                as={Input}
                control={control}
                error={errors.comments?.message}
                name="comments"
                type="text"
                multiline
                label={t('serviceRequest:shared.clientNotes')}
                placeholder={t('serviceRequest:new.comments.placeholder')}
                helperText={t('serviceRequest:new.comments.helper')}
                id="new-off-rent-notes"
                defaultValue={current.get('comments')}
                disabled
              />
            </Grid>
          ) : null
        }
        <Grid item xs={12} md={12}>
          <Controller
            as={Input}
            control={control}
            error={errors.notes?.message}
            name="notes"
            type="text"
            multiline
            required
            label={t('common:shared.notes')}
            placeholder={t('serviceRequest:new.notes.placeholder')}
            id="new-service-request-notes"
            defaultValue={current.get('notes')}
            disabled={loading}
          />
        </Grid>
      </Grid>
      <Divider spacing={20} />
      <Button
        type="submit"
        fullSize
      >
        {
          update ? t('serviceRequest:edit.submitRequest') : t('serviceRequest:new.submitRequest')
        }
      </Button>
    </form>
  )
}

SingleOffRentForm.propTypes = {
  loading: PropTypes.bool,
  update: PropTypes.bool,
  current: PropTypes.instanceOf(Map),
}

SingleOffRentForm.defaultProps = {
  loading: false,
  update: false,
  current: new Map(),
}

export default SingleOffRentForm
