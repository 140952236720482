import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'
import { Input } from 'components/common'
import { Divider, DeleteXButton } from 'components/ui'
import { EquipmentSelect } from 'components/pages/equipment'
import { SERVICE_REQUEST, ORDER_ITEM, EQUIPMENT } from 'constants/resources'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export const AddItemButton = styled.button`
  width: 11.1%;
  font-size: 0.6em;
  margin: 0.65em;
  padding: 0.25em 1em;
  border: 1.5px solid #000;
  border-radius: 2.5px;
  background-color: #fff;
  color: #000;
  font-weight: 500;
`

const OutlinedCard = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3.5px;
  width: 100%;
  margin: 5px;
  padding: 0px !important;
  background-color: ${({ theme }) => theme.color.primary};
`

const ItemError = styled.p`
  color: ${({ theme }) => theme.color.accent};
  font-size: 0.75rem;
  margin-left: 13px;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.035em;
`

function ItemRow({ equipment, quantity, rowKey, onChange, onDelete, isItemValid, disabled }) {
  const { t } = useTranslation(['common', SERVICE_REQUEST, ORDER_ITEM, EQUIPMENT, 'error'])
  const [isFirstRender, setFirstRender] = useState(true)

  const nameValues = {
    "equipment": "equipment",
    "quantity": "quantity",
  }
  const { control } = useForm({
    mode: 'onBlur',
    defaultValues: {
      item: {
        [nameValues['equipment']]: equipment,
        [nameValues['quantity']]: quantity
      }
    }
  })
  const { register, errors } = useForm()

  const onInputChange = (results, name) => {
    if (isFirstRender && results[0] === '') {
      setFirstRender(false)
      return
    }
    onChange(rowKey, results[0], name)
  }

  const handleEquipmentChange = (results) => {
    const [option] = results
    const value = (option && option.label) || option
    onInputChange(results, nameValues["equipment"])
    return value
  }

  return (
    <OutlinedCard>
      <Grid container spacing={2}>
        {disabled ? <Divider spacing={20} /> : <DeleteXButton onDelete={() => onDelete(rowKey)} />}
        <Grid item xs={8} md={8}>
          <div style={{ color: !isItemValid ? '#f7831e' : '#1f2428' }}>
            <Controller
              as={EquipmentSelect}
              control={control}
              inputRef={register(`item.${nameValues["equipment"]}`)}
              error={errors.item?.equipment?.message}
              name={`item.${nameValues["equipment"]}`}
              freeSolo
              autoSelect={false}
              clearOnBlur={false}
              disabled={disabled}
              label={t('common:resources.equipment.singular')}
              placeholder={t('equipment:select.placeholder')}
              id="new-sr-item-equipment"
              required
              onChange={handleEquipmentChange}
              data-cy="item-row-equipment"
            />
          </div>
          {!isItemValid && <ItemError data-cy="item-error">{t('error:serviceRequestItems.create')}</ItemError>}
        </Grid>
        <Grid item xs={4} md={4}>
          <Controller
            as={Input}
            control={control}
            inputRef={register({
              required: {
                value: true,
                message: t('error:quantity.required'),
              },
            },)}
            error={errors.item?.quantity?.message}
            name={`item.${nameValues["quantity"]}`}
            type={disabled ? "cardtext" : "number"}
            autoSelect={false}
            clearOnBlur={false}
            disabled={disabled}
            label={t('common:shared.quantity')}
            placeholder="1"
            id="new-sr-item-qty"
            required
            onChange={(value) => {onInputChange(value, nameValues["quantity"])}}
            data-cy="item-row-qty"
          />
        </Grid>
      </Grid>
      <Divider spacing={30} />
    </OutlinedCard>
  )
}

export default ItemRow
