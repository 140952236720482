import React, { useEffect, useState } from 'react'
import { Input } from 'components/common'
import { useTranslation } from 'react-i18next'
import { ORDER_ITEM } from 'constants/resources'
import { ORDER_ITEM_INDEX } from 'constants/algolia'
import { withAlgolia } from 'components/hocs'
import { Configure, connectHits } from 'react-instantsearch-dom'
import PropTypes from 'prop-types'

function SelectActive({
  hits, value, ...otherProps
}) {
  const { t } = useTranslation(ORDER_ITEM)
  const filters = otherProps.prop ? `serialized_order.id:${otherProps.prop}` : "status:created OR status:accepted OR status:scheduled OR status:on-rent OR status:off-rent"
  const [query, setQuery] = useState('')
  const [options, setOptions] = useState([])
  useEffect(() => {
    const opts = hits.map(orderItem => ({
      value: orderItem.objectID,
      label: `${orderItem.serialized_equipment.name} (${orderItem.internal_code})`,
    }))
    setOptions(opts)
  }, [hits, value])

  const handleInputChange = (event, data) => {
    setQuery(data)
    return data
  }

  return (
    <>
      <Configure
        query={query}
        filters={filters}
        attributesToRetrieve={[
          'objectID',
          'name',
          'internal_code',
          'serialized_equipment',
        ]}
      />
      <Input
        label={t('selectActive.label')}
        placeholder={t('selectActive.placeholder')}
        type="select"
        id="active-order-items"
        value={value}
        onInputChange={handleInputChange}
        options={options}
        {...otherProps}
      />
    </>
  )
}

SelectActive.propTypes = {
  id: PropTypes.string,
  value: PropTypes.any,
}

SelectActive.defaultProps = {
  id: '',
}

export default withAlgolia(ORDER_ITEM_INDEX)(connectHits(SelectActive))
