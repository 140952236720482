import React from 'react'
import { useTranslation } from 'react-i18next'
import { Chip } from 'components/common'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { orders } from 'store/action-creators'
import { CANCELLED, DEAD, RESOLVED } from 'constants/status'
import { Map } from 'immutable'
import { NEW } from 'constants/crud'
import { ORDER } from 'constants/designations'
import { useQuickPreview } from 'components/hooks'

function CreateOrderButton({
  id,
  status,
  designation,
  serviceRequest,
}) {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const [openPreview] = useQuickPreview()

  const handleChipClick = () => {
    openPreview(ORDER, NEW)
    dispatch(orders.setCreateData(serviceRequest))
  }
  if (designation !== ORDER || [CANCELLED, RESOLVED, DEAD].includes(status)) {
    return null
  }

  return (
    <Chip
      outline
      icon="add"
      onClick={handleChipClick}
      label={t('common:resources.orders.singular')}
    />
  )
}

CreateOrderButton.propTypes = {
  status: PropTypes.string,
  id: PropTypes.string,
  designation: PropTypes.string,
  serviceRequest: PropTypes.instanceOf(Map),
}

CreateOrderButton.defaultProps = {
  status: '',
  id: '',
  designation: '',
  serviceRequest: new Map(),
}


export default CreateOrderButton
