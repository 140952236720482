import React from 'react'
import {
  TableRow, TableCell, PhoneNumber,
} from 'components/common'
import PropTypes from 'prop-types'
import { USER } from 'constants/resources'
import { useTranslation } from 'react-i18next'

function AdminRow({
  name,
  companyId,
  companyName,
  email,
  phoneNumber,
  active,
  onClick,
  templateColumns,
  isOwner,
  lastLogin,
  shrink,
  role,
}) {
  const { t } = useTranslation(USER)

  return (
    <TableRow templateColumns={templateColumns} active={active} onClick={onClick}>
      <TableCell sm>
        <p>{name}</p>
      </TableCell>
      <TableCell lg={!shrink}>
        <p>{role && t(`user:shared.${role}`)}</p>
      </TableCell>
      <TableCell md>
        <a href={`mailto:${email}`}>{email}</a>
      </TableCell>
      <TableCell md>
        <PhoneNumber number={phoneNumber} />
      </TableCell>
    </TableRow>
  )
}

AdminRow.propTypes = {
  name: PropTypes.string,
  role: PropTypes.string,
  lastLogin: PropTypes.number,
  companyId: PropTypes.string,
  companyName: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  active: PropTypes.bool,
  shrink: PropTypes.bool,
  onClick: PropTypes.func,
  templateColumns: PropTypes.string.isRequired,
  isOwner: PropTypes.bool,
}

AdminRow.defaultProps = {
  lastLogin: 0,
  name: '',
  companyId: '',
  companyName: '',
  email: '',
  phoneNumber: '',
  active: false,
  shrink: false,
  onClick: null,
  isOwner: false,
  role: '',
}

export default AdminRow
