import api from 'config/axios'
import { buildQueryString } from 'utils'

export const current = async () => (
  api.get('/id/me/')
)

export const list = async filters => (
  api.get(`/id/users/?${buildQueryString(filters)}`)
)

export const get = async id => (
  api.get(`/id/users/${id}/`)
)

export const create = async ({
  name,
  contact_number,
  email,
  is_admin,
  is_owner,
  company,
  role,
  metadata,
}) => (
  api.post('/id/users/', {
    name,
    contact_number,
    email,
    is_admin,
    is_owner,
    company,
    role,
    metadata,
  })
)

export const update = async (id, {
  name,
  contact_number,
  email,
  is_owner,
  role,
  metadata,
}) => (
  api.patch(`/id/users/${id}/`, {
    name,
    contact_number,
    email,
    is_owner,
    role,
    metadata,
  })
)

export const destroy = async id => (
  api.delete(`/id/users/${id}/`)
)
