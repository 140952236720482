import React from 'react'
import { Flex } from 'components/common'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledHeader = styled(Flex)`
  height: 48px;
  display: inline-block;
  align-items: center;
  > h2 {
    flex-shrink: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0;
  }
  > div > div {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`
function DetailHeader({
  children, title, loading, variant,
}) {
  return (
    <StyledHeader align="center" justify="space-between" data-cy="detail-header">
      {
        !loading ? (
          <div>
            {
              variant === 'small' ? <h3 style={{ fontSize: 16 }} >{title}</h3> : <h2>{title}</h2>
            }
            <div data-cy="detail-header-chips">{children}</div>
          </div>
        ) : <h2 className="loading">{' '}</h2>
      }
    </StyledHeader>
  )
}

DetailHeader.propTypes = {
  title: PropTypes.string.isRequired,
  variant: PropTypes.string,
  loading: PropTypes.bool,
}

DetailHeader.defaultProps = {
  variant: 'normal',
  loading: false,
}

export default DetailHeader
