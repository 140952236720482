import React from 'react'
import { DateTimePicker } from '@material-ui/pickers'
import PropTypes from 'prop-types'
import moment from 'moment'
import { DATETIME } from 'constants/formats'
import TextField from 'components/ui/TextField'

function DateTimeField({
  onChange, value, type, ...otherProps
}) {
  const parsedDate = value ? moment(value) : null
  const handleDateChange = (date) => {
    if (onChange) onChange(date && date.format())
  }
  return (
    <DateTimePicker
      disableToolbar
      autoOk
      ampm={false}
      variant="dialog"
      clearable
      clearLabel="CLEAR"
      inputVariant="filled"
      format={DATETIME}
      value={parsedDate}
      onChange={handleDateChange}
      placeholder={moment().add(7, 'd').format(DATETIME)}
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
      type="text"
      TextFieldComponent={TextField}
      {...otherProps}
    />
  )
}

DateTimeField.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  type: PropTypes.string,
}

DateTimeField.defaultProps = {
  onChange: null,
  value: '',
}

export default DateTimeField
