import React from 'react'
import PropTypes from 'prop-types'
import { CollapsibleOrderItemDetail } from 'components/pages/order-items'
import { SectionHeader } from 'components/common'
import { useTranslation } from 'react-i18next'

function OrderItems({ selected }) {
  const { t } = useTranslation('common')
  const order_items = selected?.getIn(['items'])

  return (
    <div>
      <SectionHeader fullSize={true} title={t('common:resources.equipment.plural')} />
      {order_items?.map((orderItem) => (
        <CollapsibleOrderItemDetail key={orderItem.get('id')} selected={orderItem} />
      ))}
    </div>
  )
}

OrderItems.propTypes = {
  selected: PropTypes.string.isRequired
}

OrderItems.defaultProps = {
  selected: ''
}

export default OrderItems
