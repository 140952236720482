import React from 'react'
import { DetailHeader, ExtraButton } from 'components/common'
import PropTypes from 'prop-types'
import Status from 'components/pages/service-requests/Status'
import { Map } from 'immutable'
import { SERVICE_CALL } from 'constants/resources'
import AssignButton from './AssignButton'
import CreateOrderButton from './CreateOrderButton'
import CreateOrderRequestButton from './CreateOrderRequestButton'

function Header({ status, title, loading, isOrphan, id, designation, serviceRequest }) {
  return (
    <DetailHeader title={title} loading={loading}>
      {designation === 'service-call' ? (
        <ExtraButton
          id={id}
          status={status}
          currentItem={serviceRequest}
          requestType={SERVICE_CALL}
        />
      ) : null}
      <CreateOrderButton
        id={id}
        status={status}
        designation={designation}
        serviceRequest={serviceRequest}
      />
      <CreateOrderRequestButton
        id={id}
        status={status}
        designation={designation}
        serviceRequest={serviceRequest}
      />
      <AssignButton isOrphan={isOrphan} />
      <Status defaultValue={status} serviceRequestId={id} designation={designation} />
    </DetailHeader>
  )
}

Header.propTypes = {
  status: PropTypes.string,
  salesRepId: PropTypes.string,
  assigneeId: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
  loading: PropTypes.bool,
  isOrphan: PropTypes.bool,
  designation: PropTypes.string,
  serviceRequest: PropTypes.instanceOf(Map)
}

Header.defaultProps = {
  status: '',
  salesRepId: '',
  assigneeId: '',
  id: '',
  title: '',
  loading: false,
  isOrphan: false,
  designation: '',
  serviceRequest: new Map()
}

export default Header
