import React, { useState, useCallback } from 'react'
import { Input, AddressFields, Button, Flex } from 'components/common'
import { useDispatch, useSelector } from 'react-redux'
import { Divider } from 'components/ui'
import { companies, serviceRequests } from 'store/action-creators'
import { useTranslation } from 'react-i18next'
import SelectAdmin from 'components/pages/users/SelectAdmin'
import { Grid } from '@material-ui/core'
import ItemRow, { AddItemButton } from './ItemRow'
import { useForm, Controller, FormContext } from 'react-hook-form'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { ORDER, SERVICE_REQUEST } from 'constants/resources'
import { CompanySelect } from 'components/pages/companies'
import { useAttachment } from 'components/hooks'
import { OPERATIONS, SUPERUSER } from 'constants/roles'
import moment from 'moment'

function Form({ current, loading, update }) {
  const role = useSelector((state) => state.getIn(['session', 'user', 'role']))
  const serviceRequestItems = current.get('service_request_items')
  const currentUser = useSelector((state) => state.getIn(['session', 'user', 'id']))
  const isOperations = [OPERATIONS, SUPERUSER].includes(role)
  const isAdmin = [SUPERUSER].includes(role)
  const { t } = useTranslation([SERVICE_REQUEST, ORDER, 'error'])
  const dispatch = useDispatch()
  const [attachmentPayload, setFile] = useAttachment()
  const setupItemsData = () => {
    if (update) {
      var updatedItemsData = {}
      for (let i = 0; i < serviceRequestItems.size; i++) {
        updatedItemsData = {
          ...updatedItemsData,
          [`item_${i}`]: {
            equipment: serviceRequestItems.getIn([i, 'equipment', 'name']),
            quantity: serviceRequestItems.getIn([i, 'quantity']),
            isItemValid: true
          }
        }
      }
      return updatedItemsData
    } else {
      return { item_0: { equipment: '', quantity: NaN, isItemValid: true } }
    }
  }
  const [itemsData, setItemsData] = useState(setupItemsData())

  const { handleSubmit, control, errors, register, getValues, setValue } = useForm({
    mode: 'onBlur',
    defaultValues: {
      designation: 'rental-price',
      metadata: {
        company: current.getIn(['metadata', 'company']),
        company_id: current.getIn(['metadata', 'company_id']),
        duration: current.getIn(['metadata', 'duration']),
        delivery_date: current.getIn(['metadata', 'delivery_date']),
        price_given: current.getIn(['metadata', 'price_given']),
        transport_price_given: current.getIn(['metadata', 'transport_price_given']),
        purchase_order: current.getIn(['metadata', 'purchase_order']),
        used_for: current.getIn(['metadata', 'used_for']),
        last_quote: current.getIn(['metadata', 'last_quote']),
        quote_type: current.getIn(['metadata', 'quote_type']),
        current_supplier: current.getIn(['metadata', 'current_supplier']),
        opsNotes: current.getIn(['metadata', 'opsNotes'])
      },
      state: current.get('state'),
      assignee: current.getIn(['assignee', 'id']),
      notes: current.get('notes'),
      address_line1: current.get('address_line1'),
      address_line2: current.get('address_line2'),
      address_zip: current.get('address_zip'),
      address_city: current.get('address_city'),
      address_state: current.get('address_state'),
      address_country: current.getIn(['address_country', 'code']),
      sales_rep: current.getIn(['sales_rep', 'id']) || currentUser,
      contacts: current.getIn(['metadata', 'contacts'])?.map(contact => contact.toJS()),
    },
  })

  const onSubmit = (data) => {
    if (Object.values(itemsData).some((obj) => !obj.isItemValid)) {
      return
    }
    const sendBody = { ...data }
    const requestBody = attachmentPayload(sendBody)
    if (update) {
      const body = { 
        ...requestBody,
        items: itemsData,
        index: serviceRequestItems.size,
        ...(requestBody['turnaround_time'] && { turnaround_time: moment.duration(requestBody['turnaround_time'], 'hours').asMilliseconds() })
      }
      dispatch(serviceRequests.update(body))
    } else {
      requestBody.metadata = metadataSlackNotification(requestBody.metadata)
      const body = { ...requestBody, items: itemsData }
      dispatch(serviceRequests.create(body))
    }
  }
  const metadataSlackNotification = (metadata) => {
    return {
      ...metadata,
      slack_notification_items: Object.keys(itemsData)?.map((key) => {
        return {
          equipment: itemsData[key].equipment.label,
          quantity: itemsData[key].quantity
        }
      })
    }
  }
  const handleCompanyChange = (results) => {
    const [option] = results
    if (!option) return undefined

    // Set ID for backend to recognize new/existing company
    setValue('metadata.company_id', option?.value)
    if (!update && option.value) {
      dispatch(
        companies.get(option.value, (val) => {
          setValue('metadata.current_supplier', val.metadata?.current_supplier)
        })
      )
    }
    return (option && option.label) || option
  }

  const onChange = useCallback(
    (key, rowData, field) => {
      switch (field) {
        case 'equipment':
          if (typeof rowData === 'object' && rowData !== null) {
            setItemsData((prevState) => {
              return {
                ...prevState,
                [key]: {
                  ...prevState[key],
                  [field]: rowData,
                  isItemValid: true
                }
              }
            })
          } else {
            if (rowData === itemsData[key].equipment.label) {
              return
            }
            setItemsData((prevState) => {
              return {
                ...prevState,
                [key]: {
                  ...prevState[key],
                  isItemValid: false
                }
              }
            })
          }
          break
        case 'quantity':
          setItemsData((prevState) => {
            return {
              ...prevState,
              [key]: {
                ...prevState[key],
                [field]: rowData
              }
            }
          })
          break
        default:
          break
      }
    },
    [itemsData]
  )

  const onDelete = useCallback((key) => {
    setItemsData((prevState) => {
      if (Object.keys(prevState).length > 1) {
        delete prevState[key]
      }
      return {
        ...prevState
      }
    })
  }, [])

  const addItemRow = () => {
    let keys = Object.keys(itemsData),
      keysIndex = keys.map(function (d) {
        return d.replace('item_', '')
      })
    let maxIndex = 0
    if (keysIndex.length > 0) {
      maxIndex = Math.max(...keysIndex.map((i) => Number(i)))
    }
    setItemsData({
      ...itemsData,
      [`item_${maxIndex + 1}`]: { equipment: '', quantity: '', isItemValid: true }
    })
  }

  return (
    <FormContext handleSubmit={handleSubmit} control={control} errors={errors} register={register} getValues={getValues} setValue={setValue}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex align="center" justify="space-between">
          <h1>{t('common:shared.information')}</h1>
        </Flex>
        <Divider spacing={20} />
        <input type="hidden" name="designation" value="rental-price" ref={register} />
        <input type="hidden" name="metadata.company_id" ref={register} />
        <input type="hidden" name="metadata.opsNotes" ref={register} />

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Controller
              as={CompanySelect}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t('error:company.required')
                }
              }}
              required
              error={errors?.metadata?.company?.message}
              name="metadata.company"
              freeSolo
              id="new-service-request-company"
              autoSelect={false}
              clearOnBlur={false}
              defaultValue={current.getIn(['metadata', 'company'])}
              onChange={handleCompanyChange}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller as={SelectAdmin} control={control} error={errors.assignee?.message} name="assignee" placeholder="Jane Doe" id="new-service-request-assignee" defaultValue={current.getIn(['assignee', 'id'])} disabled={loading || !update} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller as={SelectAdmin} control={control} error={errors.sales_rep?.message} name="sales_rep" label={t('common:shared.salesRep')} placeholder="John Doe" id="new-service-request-sales-rep" defaultValue={current.getIn(['sales_rep', 'id'])} disabled={loading} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              as={Input}
              control={control}
              name="metadata.duration"
              defaultValue={current.getIn(['metadata', 'duration'])}
              error={errors.metadata?.duration?.message}
              type="number"
              rules={{
                required: {
                  value: true,
                  message: t('error:duration.required')
                }
              }}
              required
              label={t('common:shared.duration')}
              placeholder={t('serviceRequest:new.duration.placeholder')}
              id="new-service-request-duration"
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              as={Input}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t('error:deliveryDate.required')
                }
              }}
              name="metadata.delivery_date"
              error={errors.metadata?.delivery_date?.message}
              defaultValue={current.getIn(['metadata', 'delivery_date'])}
              type="datetime"
              required
              label={t('serviceRequest:shared.deliveryDate')}
              placeholder={t('serviceRequest:new.deliveryDate.placeholder')}
              id="new-service-request-deliveryDate"
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              as={Input}
              control={control}
              name="metadata.used_for"
              error={errors.used_for?.message}
              defaultValue={current.getIn(['metadata', 'used_for'])}
              type="text"
              required
              label={t('serviceRequest:new.usedFor.label')}
              placeholder={t('serviceRequest:new.notes.placeholder')}
              id="new-service-request-used-for"
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              as={Input}
              control={control}
              type="text"
              name="metadata.last_quote"
              defaultValue={current.getIn(['metadata', 'last_quote'])}
              error={errors.last_quote?.message}
              label={t('serviceRequest:shared.lastQuote')}
              placeholder={t('serviceRequest:new.lastQuote.placeholder')}
              id="new-service-request-lastQuote"
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              as={Input}
              control={control}
              name="metadata.quote_type"
              error={errors.quote_type?.message}
              defaultValue={current.getIn(['metadata', 'quote_type'])}
              type="select"
              options={[
                { value: 'daily', label: t('common:shared.dailyRate') },
                { value: 'weekly', label: t('common:shared.weeklyRate') },
                { value: 'monthly', label: t('common:shared.monthlyRate') },
                { value: 'transport', label: t('orderItem:shared.deliveryCost') }
              ]}
              label={t('serviceRequest:shared.priceType')}
              placeholder={t('serviceRequest:new.priceType.placeholder')}
              id="new-service-request-priceType"
              freeSolo
              autoSelect={false}
              clearOnBlur={false}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              as={Input}
              control={control}
              name="metadata.current_supplier"
              defaultValue={current.getIn(['metadata', 'current_supplier'])}
              error={errors.current_supplier?.message}
              type="text"
              label={t('serviceRequest:shared.currentSupplier')}
              placeholder={t('serviceRequest:new.currentSupplier.placeholder')}
              id="new-service-request-supplier"
              disabled={loading}
            />
          </Grid>
          <AddressFields
            control={control}
            streetError={errors.address_line1?.message}
            streetDetailError={errors.address_line2?.message}
            zipcodeError={errors.address_zip?.message}
            cityError={errors.address_city?.message}
            stateError={errors.address_state?.message}
            countryError={errors.address_country?.message}
            streetDefaultValue={current.get('address_line1')}
            streetDetailDefaultValue={current.get('address_line2')}
            zipcodeDefaultValue={current.get('address_zip')}
            cityDefaultValue={current.get('address_city')}
            stateDefaultValue={current.get('address_state')}
            countryDefaultValue={current.getIn(['address_country', 'code'])}
            loading={loading}
            streetRequired
            zipcodeRequired
            cityRequired
            stateRequired
            countryRequired
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <h3>{t('common:resources.equipment.plural')}</h3>
          </div>
        </Grid>
        {Object.keys(itemsData).map((key, index) => {
          return (
            <ItemRow
              equipment={itemsData[key].equipment}
              quantity={itemsData[key].quantity}
              isItemValid={itemsData[key].isItemValid}
              key={key}
              rowKey={key}
              onChange={update && serviceRequestItems && index < serviceRequestItems.size ? undefined : onChange}
              onDelete={update && serviceRequestItems && index < serviceRequestItems.size ? undefined : onDelete}
              disabled={(update && serviceRequestItems && index < serviceRequestItems.size) || loading}
            />
          )
        })}
        <AddItemButton id="add-item-row-btn" onClick={() => addItemRow()} type="button" disabled={loading}>
          + {t('serviceRequest:items.add')}
        </AddItemButton>
        {(isAdmin && update) &&
          <Grid item xs={12} md={12}>
            <Controller
              as={Input}
              control={control}
              error={errors.turnaround_time?.message}
              name="turnaround_time"
              type="number"
              placeholder="0.00"
              label={t('serviceRequest:shared.turnaroundTime')}
              id="new-service-request-turnaround-time"
              defaultValue={moment.duration(current.get('turnaround_time')).asHours().toFixed(2)}
              disabled={loading}
            />
          </Grid>
        }
        <Grid item xs={12} md={12}>
          <Controller as={Input} control={control} error={errors.notes?.message} name="notes" type="text" multiline label={t('common:shared.notes')} placeholder={t('serviceRequest:new.notes.placeholder')} id="new-service-request-notes" defaultValue={current.get('notes')} disabled={loading} />
        </Grid>
        {isOperations ? (
          <div>
            <Grid item xs={12} md={12}>
              <Controller
                as={Input}
                control={control}
                error={errors.metadata?.opsNotes?.message}
                name="metadata.opsNotes"
                type="text"
                multiline
                label={t('serviceRequest:new.opsNotes.label')}
                placeholder={t('serviceRequest:new.opsNotes.placeholder')}
                id="new-service-request-opsNotes"
                defaultValue={current.getIn(['metadata', 'opsNotes'])}
                disabled={loading}
                required
                rules={{
                  required: {
                    value: true,
                    message: t('error:opsNote.required')
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Input
                type="file"
                max={15}
                label={t('serviceRequest:shared.opsAttachment')}
                maxMessage={t('error:file.max')}
                helperText={t('common:file.helper', { max: 15 })}
                placeholder={t('common:file.placeholder')}
                dropPlaceholder={t('common:file.dropPlaceholder')}
                onChange={setFile}
                name="opsAttachment"
                id="new-service-request-opsAttachment"
              />
            </Grid>
          </div>
        ) : null}
        <Divider spacing={20} />
        <Button type="submit" fullSize disabled={loading}>
          {update ? t('serviceRequest:edit.submitRequest') : t('serviceRequest:new.submitRequest')}
        </Button>
      </form>
    </FormContext>
  )
}

Form.propTypes = {
  loading: PropTypes.bool,
  update: PropTypes.bool,
  current: PropTypes.instanceOf(Map)
}

Form.defaultProps = {
  loading: false,
  update: false,
  current: new Map()
}

export default Form
