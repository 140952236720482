import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { useDispatch, useSelector } from 'react-redux'
import { serviceRequestMessages } from 'store/action-creators'
import { Grid } from '@material-ui/core'
import CheckboxEditable from 'components/common/CheckboxEditable'
import { useForm, Controller, FormContext } from 'react-hook-form'
import { Input, Button, Flex, OutlinedCard } from 'components/common'
import { Divider } from 'components/ui'
import { SERVICE_REQUEST, ORDER_ITEM, EQUIPMENT } from 'constants/resources'
import { useTranslation } from 'react-i18next'
import { QuoteLangToggle } from 'components/pages/service-request-messages'
import useNotification from 'components/hooks/use-notification'
import Icon from 'components/ui/Icon'

function QuoteForm() {
  const { t } = useTranslation(['common', SERVICE_REQUEST, ORDER_ITEM, EQUIPMENT, 'error'])
  const methods = useForm({ mode: 'onBlur' })
  const dispatch = useDispatch()
  const notify = useNotification()
  const currentRequest = useSelector(state => state.getIn([SERVICE_REQUEST, 'current']))
  const items = currentRequest.getIn(['data', 'service_request_items'])
  const [isItemCheckedArr, setItemCheckedArr] = useState(new Array(items.size === undefined ? 0 : items.size).fill(true))
  const [lang, setLang] = useState('en')

  const itemHasValidRate = (index) => {
    return items.getIn([index, 'daily_rate']) || items.getIn([index, 'weekly_rate']) || items.getIn([index, 'monthly_rate'])
  }

  useEffect(() => {
    var updatedArr = [...isItemCheckedArr]
    isItemCheckedArr.forEach((e, index) => {
      if (!itemHasValidRate(index)) {
        updatedArr[index] = false
      }
    })
    setItemCheckedArr(updatedArr)
    //eslint-disable-next-line
  }, [])

  const onSubmit = (submitData) => {
    let item_id_list = []
    for(let i = 0; i < items.size; i++) {
      if (isItemCheckedArr[i]) {
        item_id_list.push(items.getIn([i, 'id']))
      }
    }

    if (items.every((item, index) => !isItemCheckedArr[index] || itemHasValidRate(index) )) {
      const data = {
        'content': submitData.content,
        'service_request': submitData.service_request,
        'item_id_list': item_id_list,
      }
      dispatch(serviceRequestMessages.createQuote({lang, data}))
     } else {
      notify({
        type: 'error',
        text: t('serviceRequest:pdf.missingInformation'),
      })
     }
  }
  
  return (
    <FormContext {...methods}>
      <div style={{ 
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 5
      }}>
        <QuoteLangToggle lang={lang} setLang={setLang}/>
      </div>
      <input type="hidden" name="service_request" ref={methods.register} value={currentRequest.get('id')} />
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Controller
              as={Input}
              control={methods.control}
              rules={{
                required: {
                  value: true,
                  message: t('error:content.required'),
                },
              }}
              error={methods.errors.content?.message}
              name="content"
              type="text"
              multiline
              label={t('serviceRequest:shared.content')}
              placeholder={t('serviceRequest:new.content.placeholder')}
              id="new-service-request-content"
              required
            />
          </Grid>
          <Flex align="center" justify="space-between">
            <h3>{t('common:resources.equipment.singular')}</h3>
          </Flex>
          <Grid item xs={4} md={12}>
            <>
              {[...Array(items.size)].map((x, i) =>
                <OutlinedCard key={items.getIn([i, 'id'])}>
                  <CheckboxEditable
                    name="items"
                    checked={isItemCheckedArr[i]}
                    disabled={!itemHasValidRate(i)}
                    onClick={() => setItemCheckedArr(
                      [
                        ...isItemCheckedArr.slice(0, i), 
                        !isItemCheckedArr[i],
                        ...isItemCheckedArr.slice(i+1), 
                      ]
                    )}
                    label={`${items.getIn([i, 'quantity'])} x ${items.getIn([i, 'equipment', 'name'])}`}
                  />
                  {!itemHasValidRate(i) &&
                    <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '10px' }} >
                      <Icon name="error" size={11} color="#f7581e" />
                      <p style={{ color: '#f7581e', fontSize: 11, padding: '0px 5px' }}>{t('error:serviceRequestItems.emptyRates')}</p>
                    </div>

                  }
                </OutlinedCard>
                )} 
            </>
          </Grid> 
        </Grid>
        <Divider spacing={20} />
        <Button
          type="submit"
          fullSize
          data-cy="new-service-request-submit-btn"
        >
          {t('serviceRequest:new.postMessage')}
        </Button>
      </form>
    </FormContext>
  )
}

QuoteForm.propTypes = {
  updateMessage: PropTypes.bool,
  currentMessage: PropTypes.instanceOf(Map)
}

QuoteForm.defaultProps = {
  updateMessage: false,
  currentMessage: new Map()
}

export default QuoteForm
