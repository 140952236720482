import React from 'react'
import { ORDER, SERVICE_CALL, SERVICE_REQUEST } from 'constants/resources'
import { NEW } from 'constants/crud'
import { serviceRequests } from 'store/action-creators'
import { useDispatch } from 'react-redux'
import { useQuickPreview } from 'components/hooks'
import { useTranslation } from 'react-i18next'
import { Chip } from 'components/common'
import PropTypes from 'prop-types'
import { Map } from 'immutable'

function ExtraButton({
  status,
  id,
  currentItem,
  requestType
}) {
  const { t } = useTranslation('common', SERVICE_REQUEST)
  const [openPreview] = useQuickPreview()
  const dispatch = useDispatch()

  const handleChipClick = () => {
    openPreview(SERVICE_REQUEST, NEW)
    const extraRequestData = {
      designation: 'extra',
      metadata: {
        contacts: currentItem.getIn(['metadata', 'contacts']),
      },
      order: {},
      sales_rep: currentItem.get('sales_rep'),
      assignee: currentItem.get('assignee'),
      address_line1: currentItem.get('address_line1'),
      address_line2: currentItem.get('address_line2'),
      address_zip: currentItem.get('address_zip'),
      address_city: currentItem.get('address_city'),
      address_state: currentItem.get('address_state'),
      address_country: { code: currentItem.getIn(['address_country', 'code']) },
    }

    switch (requestType) {
      case SERVICE_CALL:
        extraRequestData.order.internal_code = currentItem.getIn(['order_item', 'order', 'internal_code']).split('#')[1]
        extraRequestData.order.id = currentItem.getIn(['order_item', 'order', 'id'])
        extraRequestData.metadata.company = currentItem.getIn(['order_item', 'order', 'company', 'name'])
        extraRequestData.metadata.company_id = currentItem.getIn(['order_item', 'order', 'company', 'id'])
        extraRequestData.metadata.service_call_reference = currentItem.get('internal_code')
        extraRequestData.order.items = currentItem.get('order_item')
        extraRequestData.metadata.service_call_reference_id = id
        break
      case ORDER:
        extraRequestData.order.internal_code = currentItem.get('internal_code').split('#')[1]
        extraRequestData.order.id = id
        extraRequestData.order.items = currentItem.get('items')
        extraRequestData.metadata.company = currentItem.getIn(['company', 'name'])
        extraRequestData.metadata.company_id = currentItem.getIn(['company', 'id'])
        break
      default: break
    }
    dispatch(serviceRequests.setCreateData(extraRequestData))
  }

  return (
    <Chip
      outline
      icon="warning"
      onClick={handleChipClick}
      label={t('serviceRequest:shared.extra')}
    />
  )
}

ExtraButton.propTypes = {
  id: PropTypes.string,
  status: PropTypes.string,
  order: PropTypes.instanceOf(Map),
  requestType: PropTypes.string,
}

ExtraButton.defaultProps = {
  id: '',
  status: '',
  order: new Map(),
  requestType: '',
}

export default ExtraButton
