import React from 'react'
import { useSelector } from 'react-redux'
import { ServiceRequestRow } from 'components/pages/service-requests'
import { TableContent, TableLoading } from 'components/common'
import { SERVICE_REQUEST } from 'constants/resources'
import {
  useResource,
  useStatusColors,
  useSelectResource,
  useDeviceColumns,
  useStoreAlgolia,
  useFacetFilters,
  useFilters,
  useAlgoliaSearch
} from 'components/hooks'
import PropTypes from 'prop-types'
import { serviceRequests } from 'store/action-creators'
import { connectInfiniteHits, Configure } from 'react-instantsearch-dom'
import { useTranslation } from 'react-i18next'
import { SERVICE_REQUEST_INDEX } from 'constants/algolia'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'

function ServiceRequestContent({
  hits,
  hasMore,
  refineNext,
  hasPrevious,
  refinePrevious,
  columns
}) {
  const statusColors = useStatusColors(SERVICE_REQUEST)
  const selected = useResource(SERVICE_REQUEST, 'current', 'id')
  const list = useStoreAlgolia(SERVICE_REQUEST, serviceRequests, hits)
  const loading = useResource(SERVICE_REQUEST, 'list', 'loading')
  const handleRowClick = useSelectResource(SERVICE_REQUEST)
  const templateColumns = useDeviceColumns(columns, !!selected)
  const facetFilters = useFacetFilters(SERVICE_REQUEST)
  const filters = useFilters(SERVICE_REQUEST)
  const { t } = useTranslation()
  const pendingPayItems = useAlgoliaSearch(SERVICE_REQUEST_INDEX, {
    filters: 'status:pending-pay AND designation:service-call AND is_redacted:false'
  })
  const showOverlayDisplay = useSelector((state) =>
    state.getIn(['application', 'overlay', 'active'])
  )

  return (
    <>
      <TableContent next={refineNext} dataLength={hits.length} hasMore={hasMore}>
        <Configure
          hitsPerPage={40}
          filters={filters ? `designation:service-call AND ${filters}` : 'designation:service-call'}
          facetFilters={facetFilters}
        />
        <TableLoading loading={loading} />
        {list
          .filter((req) => !req.get('is_redacted'))
          .map((serviceRequest) => (
            <ServiceRequestRow
              statusColor={statusColors[serviceRequest.get('status')]}
              key={serviceRequest.get('id')}
              id={serviceRequest.get('id')}
              shrink={!!selected}
              templateColumns={templateColumns}
              internalCode={serviceRequest.get('internal_code')}
              status={serviceRequest.get('status')}
              companyName={serviceRequest.getIn(['order_item', 'order', 'company', 'name'])}
              companyId={serviceRequest.getIn(['order_item', 'order', 'company', 'id'])}
              equipmentName={serviceRequest.getIn(['order_item', 'equipment', 'name'])}
              equipmentId={serviceRequest.getIn(['order_item', 'equipment', 'id'])}
              assigneeName={serviceRequest.getIn(['assignee', 'name'])}
              assigneeId={serviceRequest.getIn(['assignee', 'id'])}
              salesRepName={serviceRequest.getIn(['sales_rep', 'name'])}
              salesRepId={serviceRequest.getIn(['sales_rep', 'id'])}
              equipment={serviceRequest.getIn(['order_item', 'equipment'])}
              createdAt={serviceRequest.get('created')}
              createdByName={serviceRequest.getIn(['created_by', 'name'])}
              createdById={serviceRequest.getIn(['created_by', 'id'])}
              extraIdArray={serviceRequest.getIn(['metadata', 'extra_id'])}
              active={serviceRequest.get('id') === selected}
              onClick={() => handleRowClick(serviceRequest.get('id'))}
            />
          ))}
      </TableContent>
      {!showOverlayDisplay && !!pendingPayItems && (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          open
        >
          <Alert severity="warning">{`${t(
            'serviceRequest:status.pending-pay'
          )}: ${pendingPayItems} ${t('common:resources.requests.plural').toLowerCase()}`}</Alert>
        </Snackbar>
      )}
    </>
  )
}

ServiceRequestContent.propTypes = {
  columns: PropTypes.object.isRequired
}

export default connectInfiniteHits(ServiceRequestContent)
