import PropTypes from 'prop-types'
import { Map } from 'immutable'
import OffRentDetail from 'components/pages/service-requests/detail/OffRentDetail'
import OrderDetail from 'components/pages/service-requests/detail/OrderDetail'
import PurchasePriceDetail from 'components/pages/service-requests/detail/PurchasePriceDetail'
import RentalPriceDetail from 'components/pages/service-requests/detail/RentalPriceDetail'
import ServiceCallDetail from 'components/pages/service-requests/detail/ServiceCallDetail'
import ExtraDetail from 'components/pages/service-requests/detail/ExtraDetail'
import {
  OFF_RENT, ORDER, PURCHASE_PRICE, RENTAL_PRICE, SERVICE_CALL, EXTRA
} from 'constants/designations'
import { useDispatch, useSelector } from 'react-redux'
import { SERVICE_REQUEST_MESSAGE } from 'constants/resources'
import React, { useEffect } from 'react'
import { serviceRequestMessages } from 'store/action-creators'

function ServiceRequest({
  id,
  loading,
  selected,
}) {
  const dispatch = useDispatch()
  const messages = useSelector(state => state.getIn([SERVICE_REQUEST_MESSAGE, 'list', 'data']))
  useEffect(() => {
    if (id) {
      dispatch(serviceRequestMessages.getListReset({
        service_request: id,
        limit: 100,
      }))
    }
  }, [dispatch, id])

  if (!id) return null
  if (selected.get('designation') === OFF_RENT) return <OffRentDetail messages={messages} selected={selected} loading={loading} />
  if (selected.get('designation') === ORDER) return <OrderDetail messages={messages} selected={selected} loading={loading} />
  if (selected.get('designation') === PURCHASE_PRICE) return <PurchasePriceDetail messages={messages} selected={selected} loading={loading} />
  if (selected.get('designation') === RENTAL_PRICE) return <RentalPriceDetail messages={messages} selected={selected} loading={loading} />
  if (selected.get('designation') === SERVICE_CALL) return <ServiceCallDetail messages={messages} selected={selected} loading={loading} />
  if (selected.get('designation') === EXTRA) return <ExtraDetail messages={messages} selected={selected} loading={loading} />

  return null
}


ServiceRequest.propTypes = {
  id: PropTypes.string,
  loading: PropTypes.bool,
  selected: PropTypes.instanceOf(Map),
}

ServiceRequest.defaultProps = {
  id: '',
  loading: false,
  selected: new Map(),
}


export default ServiceRequest
