import { SERVICE_REQUEST_ITEM } from 'constants/resources'
import { serviceRequestItems } from 'store/action-creators'
import { create as createItemService } from 'services/service-request-items'
import { takeLatest, call, select, put } from 'redux-saga/effects'
import {
  LIST,
  GET,
  CREATE,
  UPDATE,
  DELETE,
} from 'store/actions/factory'
import {
  list, get, update, destroy
} from './factory'
import nprogress from './nprogress'

function* create({ payload = {} }) {
  yield call(nprogress, true)
  const { body, onSuccess, onError } = payload
  yield put(serviceRequestItems.setCreateLoading(true))
  const itemList = yield select((store) => store.getIn([SERVICE_REQUEST_ITEM, 'list', 'data']))
  const response = yield call(createItemService, body)
  if (response) {
    yield put(serviceRequestItems.setListData((itemList ?? []).toJS().concat(response)))
    if (onSuccess) yield call(onSuccess, response)
  } else if (onError) yield call(onError)

  yield call(nprogress, false)
  yield put(serviceRequestItems.setCreateLoading(false))
}

export default function* serviceRequestItemSaga() {
  yield takeLatest(LIST(SERVICE_REQUEST_ITEM), list)
  yield takeLatest(GET(SERVICE_REQUEST_ITEM), get)
  yield takeLatest(CREATE(SERVICE_REQUEST_ITEM), create)
  yield takeLatest(UPDATE(SERVICE_REQUEST_ITEM), update)
  yield takeLatest(DELETE(SERVICE_REQUEST_ITEM), destroy)
}
