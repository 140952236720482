import React, { useState } from 'react'
import { Input, AddressFields, Button, PriceField, Flex } from 'components/common'
import { useDispatch, useSelector } from 'react-redux'
import { Divider } from 'components/ui'
import { orders, serviceRequests } from 'store/action-creators'
import { useTranslation } from 'react-i18next'
import { CompanySelect } from 'components/pages/companies'
import { SupplierSelect } from 'components/pages/suppliers'
import Grid from '@material-ui/core/Grid'
import { useForm, Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import { List, Map } from 'immutable'
import { SERVICE_REQUEST } from 'constants/resources'
import SelectAdmin from 'components/pages/users/SelectAdmin'
import { OrderActiveSelect } from 'components/pages/order-items'
import { useMultipleContacts } from 'components/hooks'
import Status from './Status'
import { OPERATIONS, SUPERUSER } from 'constants/roles'
import { OrderSelect } from 'components/pages/orders'
import { ServiceCallSelect } from 'components/pages/service-requests'
function Form({ current, loading, update }) {
  const { t } = useTranslation([SERVICE_REQUEST, 'error'])
  const role = useSelector((state) => state.getIn(['session', 'user', 'role']))
  const currentUser = useSelector((state) => state.getIn(['session', 'user', 'id']))
  const isOperations = [OPERATIONS, SUPERUSER].includes(role)
  const dispatch = useDispatch()
  const isServiceCall = current.getIn(['order', 'items']) instanceof Map
  const isOrder = current.getIn(['order', 'items']) instanceof List

  const setOrderItem = () => {
    if (current.getIn(['order_item', 'id'])) {
      return `${current.getIn(['order_item', 'equipment', 'name'])} (${current.getIn([
        'order_item',
        'internal_code'
      ])})`
    }
    if (current.getIn(['order', 'items', 'internal_code'])) {
      return `${current.getIn(['order', 'items', 'equipment', 'name'])} (${current.getIn([
        'order',
        'items',
        'internal_code'
      ])})`
    }
    return ''
  }
  const { handleSubmit, control, errors, setValue, register, getValues } = useForm({
    mode: 'onBlur',
    defaultValues: {
      designation: 'extra',
      metadata: {
        company: current.getIn(['metadata', 'company']),
        company_id: current.getIn(['metadata', 'company_id']),
        supplier: current.getIn(['metadata', 'supplier']),
        supplier_id: current.getIn(['metadata', 'supplier_id']),
        occurrence_date: current.getIn(['metadata', 'occurrence_date']),
        contract_reference:
          current.getIn(['metadata', 'contract_reference']) ||
          current.getIn(['order', 'internal_code']),
        contract_reference_id:
          current.getIn(['metadata', 'contract_reference_id']) || current.getIn(['order', 'id']),
        service_call_reference: current.getIn(['metadata', 'service_call_reference']),
        service_call_reference_id: current.getIn(['metadata', 'service_call_reference_id']),
        sent_invoice_number: current.getIn(['metadata', 'sent_invoice_number']),
        invoices_sent: current.getIn(['metadata', 'invoices_sent']),
        received_invoice_number: current.getIn(['metadata', 'received_invoice_number']),
        invoices_received: current.getIn(['metadata', 'invoices_received']),
        supplier_charge: current.getIn(['metadata', 'supplier_charge']),
        rac_charge: current.getIn(['metadata', 'rac_charge']),
        opsNotes: current.getIn(['metadata', 'opsNotes'])
      },
      assignee: current.getIn(['assignee', 'id']),
      sales_rep: current.getIn(['sales_rep', 'id']) || currentUser,
      address_line1: current.get('address_line1'),
      address_line2: current.get('address_line2'),
      address_zip: current.get('address_zip'),
      address_city: current.get('address_city'),
      address_state: current.get('address_state'),
      address_country: current.getIn(['address_country', 'code']),
      order_item: setOrderItem,
      notes: current.get('notes')
    }
  })
  const { fields, onBlur, cleanContacts } = useMultipleContacts({
    initialCount: current.getIn(['metadata', 'contacts'])?.size || 1,
    getValues
  })

  const [contractReference, setContractReference] = useState(
    isOrder
      ? current.getIn(['metadata', 'contract_reference_id']) || current.getIn(['order', 'id'])
      : undefined
  )

  // Autoselect item if extra is being created from service call
  const [selectedItem, setSelectedItem] = useState(
    isServiceCall ? current.getIn(['order', 'items','id']) : undefined
  )
  const onSubmit = (body) => {
    // Set contract reference ID
    body.metadata.contract_reference_id = body.metadata.contract_reference_id
      ? body.metadata.contract_reference_id
      : current.getIn(['metadata', 'contract_reference_id']) || current.getIn(['order', 'id'])
    body.metadata.service_call_reference_id = body.metadata.service_call_reference_id
      ? body.metadata.service_call_reference_id
      : current.getIn(['metadata', 'service_call_reference_id'])
    // Set order item ID
    body.order_item = !update && selectedItem ? selectedItem : current.getIn(['order_item', 'id'])
    body.notFromExtraForm = isServiceCall || isOrder

    if (update) {
      dispatch(serviceRequests.update(cleanContacts(body)))
    } else {
      dispatch(serviceRequests.create(cleanContacts(body)))
    }
  }
  const handleChange = (results, val) => {
    const [option] = results
    if (!option) return undefined
    setValue(val, option?.value)
    if (val === 'order_item') {
      setSelectedItem(results[0].value)
    }
    return (option && option.label) || option
  }

  const handleStatusChange = (val) => {
    if (!getValues('status')) register({ name: 'status' })
    setValue('status', val)
  }

  const handleAutoFillChange = ([data], val) => {
    const store = val === 'metadata.contract_reference_id' ? orders : serviceRequests
    if (data?.value) {
      dispatch(
        store.getStore(data.value, (item) => {
          if (item) {
            setValue([
              { address_line1: item.address_line1 },
              { address_line2: item.address_line2 },
              { address_zip: item.address_zip },
              { address_city: item.address_city },
              { address_state: item.address_state },
              { address_country: item.address_country?.code },
              { sales_rep: item.sales_rep?.id },
            ])
            if (store === orders) {
              setContractReference(data.value)
              setValue([
                { 'metadata.company': item.company?.name },
                { 'metadata.company_id': item.company?.id }
              ])
              if (item.metadata.contacts) {
                item.metadata.contacts.forEach((contact, i) => {
                  setValue(`metadata.contacts[${i}].name`, contact.name)
                  setValue(`metadata.contacts[${i}].number`, contact.number)
                })
              }
            }
            if (store === serviceRequests) {
              setValue([
                {
                  'metadata.contract_reference': item.order_item?.order?.internal_code.split('#')[1]
                },
                { 'metadata.contract_reference_id': item.order_item?.order?.id },
                {
                  order_item:
                    item.order_item?.equipment?.name + ' (' + item.order_item?.internal_code + ')'
                },
                { 'metadata.company': item.order_item?.order?.company?.name },
                { 'metadata.company_id': item.order_item?.order?.company?.id }
              ])
              if (item.order_item.order.metadata.contacts) {
                item.order_item.order.metadata.contacts.forEach((contact, i) => {
                  setValue(`metadata.contacts[${i}].name`, contact.name)
                  setValue(`metadata.contacts[${i}].number`, contact.number)
                })
              }
              setSelectedItem(item.order_item?.id)
            }
          }
        })
      )
    } else {
      setValue([
        { address_line1: '' },
        { address_line2: '' },
        { address_zip: '' },
        { address_city: '' },
        { address_state: '' },
        { address_country: '' },
        {
          metadata: {
            contacts: []
          }
        }
      ])
    }
    const option = data
    if (!option) return undefined
    setValue(val, option?.value)

    return (option && option.label) || option
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex align="center" justify="space-between">
        <h1>{t('common:shared.information')}</h1>
        <Status
          defaultValue={current.get('status')}
          onChange={handleStatusChange}
          serviceRequestId={current.get('id')}
          designation={current.get('designation')}
        />
      </Flex>
      <Divider spacing={20} />
      <input type="hidden" name="designation" value="extra" ref={register} />
      <input type="hidden" name="metadata.company_id" ref={register} />
      <input type="hidden" name="metadata.supplier_id" ref={register} />
      <input type="hidden" name="metadata.contract_reference_id" ref={register} />
      <input type="hidden" name="metadata.service_call_reference_id" ref={register} />

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Controller
            as={OrderSelect}
            control={control}
            error={errors.metadata?.contract_reference?.message}
            rules={{
              required: {
                value: true,
                message: t('error:contractReference.required')
              }
            }}
            name="metadata.contract_reference"
            freeSolo
            required
            id="new-extra-contract-reference"
            defaultValue={
              current.getIn(['metadata', 'contract_reference']) ||
              current.getIn(['order', 'internal_code'])?.slice(3)
            }
            disabled={loading || update}
            onChange={(e) => handleAutoFillChange(e, 'metadata.contract_reference_id')}
            autoSelect={false}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={ServiceCallSelect}
            control={control}
            autoSelect={false}
            error={errors.metadata?.service_call_reference?.message}
            freeSolo
            name="metadata.service_call_reference"
            id="new-extra-serviceCallReference"
            defaultValue={current.getIn(['metadata', 'service_call_reference'])}
            disabled={loading || update}
            onChange={(e) => handleAutoFillChange(e, 'metadata.service_call_reference_id')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={CompanySelect}
            name="metadata.company"
            error={errors?.metadata?.company?.message}
            control={control}
            rules={{
              required: {
                value: true,
                message: t('error:company.required')
              }
            }}
            freeSolo
            required
            id="new-extra-company"
            autoSelect={false}
            defaultValue={current.getIn(['metadata', 'company'])}
            onChange={(e) => handleChange(e, 'metadata.company_id')}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={OrderActiveSelect}
            prop={contractReference}
            control={control}
            error={errors.order_item?.message}
            name="order_item"
            id="extra-order-item"
            freeSolo
            autoSelect={false}
            onChange={(e) => handleChange(e, 'order_item')}
            defaultValue={
              current.getIn(['order_item', 'id']) || current.get('order_item') || selectedItem
            }
            disabled={loading || update}
            data-cy="extra-order-item"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={SelectAdmin}
            control={control}
            error={errors.assignee?.message}
            rules={{
              required: {
                value: true,
                message: t('error:assignee.required')
              }
            }}
            required
            name="assignee"
            placeholder="Jane Doe"
            id="new-extra-assignee"
            defaultValue={current.getIn(['assignee', 'id'])}
            disabled={loading}
            data-cy="extra-assignee"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={SelectAdmin}
            control={control}
            error={errors.sales_rep?.message}
            name="sales_rep"
            label={t('common:shared.salesRep')}
            placeholder="John Doe"
            id="new-extra-sales-rep"
            defaultValue={current.getIn(['sales_rep', 'id'])}
            disabled={loading}
          />
        </Grid>
        {fields.map((i) => (
          <>
            <Grid item xs={12} md={6}>
              <Controller
                as={Input}
                name={`metadata.contacts[${i}].name`}
                control={control}
                type="text"
                label={t('order:shared.siteContact')}
                placeholder={t('order:new.siteContact.placeholder')}
                id="new-extra-contact-name"
                defaultValue={current.getIn(['metadata', 'contacts', i, 'name'])}
                disabled={loading}
                onBlur={() => {
                  onBlur(i)
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                as={Input}
                name={`metadata.contacts[${i}].number`}
                control={control}
                type="tel"
                label={t('common:shared.contactNumber')}
                id="new-extra-contact-number"
                defaultValue={current.getIn(['metadata', 'contacts', i, 'number'])}
                disabled={loading}
                onBlur={() => {
                  onBlur(i)
                }}
              />
            </Grid>
          </>
        ))}
        <Grid item xs={12} md={6}>
          <Controller
            as={SupplierSelect}
            name="metadata.supplier"
            id="new-extra-supplier"
            control={control}
            error={errors.supplier?.message}
            freeSolo
            autoSelect={false}
            defaultValue={current.getIn(['metadata', 'supplier'])}
            onChange={(e) => handleChange(e, 'metadata.supplier_id')}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            control={control}
            rules={{
              required: {
                value: true,
                message: t('error:occurrenceDate.required')
              }
            }}
            name="metadata.occurrence_date"
            error={errors.metadata?.occurrence_date?.message}
            defaultValue={current.getIn(['metadata', 'occurrence_date'])}
            type="datetime"
            label={t('serviceRequest:new.extra.occurrenceDate.label')}
            placeholder={t('serviceRequest:new.extra.occurrenceDate.placeholder')}
            id="new-extra-occurrenceDate"
            required
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            type="text"
            name="metadata.sent_invoice_number"
            control={control}
            error={errors.metadata?.sent_invoice_number?.message}
            label={t('serviceRequest:new.extra.sentInvoiceNumber.label')}
            placeholder={t('serviceRequest:new.extra.sentInvoiceNumber.placeholder')}
            id="new-extra-sent-invoice-number"
            defaultValue={current.getIn(['metadata', 'sent_invoice_number'])}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            type="number"
            name="metadata.invoices_sent"
            control={control}
            error={errors.metadata?.invoices_sent?.message}
            label={t('serviceRequest:new.extra.invoicesSent.label')}
            placeholder={t('serviceRequest:new.extra.invoicesSent.placeholder')}
            id="new-extra-invoices-sent"
            defaultValue={current.getIn(['metadata', 'invoices_sent'])}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            type="text"
            name="metadata.received_invoice_number"
            control={control}
            error={errors.metadata?.sent_invoice_number?.message}
            label={t('serviceRequest:new.extra.receivedInvoiceNumber.label')}
            placeholder={t('serviceRequest:new.extra.receivedInvoiceNumber.placeholder')}
            id="new-extra-received-invoice-number"
            defaultValue={current.getIn(['metadata', 'received_invoice_number'])}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            type="number"
            name="metadata.invoices_received"
            control={control}
            error={errors.metadata?.invoices_received?.message}
            label={t('serviceRequest:new.extra.invoicesReceived.label')}
            placeholder={t('serviceRequest:new.extra.invoicesReceived.placeholder')}
            id="new-extra-invoices-received"
            defaultValue={current.getIn(['metadata', 'invoices_received'])}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={PriceField}
            name="metadata.supplier_charge"
            control={control}
            error={errors.metadata?.daily_rate?.message}
            label={t('serviceRequest:new.extra.supplierCharge.label')}
            placeholder={t('serviceRequest:new.extra.supplierCharge.placeholder')}
            id="new-extra-supplier-charge"
            defaultValue={current.getIn(['metadata', 'supplier_charge'])}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={PriceField}
            name="metadata.rac_charge"
            control={control}
            error={errors.metadata?.daily_rate?.message}
            label={t('serviceRequest:new.extra.racCharge.label')}
            placeholder={t('serviceRequest:new.extra.racCharge.placeholder')}
            id="new-extra-rac-charge"
            defaultValue={current.getIn(['metadata', 'rac_charge'])}
            disabled={loading}
          />
        </Grid>
        <AddressFields
          control={control}
          streetError={errors.address_line1?.message}
          streetDetailError={errors.address_line2?.message}
          zipcodeError={errors.address_zip?.message}
          cityError={errors.address_city?.message}
          stateError={errors.address_state?.message}
          countryError={errors.address_country?.message}
          streetDefaultValue={current.get('address_line1')}
          streetDetailDefaultValue={current.get('address_line2')}
          zipcodeDefaultValue={current.get('address_zip')}
          cityDefaultValue={current.get('address_city')}
          stateDefaultValue={current.get('address_state')}
          countryDefaultValue={current.getIn(['address_country', 'code'])}
          loading={loading}
        />
        <Grid item xs={12} md={12}>
          <Controller
            as={Input}
            control={control}
            error={errors.notes?.message}
            name="notes"
            type="text"
            multiline
            required
            rules={{
              required: {
                value: true,
                message: t('error:damageExtra.required')
              }
            }}
            label={t('common:shared.damageExtra')}
            placeholder={t('serviceRequest:new.notes.placeholder')}
            id="new-extra-notes"
            defaultValue={current.get('notes')}
            disabled={loading}
          />
        </Grid>
        {isOperations && (
          <Grid item xs={12} md={12}>
            <Controller
              as={Input}
              control={control}
              error={errors.metadata?.opsNotes?.message}
              name="metadata.opsNotes"
              type="text"
              multiline
              label={t('serviceRequest:new.opsNotes.label')}
              placeholder={t('serviceRequest:new.opsNotes.placeholder')}
              id="new-extra-opsNotes"
              defaultValue={current.getIn(['metadata', 'opsNotes'])}
              disabled={loading}
              required
              rules={{
                required: {
                  value: true,
                  message: t('error:opsNote.required')
                }
              }}
              data-cy="new-extra-ops-notes"
            />
          </Grid>
        )}
      </Grid>
      <Divider spacing={20} />
      <Grid item xs={12} md={12}>
        <p style={{ fontWeight: 'bold' }}>**{t('serviceRequest:new.extra.photos')}</p>
      </Grid>
      <Divider spacing={20} />
      <Button type="submit" fullSize>
        {update ? t('serviceRequest:edit.submitExtra') : t('serviceRequest:new.submitExtra')}
      </Button>
    </form>
  )
}

Form.propTypes = {
  loading: PropTypes.bool,
  update: PropTypes.bool,
  current: PropTypes.instanceOf(Map)
}

Form.defaultProps = {
  loading: false,
  update: false,
  current: new Map()
}

export default Form
