import { useEffect, useState } from 'react'

function useAlgoliaSelectOptions(hits, value, keyLabel) {
  const [options, setOptions] = useState([])
  useEffect(() => {
    const opts = hits.map((object) => ({
      value: object.objectID,
      label:
        object[keyLabel].split('#')[0] === 'PO'
          ? object[keyLabel].split('#')[1]
          : object[keyLabel],
    }))
    setOptions(opts)
  }, [hits, value, keyLabel])
  return options
}

export default useAlgoliaSelectOptions
