import {
  SET_APPLICATION_THEME,
  SET_APPLICATION_LANGUAGE,
  SET_APPLICATION_OVERLAY_DISPLAY,
  SET_APPLICATION_OVERLAY_ACTION,
  SET_APPLICATION_OVERLAY_RESOURCE,
  SET_APPLICATION_HINTS,
  SET_APPLICATION_NOTIFICATION
} from 'store/actions/application'
import { createAction } from './factory'

export default {
  setTheme: createAction(SET_APPLICATION_THEME),
  setLanguage: createAction(SET_APPLICATION_LANGUAGE),
  setOverlayAction: createAction(SET_APPLICATION_OVERLAY_ACTION),
  setOverlayDisplay: createAction(SET_APPLICATION_OVERLAY_DISPLAY),
  setOverlayResource: createAction(SET_APPLICATION_OVERLAY_RESOURCE),
  setHints: createAction(SET_APPLICATION_HINTS),
  setNotification: createAction(SET_APPLICATION_NOTIFICATION),
}
