import React from 'react'
import { OrderContent, OrderHeader, OrderControls } from 'components/pages/orders'
import { withAlgolia } from 'components/hocs'
import {
  ORDER_INDEX,
  ORDER_CREATED_ASC_INDEX,
  ORDER_CREATED_DESC_INDEX,
  ORDER_STARTDATE_ASC_INDEX,
  ORDER_STARTDATE_DESC_INDEX,
  ORDER_DELIVERYDATE_ASC_INDEX,
  ORDER_DELIVERYDATE_DESC_INDEX,
  ORDER_ENDDATE_ASC_INDEX,
  ORDER_ENDDATE_DESC_INDEX,
  ORDER_BILLINGDATE_ASC_INDEX,
  ORDER_BILLINGDATE_DESC_INDEX
} from 'constants/algolia'
import { ORDER } from 'constants/resources'
import { useTranslation } from 'react-i18next'
import { Table } from 'components/common'
import { Link } from 'react-router-dom'
import routes from 'constants/routes'

function OrderTable() {
  const { t } = useTranslation('common')
  const columns = {
    sm: '1.5fr 2fr 2fr',
    md: '1fr 2fr 2fr 2fr 2fr 2fr 2fr',
    lg: '1fr 2fr 2fr 3fr 2fr 1.25fr 1.25fr 1.5fr 1.5fr',
    xl: '1fr 2fr 2fr 3fr 2fr 1.25fr 1.25fr 1.25fr 1.5fr 1.5fr'
  }

  return (
    <Table
      title={t('resources.orders.plural')}
      subtitle={
        <Link to={routes.orderItems}>
          <h1>{t('resources.orderItems.plural')}</h1>
        </Link>
      }
      controls={<OrderControls />}
      resource={ORDER}
      header={
        <OrderHeader
          columns={columns}
          defaultRefinement={ORDER_INDEX}
          items={[
            ORDER_CREATED_ASC_INDEX,
            ORDER_CREATED_DESC_INDEX,
            ORDER_STARTDATE_ASC_INDEX,
            ORDER_STARTDATE_DESC_INDEX,
            ORDER_DELIVERYDATE_ASC_INDEX,
            ORDER_DELIVERYDATE_DESC_INDEX,
            ORDER_ENDDATE_ASC_INDEX,
            ORDER_ENDDATE_DESC_INDEX,
            ORDER_BILLINGDATE_ASC_INDEX,
            ORDER_BILLINGDATE_DESC_INDEX
          ]}
        />
      }
      content={<OrderContent columns={columns} />}
    />
  )
}

export default withAlgolia(ORDER_INDEX)(OrderTable)
