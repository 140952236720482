import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Divider } from 'components/ui'
import { Input, Button } from 'components/common'
import { serviceRequestMessages } from 'store/action-creators'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { SERVICE_REQUEST } from 'constants/resources'

function MessageForm({ current, loading, update }) {
  const [file, setFile] = useState(null)
  const { t } = useTranslation(['common', SERVICE_REQUEST, 'error'])
  const dispatch = useDispatch()
  const {
    handleSubmit, control, errors, register,
  } = useForm({ mode: 'onBlur' })

  const onSubmit = (data) => {
    let formData
    if (file) {
      formData = new FormData()
      formData.append('attachment', file)
    }
    const body = {
      ...data,
      attachment: formData,
    }
    if (update) {
      dispatch(serviceRequestMessages.update(body))
    } else {
      dispatch(serviceRequestMessages.create(body))
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" name="service_request" ref={register} value={current.getIn(['service_request', 'id'])} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Controller
            as={Input}
            control={control}
            rules={{
              required: {
                value: true,
                message: t('error:content.required'),
              },
            }}
            error={errors.content?.message}
            name="content"
            type="text"
            multiline
            label={t('serviceRequest:shared.content')}
            placeholder={t('serviceRequest:new.content.placeholder')}
            id="new-service-request-content"
            defaultValue={current.get('content')}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Input
            type="file"
            max={15}
            label={t('serviceRequest:shared.attachment')}
            maxMessage={t('error:file.max')}
            helperText={t('common:file.helper', { max: 15 })}
            placeholder={t('common:file.placeholder')}
            dropPlaceholder={t('common:file.dropPlaceholder')}
            onChange={setFile}
            name="attachment"
            id="new-service-request-attachment"
          />
        </Grid>
      </Grid>
      <Divider spacing={20} />
      <Button
        type="submit"
        fullSize
      >
        {
          update ? t('serviceRequest:edit.postMessage') : t('serviceRequest:new.postMessage')
        }
      </Button>
    </form>
  )
}

MessageForm.propTypes = {
  loading: PropTypes.bool,
  update: PropTypes.bool,
  current: PropTypes.instanceOf(Map),
}

MessageForm.defaultProps = {
  loading: false,
  update: false,
  current: new Map(),
}

export default MessageForm
