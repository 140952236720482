import React from 'react'
import PropTypes from 'prop-types'
import { CollapsibleServiceRequestItemDetail } from 'components/pages/service-request-items'
import { SectionHeader } from 'components/common'
import { useTranslation } from 'react-i18next'

function ServiceRequestItems({ selected }) {
  const { t } = useTranslation('common')
  const service_request_items = selected.getIn(['service_request_items'])

  return (
    <div>
      <SectionHeader fullSize={true} title={t('common:resources.equipment.plural')} />
      {service_request_items.map((serviceRequestItem) => (
        <CollapsibleServiceRequestItemDetail key={serviceRequestItem.get('id')} selected={serviceRequestItem} />
      ))}
    </div>
  )
}

ServiceRequestItems.propTypes = {
  selected: PropTypes.string.isRequired,
}

ServiceRequestItems.defaultProps = {
  selected: ''
}

export default ServiceRequestItems
