import React from 'react'
import { SERVICE_REQUEST } from 'constants/resources'
import { Divider } from 'components/ui'
import { AddressSection, Destroy } from 'components/common'
import RentalPriceInformation from './RentalPriceInformation'
import UpdatesHistory from './UpdatesHistory'
import ServiceRequestItems from './ServiceRequestItems'

function RentalPriceDetail({
  selected,
  loading,
  messages,
}) {
  return (
    <>
      <RentalPriceInformation
        internalCode={selected.get('internal_code')}
        assigneeName={selected.getIn(['assignee', 'name'])}
        assigneeId={selected.getIn(['assignee', 'id'])}
        salesRepName={selected.getIn(['sales_rep', 'name'])}
        salesRepId={selected.getIn(['sales_rep', 'id'])}
        createdByName={selected.getIn(['created_by', 'name'])}
        createdById={selected.getIn(['created_by', 'id'])}
        notes={selected.get('notes')}
        createdAt={selected.get('created')}
        status={selected.get('status')}
        designation={selected.get('designation')}
        turnaroundTime={selected.get('turnaround_time')}
        loading={loading}
        duration={selected.getIn(['metadata', 'duration'])}
        deliveryDate={selected.getIn(['metadata', 'delivery_date'])}
        lastQuote={selected.getIn(['metadata', 'last_quote'])}
        quoteType={selected.getIn(['metadata', 'quote_type'])}
        currentSupplier={selected.getIn(['metadata', 'current_supplier'])}
        usedFor={selected.getIn(['metadata', 'used_for'])}
        // FIXME:
        companyName={selected.getIn(['metadata', 'company'])}
        companyId={selected.getIn(['metadata', 'company', 'id'])}
        opsNotes={selected.getIn(['metadata', 'opsNotes'])}
        opsAttachment={selected.get('attachment')}
      />
      <Divider spacing={20} />
      <AddressSection
        street={selected.get('address_line1')}
        streetDetails={selected.get('address_line2')}
        city={selected.get('address_city')}
        state={selected.get('address_state')}
        zipcode={selected.get('address_zip')}
        country={selected.getIn(['address_country', 'name'])}
        loading={loading}
      />
      <Divider spacing={20} />
      <ServiceRequestItems
        selected={selected}
      />
      <Divider spacing={20} />
      <UpdatesHistory
        messages={messages}
        statusHistory={selected.get('status_history')}
        stateHistory={selected.get('state_history')}
        loading={loading}
        serviceRequestId={selected.get('id')}
      />
      <Destroy resource={SERVICE_REQUEST} id={selected.get('id')} />
    </>
  )
}


export default RentalPriceDetail
