import React, { useEffect } from 'react'
import { useCurrent, useResource } from 'components/hooks'
import { SERVICE_REQUEST, SERVICE_REQUEST_MESSAGE } from 'constants/resources'
import {
  OFF_RENT,
  ORDER,
  PURCHASE_PRICE,
  RENTAL_PRICE,
  SERVICE_CALL,
  EXTRA
} from 'constants/designations'
import { TableDetail } from 'components/common'
import { Divider } from 'components/ui'
import { serviceRequestMessages } from 'store/action-creators'
import { useDispatch, useSelector } from 'react-redux'
import OffRentDetail from './detail/OffRentDetail'
import OrderDetail from './detail/OrderDetail'
import PurchasePriceDetail from './detail/PurchasePriceDetail'
import RentalPriceDetail from './detail/RentalPriceDetail'
import ServiceCallDetail from './detail/ServiceCallDetail'
import ExtraDetail from './detail/ExtraDetail'
import Header from './detail/Header'

function ServiceRequestDetail() {
  const dispatch = useDispatch()
  const selected = useCurrent(SERVICE_REQUEST)
  const messages = useSelector((state) => state.getIn([SERVICE_REQUEST_MESSAGE, 'list', 'data']))
  const loading = useResource(SERVICE_REQUEST, 'current', 'loading')
  const id = selected.get('id')
  useEffect(() => {
    if (id) {
      dispatch(
        serviceRequestMessages.getListReset({
          service_request: id,
          limit: 100
        })
      )
    }
  }, [dispatch, id])

  let content
  if (selected.get('designation') === OFF_RENT)
    content = <OffRentDetail messages={messages} selected={selected} loading={loading} />
  if (selected.get('designation') === ORDER)
    content = <OrderDetail messages={messages} selected={selected} loading={loading} />
  if (selected.get('designation') === PURCHASE_PRICE)
    content = <PurchasePriceDetail messages={messages} selected={selected} loading={loading} />
  if (selected.get('designation') === RENTAL_PRICE)
    content = <RentalPriceDetail messages={messages} selected={selected} loading={loading} />
  if (selected.get('designation') === SERVICE_CALL)
    content = <ServiceCallDetail messages={messages} selected={selected} loading={loading} />
  if (selected.get('designation') === EXTRA)
    content = <ExtraDetail messages={messages} selected={selected} loading={loading} />

  return (
    <TableDetail>
      <Header
        id={id}
        designation={selected.get('designation')}
        title={selected.get('internal_code')}
        status={selected.get('status')}
        loading={loading}
        serviceRequest={selected}
        isOrphan={!selected.getIn(['assignee', 'id'])}
      />
      <Divider spacing={28} />
      {content}
    </TableDetail>
  )
}

export default ServiceRequestDetail
