import React, { useEffect, useState, useMemo } from 'react'
import { Input } from 'components/common'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { SERVICE_REQUEST } from 'constants/resources'

function TransportSelect({ value, ...otherProps }) {
  const { t } = useTranslation([SERVICE_REQUEST, 'transportConventions'])

  const transportConventions = useMemo(() => {
    return [
      { label: t('transportConventions:deliveryCharge'), value: 'DELIVERY' },
      { label: t('transportConventions:pickupCharge'), value: 'PICKUP' },
      { label: t('transportConventions:displacementCharge'), value: 'DISPLACEMENT' },
      { label: t('transportConventions:freight'), value: 'FREIGHT' },
    ]
  }, [t])
  const [options, setOptions] = useState(transportConventions)
  useEffect(() => {
    if (value && !transportConventions.some(option => option.label.toUpperCase() === value.toUpperCase())) {
      const opts = [...transportConventions, { label: value, value: value }]
      setOptions(opts)
    }
  }, [value, transportConventions])
  const handleInputChange = (event, data) => {
    if (!data) {
      setOptions(transportConventions)
    }
    return data
  }

  return (
    <Input
      label={t('serviceRequest:pdf.transport')}
      placeholder={t('transportConventions:deliveryCharge')}
      type="select"
      value={value}
      onInputChange={handleInputChange}
      options={options}
      {...otherProps}
    />
  )
}

TransportSelect.propTypes = {
  id: PropTypes.string,
  value: PropTypes.any,
}

TransportSelect.defaultProps = {
  id: '',
}

export default TransportSelect
