import styled from 'styled-components'

export const OutlinedCard = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3.5px;
  margin: 5px;
  padding: 5px;
  width: 95%;
  paddingRight: 5%;
  background-color: ${({ theme }) => theme.color.primary};
`

export default OutlinedCard
