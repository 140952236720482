import styled from 'styled-components'

const Chip = styled.button`
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  height: 1.75em;
  background-color: ${({
    background, theme, outline, secondary,
  }) => (outline ? 'transparent' : (background || (secondary ? theme.color.primary : theme.color.secondary)))};
  color: ${({
    color, theme, outline, background, secondary,
  }) => (outline ? background || (secondary ? theme.color.primary100 : theme.color.secondary100) : (color || (secondary ? theme.color.secondary100 : theme.color.primary100)))};
  border-radius: 4px;
  padding: 0.3em 0.5em;
  text-transform: uppercase;
  font-weight: ${({ outline }) => (outline ? 400 : 500)};
  box-sizing: border-box;
  box-shadow: ${({
    background, theme, outline, secondary,
  }) => outline && `inset 0 0 1px 1px ${background || (secondary ? theme.color.primary : theme.color.secondary)}`};
  font-size: ${({ theme }) => theme.font.small.size}px;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ onClick, disabled }) => (onClick && !disabled) && 'pointer'};
  user-select: none; 
  > span {
    margin-right: 0.25em !important;
  }
  &:active {
    ${({ onClick, disabled }) => (onClick && !disabled) && 'transform: scale(0.96);'}; 
  }
`

export default Chip
