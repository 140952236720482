import api from 'config/axios'
import { buildQueryString } from 'utils'

export const list = async (filters) => api.get(`/app/service_requests/?${buildQueryString(filters)}`)

export const get = async (id) => api.get(`/app/service_requests/${id}/`)

export const create = async ({
  order_item,
  equipment,
  designation,
  metadata,
  resolve_by,
  comments,
  address_line1,
  address_line2,
  address_city,
  address_state,
  address_zip,
  address_country,
  assignee,
  notes,
  status,
  sales_rep
}) =>
  api.post('/app/service_requests/', {
    order_item,
    equipment,
    designation,
    metadata,
    resolve_by,
    comments,
    address_line1,
    address_line2,
    address_city,
    address_state,
    address_zip,
    address_country,
    assignee,
    notes,
    status,
    sales_rep
  })

export const update = async (
  id,
  {
    order_item,
    equipment,
    designation,
    metadata,
    resolve_by,
    comments,
    address_line1,
    address_line2,
    address_city,
    address_state,
    address_zip,
    address_country,
    assignee,
    notes,
    status,
    sales_rep,
    turnaround_time
  }
) =>
  api.patch(`/app/service_requests/${id}/`, {
    order_item,
    equipment,
    designation,
    metadata,
    resolve_by,
    comments,
    address_line1,
    address_line2,
    address_city,
    address_state,
    address_zip,
    address_country,
    assignee,
    notes,
    status,
    sales_rep,
    turnaround_time
  })

export const destroy = async (id) => api.delete(`/app/service_requests/${id}/`)

export const updateAttachment = async (id, body) => api.patch(`/app/service_requests/${id}/`, body)
