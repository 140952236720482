import React, { useEffect } from 'react'
import {
  Chip, Flex, Button, Input,
} from 'components/common'
import { useDispatch, useSelector } from 'react-redux'
import { Divider } from 'components/ui'
import { users } from 'store/action-creators'
import Grid from '@material-ui/core/Grid'
import { CompanySelect } from 'components/pages/companies'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { PHONE, EMAIL } from 'constants/patterns'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { USER } from 'constants/resources'
import { SUPERUSER } from 'constants/roles'

function Form({ current, loading, update }) {
  const role = useSelector(state => state.getIn(['session', 'user', 'role']))
  const { t } = useTranslation(['common', USER, 'error'])
  const dispatch = useDispatch()
  const {
    handleSubmit, control, errors, register, watch, getValues, setValue,
  } = useForm({ mode: 'onBlur' })

  useEffect(() => {
    if (typeof getValues('is_owner') === 'undefined') {
      register({ name: 'is_owner' })
      setValue('is_owner', current.get('is_owner'))
    }
  }, [current, getValues, register, setValue])

  const onSubmit = (body) => {
    if (update) {
      dispatch(users.update(body))
    } else {
      dispatch(users.create(body))
    }
  }

  const handleChipClick = () => {
    setValue('is_owner', !getValues('is_owner'))
  }

  const isAdmin = current.get('is_admin') || (watch('is_admin') === 'true')

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex align="center" justify="space-between">
        <h1>{t('common:shared.information')}</h1>
        <div>
          {
            current.get('is_admin') ? (
              <Chip label={t('user:shared.admin')} />
            ) : (
              <Chip
                onClick={handleChipClick}
                outline={!watch('is_owner')}
                label={t('common:shared.owner')}
              />
            )
          }
        </div>
      </Flex>
      <Divider spacing={20} />
      <input type="hidden" name="is_admin" ref={register} value={!!current.get('is_admin')} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            name="name"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('error:name.required'),
              },
            }}
            error={errors.name?.message}
            type="text"
            label={t('common:shared.name')}
            id="new-user-name"
            placeholder={t('user:new.name.placeholder')}
            defaultValue={current.get('name')}
            disabled={loading}
          />
        </Grid>
        {
          !current.get('is_admin') ? (
            <Grid item xs={12} md={6}>
              <Controller
                as={CompanySelect}
                name="company"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t('error:company.required'),
                  },
                }}
                error={errors.company?.message}
                id="new-user-company"
                defaultValue={current.getIn(['company', 'id'])}
                disabled={loading || !!current.getIn(['company', 'id'])}
              />
            </Grid>
          ) : null
        }
        {
          (isAdmin && role === SUPERUSER) ? (
            <Grid item xs={12} md={6}>
              <Controller
                as={Input}
                name="role"
                control={control}
                error={errors.role?.message}
                type="select"
                options={[
                  {
                    label: t('user:shared.operations'),
                    value: 'operations',
                  },
                  {
                    label: t('user:shared.sales'),
                    value: 'sales',
                  },
                ]}
                label={t('common:shared.role')}
                placeholder={t('user:new.role.placeholder')}
                id="new-user-role"
                defaultValue={current.get('role')}
                disabled={loading}
              />
            </Grid>
          ) : null
        }
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            name="email"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('error:email.required'),
              },
              pattern: {
                value: EMAIL,
                message: t('error:email.pattern'),
              },
            }}
            error={errors.email?.message}
            type="email"
            label={t('common:shared.email')}
            placeholder={t('user:new.email.placeholder')}
            id="new-user-email"
            defaultValue={current.get('email')}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            name="contact_number"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('error:phone.required'),
              },
              pattern: {
                value: PHONE,
                message: t('error:phone.pattern'),
              },
            }}
            error={errors.contact_number?.message}
            type="tel"
            label={t('common:shared.phone')}
            id="new-user-contact-number"
            defaultValue={current.get('contact_number')}
            disabled={loading}
          />
        </Grid>
      </Grid>
      <Divider spacing={20} />
      <Button
        type="submit"
        fullSize
      >
        {
          update ? t('user:edit.submit') : t('user:new.submit')
        }
      </Button>
    </form>
  )
}

Form.propTypes = {
  loading: PropTypes.bool,
  update: PropTypes.bool,
  current: PropTypes.instanceOf(Map),
}

Form.defaultProps = {
  loading: false,
  update: false,
  current: new Map(),
}

export default Form
