import React from 'react'
import PropTypes from 'prop-types'
import Icon from 'components/ui/Icon'
import ChipUI from 'components/ui/Chip'
import styled from 'styled-components'
import themes from 'config/themes'
import { useSelector } from 'react-redux'

const ChipStyled = styled.div`
  display: inline-block;
  margin-right: ${({ theme }) => theme.spacing.xsmall}px;
  &:last-child {
    margin: 0;
  }
`
function Chip({
  icon, iconColor, label, ...otherProps
}) {
  const theme = useSelector(state => state.getIn(['application', 'theme']))
  return (
    <ChipStyled>
      <ChipUI type="button" {...otherProps}>
        {icon ? <Icon name={icon} size={14} color={iconColor==='inherit' ? themes[theme].color.primary100 : "inherit"  }/> : null}
        {label.replace(/-/g, ' ')}
      </ChipUI>
    </ChipStyled>
  )
}

Chip.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  iconColor: PropTypes.string,
}

Chip.defaultProps = {
  icon: '',
  label: '',
  iconColor:'',
}

export default Chip
