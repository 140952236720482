import React from 'react'
import { useSelector } from 'react-redux'
import { RequestsRow } from 'components/pages/requests'
import { TableContent, TableLoading } from 'components/common'
import { SERVICE_REQUEST } from 'constants/resources'
import { SERVICE_REQUEST_INDEX } from 'constants/algolia'
import {
  useResource,
  useStatusColors,
  useSelectResource,
  useDeviceColumns,
  useStoreAlgolia,
  useFacetFilters,
  useFilters,
  useAlgoliaSearch
} from 'components/hooks'
import PropTypes from 'prop-types'
import { connectInfiniteHits, Configure } from 'react-instantsearch-dom'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import { useTranslation } from 'react-i18next'
import { serviceRequests } from 'store/action-creators'

function RequestsContent({ hits, hasMore, refineNext, hasPrevious, refinePrevious, columns }) {
  const { t } = useTranslation()
  const statusColors = useStatusColors(SERVICE_REQUEST)
  const selected = useResource(SERVICE_REQUEST, 'current')
  const selectedId = selected.get('id')
  const list = useStoreAlgolia(SERVICE_REQUEST, serviceRequests, hits)
  const loading = useResource(SERVICE_REQUEST, 'list', 'loading')
  const selectResource = useSelectResource(SERVICE_REQUEST)
  const templateColumns = useDeviceColumns(columns, !!selectedId)
  const facetFilters = useFacetFilters(SERVICE_REQUEST)
  const filters = useFilters(SERVICE_REQUEST)
  const pendingPayItems = useAlgoliaSearch(SERVICE_REQUEST_INDEX, {
    filters: 'status:pending-pay AND NOT designation:service-call AND is_redacted:false'
  })
  const showOverlayDisplay = useSelector((state) => state.getIn(['application', 'overlay', 'active']))
  const handleRowClick = (id) => {
    selectResource(id)
  }

  return (
    <>
      <TableContent next={refineNext} dataLength={hits.length} hasMore={hasMore}>
        <Configure
          hitsPerPage={40}
          filters={
            filters
              ? `designation:off-rent OR designation:order OR designation:rental-price OR designation:purchase-price AND ${filters}`
              : 'designation:off-rent OR designation:order OR designation:rental-price OR designation:purchase-price'
          }
          facetFilters={facetFilters}
        />
        <TableLoading loading={loading} />
        {list
          .filter((req) => !req.get('is_redacted'))
          .map((serviceRequest) => (
            <RequestsRow
              statusColor={statusColors[serviceRequest.get('status')]}
              key={serviceRequest.get('id')}
              id={serviceRequest.get('id')}
              shrink={!!selectedId}
              templateColumns={templateColumns}
              code={serviceRequest.get('code')}
              internalCode={serviceRequest.get('internal_code')}
              status={serviceRequest.get('status')}
              companyName={serviceRequest.getIn(['order_item', 'order', 'company', 'name']) || serviceRequest.getIn(['metadata', 'company'])}
              companyId={serviceRequest.getIn(['order_item', 'order', 'company', 'id']) || serviceRequest.getIn(['metadata', 'company_id'])}
              equipmentName={serviceRequest.getIn(['order_item', 'equipment', 'name']) || serviceRequest.getIn(['metadata', 'equipment'])}
              equipmentId={serviceRequest.getIn(['order_item', 'equipment', 'id']) || serviceRequest.getIn(['metadata', 'equipment', 'id'])}
              assigneeName={serviceRequest.getIn(['assignee', 'name'])}
              assigneeId={serviceRequest.getIn(['assignee', 'id'])}
              salesRepName={serviceRequest.getIn(['sales_rep', 'name'])}
              salesRepId={serviceRequest.getIn(['sales_rep', 'id'])}
              equipment={serviceRequest.getIn(['order_item', 'equipment'])}
              createdAt={serviceRequest.get('created')}
              createdByName={serviceRequest.getIn(['created_by', 'name'])}
              createdById={serviceRequest.getIn(['created_by', 'id'])}
              designation={serviceRequest.get('designation')}
              active={serviceRequest.get('id') === selectedId}
              onClick={() => handleRowClick(serviceRequest.get('id'))}
            />
          ))}
      </TableContent>
      {!showOverlayDisplay && !!pendingPayItems && (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          open
        >
          <Alert severity="warning">{`${t('serviceRequest:status.pending-pay')}: ${pendingPayItems} ${t(
            'common:resources.requests.plural'
          ).toLowerCase()}`}</Alert>
        </Snackbar>
      )}
    </>
  )
}

RequestsContent.propTypes = {
  columns: PropTypes.object.isRequired
}

export default connectInfiniteHits(RequestsContent)
