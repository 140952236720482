import React from 'react'
import { ORDER } from 'constants/resources'
import { NEW } from 'constants/crud'
import { orders } from 'store/action-creators'
import { useDispatch } from 'react-redux'
import { useQuickPreview } from 'components/hooks'
import { useTranslation } from 'react-i18next'
import { Chip } from 'components/common'
import PropTypes from 'prop-types'
import { Map } from 'immutable'

function OffRentButton({
  order,
}) {
  const { t } = useTranslation('common', ORDER)
  const [openPreview] = useQuickPreview()
  const dispatch = useDispatch()

  const handleChipClick = () => {
    openPreview(ORDER, NEW)
    dispatch(orders.setCreateData(order))
  }

  return (
    <Chip
      outline
      icon="content_copy"
      onClick={handleChipClick}
      label={t('order:shared.duplicate')}
    />
  )
}

OffRentButton.propTypes = {
  id: PropTypes.string,
  status: PropTypes.string,
  order: PropTypes.instanceOf(Map),
}

OffRentButton.defaultProps = {
  id: '',
  status: '',
  order: new Map(),
}


export default OffRentButton
