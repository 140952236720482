import React from 'react'
import { Chip } from 'components/common'
import { useDispatch } from 'react-redux'
import { users } from 'store/action-creators'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { NOTIFICATION } from 'constants/resources'

function DeleteUserButton({
  show,
  userId
}) {
  const dispatch = useDispatch()
  const { t } = useTranslation(NOTIFICATION)
  const canDelete = show
  if (!canDelete) return null

  const handleChipClick = () => {
    dispatch(users.delete(userId))
  }

  return (
    <Chip
      outline
      icon="add"
      onClick={handleChipClick}
      label={t('detail.deleteUser')}
    />
  )
}

DeleteUserButton.propTypes = {
  name: PropTypes.string,
  company: PropTypes.string,
  email: PropTypes.string,
  contactNumber: PropTypes.string,
  show: PropTypes.bool,
}

DeleteUserButton.defaultProps = {
  name: '',
  company: '',
  email: '',
  contactNumber: '',
  show: false,
}
export default DeleteUserButton
