import React, { useState } from 'react'
import {
  Input, AddressFields, Button, Flex,
} from 'components/common'
import { useDispatch } from 'react-redux'
import { Divider } from 'components/ui'
import { serviceRequests } from 'store/action-creators'
import { useTranslation } from 'react-i18next'
import SelectAdmin from 'components/pages/users/SelectAdmin'
import Grid from '@material-ui/core/Grid'
import { useForm, Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import { List, Map } from 'immutable'
import { SERVICE_REQUEST } from 'constants/resources'
import moment from 'moment'
import { DATETIME } from 'constants/formats'
import { useMultipleContacts } from 'components/hooks'
import Checkbox from 'components/common/Checkbox'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'

function BatchOffRentForm({ current, loading, items }) {
  const initialSelectedItems = {}
  items.forEach((item) => {
    initialSelectedItems[item.get('id')] = true
  })
  const { t } = useTranslation([SERVICE_REQUEST, 'error'])
  const [selectedItems, setSelectedItems] = useState(initialSelectedItems)
  const dispatch = useDispatch()
  const {
    handleSubmit, control, errors, register, getValues,
  } = useForm({ mode: 'onBlur' })
  const {
    fields, onBlur, cleanContacts,
  } = useMultipleContacts({
    initialCount: current.getIn(['metadata', 'contacts'])?.size || 1,
    getValues,
  })
  const onSubmit = (body) => {
    Object.keys(selectedItems).filter(orderItemId => selectedItems[orderItemId]).forEach((orderItem) => {
      dispatch(serviceRequests.create(cleanContacts({ ...body, order_item: orderItem })))
    })
  }

  const handleItemCheck = (value) => {
    const selectedClone = { ...selectedItems }
    selectedClone[value] = !selectedClone[value]
    setSelectedItems(selectedClone)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex align="center" justify="space-between">
        <h1>{t('common:shared.information')}</h1>
      </Flex>
      <Divider spacing={20} />
      <input type="hidden" name="designation" value="off-rent" ref={register} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend" style={{ fontSize: '0.75rem', color: '#d4d6d6', paddingLeft: 8 }}>Select itemized to include</FormLabel>
            <FormGroup>
              {
                items.map(item => (
                  <Checkbox
                    name="items"
                    checked={selectedItems[item.get('id')]}
                    onClick={() => { handleItemCheck(item.get('id')) }}
                    label={`${item.get('internal_code')} - ${item.getIn(['equipment', 'name'])}`}
                    id="new-order-checkbox"
                    disabled={loading}
                  />
                ))
              }
            </FormGroup>
          </FormControl>

        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            as={Input}
            name="resolve_by"
            defaultValue={current.get('resolve_by')}
            error={errors.resolve_by?.message}
            control={control}
            type="datetime"
            rules={{
              required: {
                value: true,
                message: t('error:date.required'),
              },
            }}
            label={t('serviceRequest:shared.resolvedBy')}
            placeholder={moment().add(9, 'd').format(DATETIME)}
            id="new-order-end-date"
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={SelectAdmin}
            control={control}
            error={errors.assignee?.message}
            name="assignee"
            placeholder="Jane Doe"
            id="new-service-request-assignee"
            defaultValue={current.getIn(['assignee', 'id'])}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={SelectAdmin}
            control={control}
            error={errors.sales_rep?.message}
            name="sales_rep"
            label={t('common:shared.salesRep')}
            placeholder="John Doe"
            id="new-service-request-sales-rep"
            defaultValue={current.getIn(['sales_rep', 'id'])}
            disabled={loading}
          />
        </Grid>
        {
          fields.map(i => (
            <>
              <Grid item xs={12} md={6}>
                <Controller
                  as={Input}
                  name={`metadata.contacts[${i}].name`}
                  control={control}
                  error={errors.metadata?.contacts[i]?.name?.message}
                  type="text"
                  label={t('order:shared.siteContact')}
                  placeholder={t('order:new.siteContact.placeholder')}
                  id="new-service-request-contact-name"
                  defaultValue={current.getIn(['metadata', 'contacts', i, 'name'])}
                  disabled={loading}
                  onBlur={() => { onBlur(i) }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  as={Input}
                  name={`metadata.contacts[${i}].number`}
                  control={control}
                  error={errors.metadata?.contacts[i]?.number?.message}
                  type="tel"
                  label={t('common:shared.contactNumber')}
                  id="new-service-request-contact-number"
                  defaultValue={current.getIn(['metadata', 'contacts', i, 'number'])}
                  disabled={loading}
                  onBlur={() => { onBlur(i) }}
                />
              </Grid>
            </>
          ))
        }
        <AddressFields
          control={control}
          streetError={errors.address_line1?.message}
          streetDetailError={errors.address_line2?.message}
          zipcodeError={errors.address_zip?.message}
          cityError={errors.address_city?.message}
          stateError={errors.address_state?.message}
          countryError={errors.address_country?.message}
          streetDefaultValue={current.get('address_line1')}
          streetDetailDefaultValue={current.get('address_line2')}
          zipcodeDefaultValue={current.get('address_zip')}
          cityDefaultValue={current.get('address_city')}
          stateDefaultValue={current.get('address_state')}
          countryDefaultValue={current.getIn(['address_country', 'code'])}
          loading={loading}
        />
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            control={control}
            error={errors.metadata?.site_hours?.message}
            name="metadata.site_hours"
            type="text"
            multiline
            label={t('serviceRequest:new.offRent.siteHours.label')}
            placeholder={t('serviceRequest:new.offRent.siteHours.placeholder')}
            id="new-service-request-siteHours"
            defaultValue={current.getIn(['metadata', 'site_hours'])}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            control={control}
            error={errors.metadata?.unit_location?.message}
            name="metadata.unit_location"
            type="text"
            multiline
            label={t('serviceRequest:new.offRent.unitLocation.label')}
            placeholder={t('serviceRequest:new.offRent.unitLocation.placeholder')}
            id="new-service-request-unitLocation"
            defaultValue={current.getIn(['metadata', 'unit_location'])}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            control={control}
            error={errors.metadata?.unit_number?.message}
            name="metadata.unit_number"
            type="text"
            label={t('serviceRequest:new.offRent.unitNumber.label')}
            placeholder={t('serviceRequest:new.offRent.unitNumber.placeholder')}
            id="new-service-request-unit-number"
            defaultValue={current.getIn(['metadata', 'unit_number'])}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={Input}
            control={control}
            error={errors.metadata?.pickup_by?.message}
            name="metadata.pickup_by"
            type="date"
            label={t('serviceRequest:new.offRent.pickupBy.label')}
            placeholder={t('serviceRequest:new.offRent.pickupBy.placeholder')}
            id="new-service-request-pickupBy"
            defaultValue={current.getIn(['metadata', 'pickup_by'])}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            as={Input}
            control={control}
            error={errors.notes?.message}
            name="notes"
            type="text"
            multiline
            required
            label={t('common:shared.notes')}
            placeholder={t('serviceRequest:new.notes.placeholder')}
            id="new-service-request-notes"
            defaultValue={current.get('notes')}
            disabled={loading}
          />
        </Grid>
      </Grid>
      <Divider spacing={20} />
      <Button
        type="submit"
        fullSize
      >
        { t('serviceRequest:new.submitRequest')}
      </Button>
    </form>
  )
}

BatchOffRentForm.propTypes = {
  loading: PropTypes.bool,
  items: PropTypes.instanceOf(List),
  current: PropTypes.instanceOf(Map),
}

BatchOffRentForm.defaultProps = {
  loading: false,
  items: new List(),
  current: new Map(),
}

export default BatchOffRentForm
