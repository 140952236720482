import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Copy, Label } from 'components/common'
import { useNotification } from 'components/hooks'
import { useTranslation } from 'react-i18next'

const DetailStyled = styled.div`
  p {
    word-break: break-word;
    white-space: pre-wrap;
  }
  .detail-body:empty {
    height: 1.7em;
    &:after {
      content: '-'
    }
  }
  .detail-placeholder {
    opacity: 0.3;
    font-style: italic;
  }
`

function Detail({
  label, loading, children, text, empty,
}) {
  const notify = useNotification()
  const { t } = useTranslation('common')
  const handleCopy = () => {
    notify({ text: t('copy.notification') })
  }

  if (loading) {
    return (
      <DetailStyled>
        <Label>{label}</Label>
        <p className="loading" />
      </DetailStyled>
    )
  }

  return (
    <DetailStyled>
      <Label>{label}</Label>
      <Copy text={text} onCopy={handleCopy}>
        <p className="detail-body">{children}</p>
      </Copy>
    </DetailStyled>
  )
}

Detail.propTypes = {
  label: PropTypes.string.isRequired,
  empty: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  loading: PropTypes.bool,
}

Detail.defaultProps = {
  loading: false,
  text: '',
  empty: '',
}

export default Detail
