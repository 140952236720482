import React from 'react'
import { DatePicker } from '@material-ui/pickers'
import PropTypes from 'prop-types'
import moment from 'moment'
import { DATE } from 'constants/formats'
import TextField from 'components/ui/TextField'

function DateField({
  onChange, value, type, ...otherProps
}) {
  const parsedDate = value ? moment(value) : null
  const handleDateChange = (date) => {
    if (onChange) {
      onChange(date && date.format('YYYY-MM-DD'))
    }
  }
  return (
    <DatePicker
      disableToolbar
      autoOk
      variant="dialog"
      clearable
      clearLabel="CLEAR"
      inputVariant="filled"
      format={DATE}
      value={parsedDate}
      placeholder={moment().add(7, 'd').format(DATE)}
      onChange={handleDateChange}
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
      type="text"
      TextFieldComponent={TextField}
      {...otherProps}
    />
  )
}

DateField.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  type: PropTypes.string,
}

DateField.defaultProps = {
  onChange: null,
  value: '',
}

export default DateField
