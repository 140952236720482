import React from 'react'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import {
  Input,
  CountrySelect,
  StateSelect,
  PostalCodeField,
} from 'components/common'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'

function AddressFields({
  loading,
  streetDefaultValue,
  streetDetailDefaultValue,
  zipcodeDefaultValue,
  cityDefaultValue,
  stateDefaultValue,
  countryDefaultValue,
  control,
  streetError,
  streetDetailError,
  zipcodeError,
  cityError,
  stateError,
  countryError,
  streetRequired,
  streetDetailRequired,
  zipcodeRequired,
  cityRequired,
  stateRequired,
  countryRequired,
}) {
  const { t } = useTranslation(['common', 'error'])
  return (
    <>
      <Grid item xs={12} md={6}>
        <Controller
          as={Input}
          name="address_line1"
          control={control}
          error={streetError}
          type="text"
          label={t('common:address.street.label')}
          placeholder={t('common:address.street.placeholder')}
          id="new-street"
          defaultValue={streetDefaultValue}
          disabled={loading}
          required={streetRequired}
          rules={{
            required: {
              value: streetRequired,
              message: t('error:street.required'),
            },
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          as={Input}
          name="address_line2"
          control={control}
          error={streetDetailError}
          type="text"
          label={t('common:address.streetDetail.label')}
          placeholder={t('common:address.streetDetail.placeholder')}
          id="new-street-details"
          defaultValue={streetDetailDefaultValue}
          disabled={loading}
          required={streetDetailRequired}
          rules={{
            required: {
              value: streetDetailRequired,
              message: t('error:streetDetail.required'),
            },
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          as={PostalCodeField}
          name="address_zip"
          control={control}
          rules={{
            pattern: {
              value: /[a-zA-Z0-9\s]{5,6}/,
              message: t('error:zipcode.pattern'),
            },
            required: {
              value: zipcodeRequired,
              message: t('error:zipcode.required'),
            },
          }}
          error={zipcodeError}
          defaultValue={zipcodeDefaultValue}
          disabled={loading}
          required={zipcodeRequired}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          as={Input}
          control={control}
          error={cityError}
          type="text"
          name="address_city"
          label={t('common:address.city.label')}
          placeholder={t('common:address.city.placeholder')}
          id="new-city"
          defaultValue={cityDefaultValue}
          disabled={loading}
          required={cityRequired}
          rules={{
            required: {
              value: cityRequired,
              message: t('error:city.required'),
            },
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          as={StateSelect}
          name="address_state"
          control={control}
          error={stateError}
          label={t('common:address.state.label')}
          placeholder={t('common:address.state.placeholder')}
          id="new-state"
          defaultValue={stateDefaultValue}
          disabled={loading}
          required={stateRequired}
          rules={{
            required: {
              value: stateRequired,
              message: t('error:state.required'),
            },
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          as={CountrySelect}
          name="address_country"
          control={control}
          error={countryError}
          label={t('common:address.country.label')}
          id="new-country"
          defaultValue={countryDefaultValue || 'CA'}
          disabled={loading}
          required={countryRequired}
          rules={{
            required: {
              value: countryRequired,
              message: t('error:country.required'),
            },
          }}
        />
      </Grid>

    </>
  )
}

AddressFields.propTypes = {
  loading: PropTypes.bool,
  streetDefaultValue: PropTypes.string,
  streetDetailDefaultValue: PropTypes.string,
  zipcodeDefaultValue: PropTypes.string,
  cityDefaultValue: PropTypes.string,
  stateDefaultValue: PropTypes.string,
  countryDefaultValue: PropTypes.string,
  streetRequired: PropTypes.bool,
  streetDetailRequired: PropTypes.bool,
  zipcodeRequired: PropTypes.bool,
  cityRequired: PropTypes.bool,
  stateRequired: PropTypes.bool,
  countryRequired: PropTypes.bool,
  onChange: PropTypes.func,
  control: PropTypes.any.isRequired,
  streetError: PropTypes.string,
  streetDetailError: PropTypes.string,
  zipcodeError: PropTypes.string,
  cityError: PropTypes.string,
  stateError: PropTypes.string,
  countryError: PropTypes.string,
}

AddressFields.defaultProps = {
  loading: false,
  streetDefaultValue: '',
  streetDetailDefaultValue: '',
  zipcodeDefaultValue: '',
  cityDefaultValue: '',
  stateDefaultValue: '',
  countryDefaultValue: '',
  streetRequired: false,
  streetDetailRequired: false,
  zipcodeRequired: false,
  cityRequired: false,
  stateRequired: false,
  countryRequired: false,
  streetError: '',
  streetDetailError: '',
  zipcodeError: '',
  cityError: '',
  stateError: '',
  countryError: '',
}

export default AddressFields
