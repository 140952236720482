import { useCallback, useState } from 'react'

export default function useMultipleContacts({
  initialCount = 1,
  getValues,
}) {
  const [count, setCount] = useState(initialCount)
  const fields = [...Array(count).keys()]
  const onBlur = useCallback((i) => {
    const values = getValues()

    if (values[`metadata.contacts[${i}].name`] && values[`metadata.contacts[${i}].number`]) {
      setCount(count + 1)
    }
  }, [count, getValues])

  const cleanContacts = useCallback(
    payload => ({
      ...payload,
      metadata: {
        ...payload.metadata,
        contacts: payload.metadata.contacts 
          ? payload.metadata.contacts.filter(contact => !!contact.name && !!contact.number)
          : {},
      },
    }),
    [],
  )
  return {
    count, setCount, onBlur, fields, cleanContacts,
  }
}
