import React from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  Detail, PreviewLink, Date,
  SectionHeader, Price,
} from 'components/common'
import {
  COMPANY, USER, SUPPLIER,
  SERVICE_REQUEST, ORDER_ITEM,
  ORDER, EQUIPMENT,
} from 'constants/resources'
import { useSelector } from 'react-redux'
import { OPERATIONS, SUPERUSER } from 'constants/roles'

function Information({
  action,
  title,
  internalCode,
  createdAt,
  contractReference,
  contractReferenceId,
  companyName,
  companyId,
  occurrenceDate,
  assigneeName,
  assigneeId,
  salesRepName,
  salesRepId,
  orderItemCode,
  orderItemId,
  equipmentName,
  equipmentId,
  supplier,
  supplierId,
  serviceCallReference,
  serviceCallReferenceId,
  sentInvoiceNumber,
  invoicesSent,
  receivedInvoiceNumber,
  invoicesReceived,
  supplierCharge,
  racCharge,
  notes,
  opsNotes,
  loading,
}) {
  const { t } = useTranslation(['common', SERVICE_REQUEST])
  const role = useSelector(state => state.getIn(['session', 'user', 'role']))
  const isOperations = [OPERATIONS, SUPERUSER].includes(role)

  return (
    <Grid container spacing={2}>
      {
        title ? <SectionHeader title={title}>{action}</SectionHeader> : null
      }
      <Grid item xs={12} md={6} data-cy="sr-extra-info-created-at">
        <Detail
          text={internalCode}
          loading={loading}
          label={t('common:shared.id')}
        >
          {internalCode}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="sr-extra-info-created-at">
        <Detail
          text={createdAt}
          loading={loading}
          label={t('common:shared.createdAt')}
        >
          <Date variant="datetime">
            {createdAt}
          </Date>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="sr-extra-info-contract-reference">
        <Detail
          text={contractReference}
          loading={loading}
          label={t('serviceRequest:new.extra.contractReference.label')}
        >
          <PreviewLink id={contractReferenceId} resource={ORDER}>
            {contractReference}
          </PreviewLink>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="sr-extra-info-service-call-reference">
        <Detail
          text={serviceCallReference}
          loading={loading}
          label={t('serviceRequest:new.extra.serviceCallReference.label')}
        >         
          <PreviewLink id={serviceCallReferenceId} resource={SERVICE_REQUEST}>
            {serviceCallReference}
          </PreviewLink>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="sr-extra-info-company">
        <Detail
          text={companyName}
          loading={loading}
          label={t('common:resources.companies.singular')}
        >
          <PreviewLink id={companyId} resource={COMPANY}>
            {companyName}
          </PreviewLink>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="sr-extra-info-supplier">
        <Detail
          text={supplier}
          loading={loading}
          label={t('common:resources.suppliers.singular')}
        >
          <PreviewLink id={supplierId} resource={SUPPLIER}>
            {supplier}
          </PreviewLink>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="sr-extra-info-occurrence-date">
        <Detail
          text={occurrenceDate}
          loading={loading}
          label={t('serviceRequest:new.extra.occurrenceDate.label')}
        >
          <Date variant="datetime">
            {occurrenceDate}
          </Date>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} />
      <Grid item xs={12} md={6} data-cy="sr-extra-info-equipment-name">
        <Detail
          text={equipmentName}
          loading={loading}
          label={t('common:resources.equipment.singular')}
        >
          <PreviewLink id={equipmentId} resource={EQUIPMENT}>
            {equipmentName}
          </PreviewLink>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="sr-extra-info-equipment-id">
        <Detail
          text={orderItemCode}
          loading={loading}
          label={t('common:resources.orderItems.singular')}
        >
          <PreviewLink id={orderItemId} resource={ORDER_ITEM}>
            {orderItemCode}
          </PreviewLink>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="sr-extra-info-handled-by">
        <Detail
          text={assigneeName}
          loading={loading}
          label={t('common:shared.handledBy')}
        >
          <PreviewLink id={assigneeId} resource={USER}>
            {assigneeName}
          </PreviewLink>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="sr-extra-info-sales-rep">
        <Detail
          text={salesRepName}
          loading={loading}
          label={t('shared.salesRep')}
          empty={t('company:shared.emptySalesRep')}
        >
          <PreviewLink resource={USER} id={salesRepId}>
            {salesRepName}
          </PreviewLink>
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="sr-extra-info-sent-invoice-number">
        <Detail
          text={sentInvoiceNumber}
          loading={loading}
          label={t('serviceRequest:new.extra.sentInvoiceNumber.label')}
        >
          {sentInvoiceNumber}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="sr-extra-info-invoices-sent">
        <Detail
          text={invoicesSent}
          loading={loading}
          label={t('serviceRequest:new.extra.invoicesSent.label')}
        >
          {invoicesSent}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="sr-extra-info-received-invoice-number">
        <Detail
          text={receivedInvoiceNumber}
          loading={loading}
          label={t('serviceRequest:new.extra.receivedInvoiceNumber.label')}
        >
          {receivedInvoiceNumber}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="sr-extra-info-invoices-received">
        <Detail
          text={invoicesReceived}
          loading={loading}
          label={t('serviceRequest:new.extra.invoicesReceived.label')}
        >
          {invoicesReceived}
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="sr-extra-info-supplier-charge">
        <Detail
          text={supplierCharge}
          loading={loading}
          label={t('serviceRequest:new.extra.supplierCharge.label')}
        >
          <Price amount={supplierCharge} />
        </Detail>
      </Grid>
      <Grid item xs={12} md={6} data-cy="sr-extra-info-rac-charge">
        <Detail
          text={racCharge}
          loading={loading}
          label={t('serviceRequest:new.extra.racCharge.label')}
        >
          <Price amount={racCharge} />
        </Detail>
      </Grid>
      <Grid item xs={12} md={12} data-cy="sr-extra-info-notes">
        <Detail
          text={notes}
          loading={loading}
          label={t('common:shared.damageExtra')}
        >
          {notes}
        </Detail>
      </Grid>
      {
        isOperations ? (
          <Grid item xs={12} md={12} data-cy="sr-extra-info-opsNotes">
            <Detail
              text={opsNotes}
              loading={loading}
              label={t('serviceRequest:new.opsNotes.label')}
            >
              {opsNotes}
            </Detail>
          </Grid>
        ) : null
      }
    </Grid>
  )
}

Information.propTypes = {
  action: PropTypes.node,
  title: PropTypes.string,
  createdAt: PropTypes.string,
  contractReference: PropTypes.string,
  contractReferenceId: PropTypes.string,
  companyName: PropTypes.string,
  companyId: PropTypes.string,
  occurrenceDate: PropTypes.string,
  assigneeName: PropTypes.string,
  assigneeId: PropTypes.string,
  salesRepName: PropTypes.string,
  salesRepId: PropTypes.string,
  orderItemCode: PropTypes.string,
  orderItemId: PropTypes.string,
  equipmentName: PropTypes.string,
  equipmentId: PropTypes.string,
  supplier: PropTypes.string,
  supplierId: PropTypes.string,
  serviceCallReference: PropTypes.string,
  serviceCallReferenceId: PropTypes.string,
  sentInvoiceNumber: PropTypes.string,
  invoicesSent: PropTypes.string,
  receivedInvoiceNumber: PropTypes.string,
  invoicesReceived: PropTypes.string,
  supplierCharge: PropTypes.string,
  racCharge: PropTypes.string,
  notes: PropTypes.string,
  opsNotes: PropTypes.string,
  loading: PropTypes.bool,
}

Information.defaultProps = {
  action: null,
  title: '',
  internalCode: '',
  createdAt: '',
  contractReference: '',
  contractReferenceId: '',
  companyName: '',
  companyId: '',
  occurrenceDate: '',
  assigneeName: '',
  assigneeId: '',
  salesRepName: '',
  salesRepId: '',
  orderItemCode: '',
  orderItemId: '',
  equipmentName: '',
  equipmentId: '',
  supplierName: '',
  supplierId: '',
  serviceCallReference: '',
  serviceCallReferenceId: '',
  sentInvoiceNumber: '',
  invoicesSent: '',
  receivedInvoiceNumber: '',
  invoicesReceived: '',
  supplierCharge: '',
  racCharge: '',
  notes: '',
  opsNotes: '',
  loading: false,
}

export default Information
