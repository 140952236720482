import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const ChipToggleContainer = styled.div`
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  height: 1.75em;
  border-radius: 4px;
  padding: 0.3em 0em;
  text-transform: uppercase;
  box-sizing: border-box;
  box-shadow: ${({
    background, theme, secondary,
  }) => `inset 0 0 1px 1px ${background || (secondary ? theme.color.primary : theme.color.secondary)}`};
  font-size: 10px;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  user-select: none; 
  > span {
    margin-right: 0.25em;
  }
`
 const ChipToggleElement = styled.button`
  display: block;
  width: 100%;
  text-align: center;
  outline: none;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 5px 10px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  background: ${({
    background, theme, outline, secondary,
  }) => `none repeat scroll 0% 0% ${!outline ? 'transparent' : (background || (secondary ? theme.color.primary : theme.color.secondary))}`};
  color: ${({
    color, theme, outline, background, secondary,
  }) => (!outline ? background || (secondary ? theme.color.primary100 : theme.color.secondary100) : (color || (secondary ? theme.color.secondary100 : theme.color.primary100)))};
  font-weight: ${({ outline }) => (outline ? 400 : 500)};
  &:active {
    ${({ onClick, disabled }) => (onClick && !disabled) && 'transform: scale(0.96);'}; 
  }
  cursor: ${({ onClick, disabled }) => (onClick && !disabled) && 'pointer'};
  user-select: none; 
`

function QuoteLangToggle ({ lang, setLang }) {
  return (
    <ChipToggleContainer>
      <ChipToggleElement outline={lang === 'en'} onClick={() => setLang('en')}>EN</ChipToggleElement>
      <ChipToggleElement outline={lang === 'fr'} onClick={() => setLang('fr')}>FR</ChipToggleElement>
    </ChipToggleContainer>
  )
}

QuoteLangToggle.propTypes = {
  lang: PropTypes.string,
  setLang: PropTypes.any
}

QuoteLangToggle.defaultProps = {
  lang: 'en',
  setLang: null
}

export default QuoteLangToggle
