import {
  takeLatest,
  call,
  select,
  put,
} from 'redux-saga/effects'
import { SERVICE_REQUEST_MESSAGE } from 'constants/resources'
import {
  LIST,
  GET,
  CREATE,
  UPDATE,
  DELETE,
  LIST_RESET,
} from 'store/actions/factory'
import {
  create as createService,
  update as updateService,
  updateAttachment as updateAttachmentService,
  createQuote as createQuoteService
} from 'services/service-request-messages'
import { EDIT, NEW } from 'constants/crud'
import { serviceRequestMessages } from 'store/action-creators'
import { SERVICE_REQUEST_MESSAGE_CREATE_QUOTE } from 'store/actions/serviceRequestMessages'
import notify from 'utils/notify'
import i18n from 'config/i18n'
import {
  list, get, destroy, resetList,
} from './factory'
import nprogress from './nprogress'
import { closePreview } from './preview'

const RESOURCE_QUOTE = 'Quote'

function* create({ payload }) {
  yield call(nprogress, true)
  const { body, onSuccess, onError } = payload
  const { attachment } = body
  yield put(serviceRequestMessages.setCreateLoading(true))
  const response = yield call(createService, body)
  if (response) {
    notify({
      text: i18n.t('common:shared.successCreate', { resource: response.name }),
    })
    yield put(serviceRequestMessages.prependListItem(response))
    yield call(closePreview, SERVICE_REQUEST_MESSAGE, NEW)
    yield put(serviceRequestMessages.setCurrentData(response))
    if (attachment) {
      const attachmentResponse = yield call(updateAttachmentService, response.id, attachment)
      yield put(serviceRequestMessages.setCurrentData(attachmentResponse))
      yield put(serviceRequestMessages.updateListItem(attachmentResponse))
    }
    if (onSuccess) yield call(onSuccess, response)
  } else if (onError) yield call(onError)

  yield call(nprogress, false)
  yield put(serviceRequestMessages.setCreateLoading(false))
}

function* update({ payload }) {
  yield call(nprogress, true)
  const { body, onSuccess, onError } = payload
  const { attachment } = body
  yield put(serviceRequestMessages.setUpdateLoading(true))
  const id = yield select(store => store.getIn([SERVICE_REQUEST_MESSAGE, 'current', 'id']))
  const response = yield call(updateService, id, body)
  let attachmentResponse = null
  if (attachment) {
    attachmentResponse = yield call(updateAttachmentService, id, attachment)
  }
  if (response) {
    notify({
      text: i18n.t('common:shared.successUpdate', { resource: response.name }),
    })
    yield put(serviceRequestMessages.updateListItem(response))
    yield call(closePreview, SERVICE_REQUEST_MESSAGE, EDIT)
    yield put(serviceRequestMessages.setCurrentData(response))
    if (onSuccess) yield call(onSuccess, response)
    yield call(nprogress, false)
  } else if (onError) yield call(onError)
  if (attachmentResponse) {
    notify({
      text: i18n.t('error:attachment.delay'),
    })
    yield put(serviceRequestMessages.setCurrentData(attachmentResponse))
  } else if (onError) yield call(onError)

  yield call(nprogress, false)
  yield put(serviceRequestMessages.setUpdateLoading(false))
}

function* createQuote({ payload }) {
  yield call(nprogress, true)
  const { body, onSuccess, onError } = payload
  const { lang, data } = body
  yield put(serviceRequestMessages.setCreateLoading(true))
  const response = yield call(createQuoteService, lang, data)
  if (response) {
    notify({
      text: i18n.t('common:shared.successCreate', { resource: RESOURCE_QUOTE }),
    })
    yield put(serviceRequestMessages.prependListItem({
      ...response
    }))
    yield call(closePreview, SERVICE_REQUEST_MESSAGE, NEW)
    yield put(serviceRequestMessages.setCurrentData({
      ...response
    }))
    if (onSuccess) {
      yield call(onSuccess, response)
    }
  } else if (onError) yield call(onError)

  yield call(nprogress, false)
  yield put(serviceRequestMessages.setCreateLoading(false))
}

export default function* serviceRequestMessageSaga() {
  yield takeLatest(LIST(SERVICE_REQUEST_MESSAGE), list)
  yield takeLatest(LIST_RESET(SERVICE_REQUEST_MESSAGE), resetList)
  yield takeLatest(GET(SERVICE_REQUEST_MESSAGE), get)
  yield takeLatest(CREATE(SERVICE_REQUEST_MESSAGE), create)
  yield takeLatest(UPDATE(SERVICE_REQUEST_MESSAGE), update)
  yield takeLatest(DELETE(SERVICE_REQUEST_MESSAGE), destroy)
  yield takeLatest(SERVICE_REQUEST_MESSAGE_CREATE_QUOTE, createQuote)
}
