import axios from 'axios'
import { API_URL } from 'constants/api'
import setInterceptors from './interceptors'


const api = axios.create({
  baseURL: API_URL,
  timeout: 8000,
  headers: { 'Content-Type': 'application/json' },
})

setInterceptors(api)

export default api
