import {
  COMPANY,
  CATEGORY,
  EQUIPMENT,
  NOTIFICATION,
  ORDER_ITEM,
  ORDER,
  RESERVATION,
  SERVICE_REQUEST,
  SUPPLIER,
  SUPPLIER_REVIEW,
  USER,
  RESERVATION_ITEM,
  ORDER_SERVICE,
  SERVICE_REQUEST_MESSAGE,
  SERVICE_REQUEST_ITEM,
  SUPPLIER_RATE
} from 'constants/resources'
import * as categories from './categories'
import * as companies from './companies'
import * as equipment from './equipment'
import * as notifications from './notifications'
import * as orderItems from './order-items'
import * as orders from './orders'
import * as orderServices from './order-services'
import * as reservations from './reservations'
import * as reservationItems from './reservation-items'
import * as serviceRequests from './service-requests'
import * as serviceRequestMessages from './service-request-messages'
import * as sessions from './sessions'
import * as supplierReviews from './supplier-reviews'
import * as suppliers from './suppliers'
import * as supplierRate from './supplier-rate'
import * as users from './users'
import * as serviceRequestItems from './service-request-items'

const services = {}
services[COMPANY] = companies
services[CATEGORY] = categories
services[EQUIPMENT] = equipment
services[NOTIFICATION] = notifications
services[ORDER_ITEM] = orderItems
services[ORDER_SERVICE] = orderServices
services[ORDER] = orders
services[RESERVATION] = reservations
services[RESERVATION_ITEM] = reservationItems
services[SERVICE_REQUEST] = serviceRequests
services[SERVICE_REQUEST_MESSAGE] = serviceRequestMessages
services[SERVICE_REQUEST_ITEM] = serviceRequestItems
services[SUPPLIER] = suppliers
services[SUPPLIER_RATE] = supplierRate
services[SUPPLIER_REVIEW] = supplierReviews
services[USER] = users

export {
  categories,
  companies,
  equipment,
  notifications,
  orderItems,
  orderServices,
  orders,
  reservations,
  reservationItems,
  serviceRequests,
  serviceRequestMessages,
  serviceRequestItems,
  sessions,
  supplierReviews,
  suppliers,
  supplierRate,
  users,
}

export default services
