import { MainLayout } from 'components/layouts'
import Routes from 'Routes'
import React from 'react'

function Component() {
  return (
    <MainLayout>
      <Routes />
    </MainLayout>
  )
}

export default Component
