import { combineReducers } from 'redux-immutable'
import { connectRouter } from 'connected-react-router/immutable'
import {
  COMPANY,
  CATEGORY,
  EQUIPMENT,
  NOTIFICATION,
  ORDER_ITEM,
  ORDER,
  RESERVATION,
  SERVICE_REQUEST,
  SUPPLIER,
  SUPPLIER_REVIEW,
  USER,
  ORDER_SERVICE,
  SERVICE_REQUEST_MESSAGE,
  SERVICE_REQUEST_ITEM,
  SUPPLIER_RATE,
} from 'constants/resources'
import session from './sessions'
import application from './application'
import createReducerWithResource from './factory'

const createRootReducer = (history) => {
  const reducers = {}
  reducers.application = application
  reducers.session = session
  reducers.router = connectRouter(history)
  reducers[COMPANY] = createReducerWithResource(COMPANY)
  reducers[CATEGORY] = createReducerWithResource(CATEGORY)
  reducers[EQUIPMENT] = createReducerWithResource(EQUIPMENT)
  reducers[NOTIFICATION] = createReducerWithResource(NOTIFICATION)
  reducers[ORDER] = createReducerWithResource(ORDER)
  reducers[ORDER_ITEM] = createReducerWithResource(ORDER_ITEM)
  reducers[ORDER_SERVICE] = createReducerWithResource(ORDER_SERVICE)
  reducers[RESERVATION] = createReducerWithResource(RESERVATION)
  reducers[SERVICE_REQUEST] = createReducerWithResource(SERVICE_REQUEST)
  reducers[SERVICE_REQUEST_ITEM] = createReducerWithResource(SERVICE_REQUEST_ITEM)
  reducers[SERVICE_REQUEST_MESSAGE] = createReducerWithResource(SERVICE_REQUEST_MESSAGE)
  reducers[SUPPLIER] = createReducerWithResource(SUPPLIER)
  reducers[SUPPLIER_RATE] = createReducerWithResource(SUPPLIER_RATE)
  reducers[SUPPLIER_REVIEW] = createReducerWithResource(SUPPLIER_REVIEW)
  reducers[USER] = createReducerWithResource(USER)
  return combineReducers(reducers)
}

export default createRootReducer
