import React, { useMemo } from 'react'
import { Chip, InlineTable, TableRow, TableHeader, PreviewLink, Price } from 'components/common'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { list as listOrderItems } from 'services/order-items'
import { COMPANY, ORDER_ITEM, SUPPLIER } from 'constants/resources'
import { usePaginatedList, useStatusColors } from 'components/hooks'
import { Grid } from '@material-ui/core'

function OrderList({ equipmentId }) {
  const { t } = useTranslation([COMPANY, 'common', 'orderItem'])
  const statusColors = useStatusColors(ORDER_ITEM)
  const templateColumns = '1fr 1fr 2fr'
  const templateColumnsRates = '2fr 1fr 1fr 1fr'
  const options = useMemo(
    () =>
      equipmentId
        ? {
            equipment: equipmentId,
            limit: 5
          }
        : null,
    [equipmentId]
  )
  const { list, hasMore, hasPrevious, refineNext, refinePrevious, loading, count } = usePaginatedList(listOrderItems, options)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <InlineTable
          title={`${t('company:orderList.header')} (${count})`}
          header={
            <TableHeader fullSize templateColumns={templateColumns}>
              <p>{t('common:shared.code')}</p>
              <p>{t('common:shared.status')}</p>
              <p>{t('common:resources.suppliers.singular')}</p>
            </TableHeader>
          }
          onNext={refineNext}
          onPrevious={refinePrevious}
          hasNext={hasMore}
          hasPrevious={hasPrevious}
          loading={loading}
        >
          {list.map((item) => (
            <TableRow key={item.get('id')} fullSize templateColumns={templateColumns}>
              <PreviewLink resource={ORDER_ITEM} id={item.get('id')}>
                {item.get('internal_code')}
              </PreviewLink>
              <div>
                <Chip background={statusColors[item.get('status')]} label={t(`orderItem:status.${item.get('status')}`)} />
              </div>
              <PreviewLink resource={SUPPLIER} id={item.getIn(['selected_supplier', 'supplier', 'id'])}>
                <span>{item.getIn(['selected_supplier', 'supplier', 'name'])}</span>
              </PreviewLink>
            </TableRow>
          ))}
        </InlineTable>
      </Grid>
      <Grid item xs={12} md={12}>
        <InlineTable
          title={`${t('orderItem:shared.pastRates')} (${count})`}
          header={
            <TableHeader fullSize templateColumns={templateColumnsRates}>
              <p>{t('common:resources.suppliers.singular')}</p>
              <p>{t('common:shared.dailyRate')}</p>
              <p>{t('common:shared.weeklyRate')}</p>
              <p>{t('common:shared.monthlyRate')}</p>
            </TableHeader>
          }
          onNext={refineNext}
          onPrevious={refinePrevious}
          hasNext={hasMore}
          hasPrevious={hasPrevious}
          loading={loading}
        >
          {list.map(
            (item) =>
              item.get('selected_supplier') && (
                <TableRow key={item.get('id')} fullSize templateColumns={templateColumnsRates}>
                  <PreviewLink resource={SUPPLIER} id={item.getIn(['supplier', 'id'])}>
                    {item.getIn(['selected_supplier', 'supplier', 'name'])}
                  </PreviewLink>
                  <Price amount={item.getIn(['selected_supplier', 'daily_rate'])} />
                  <Price amount={item.getIn(['selected_supplier', 'weekly_rate'])} />
                  <Price amount={item.getIn(['selected_supplier', 'monthly_rate'])} />
                </TableRow>
              )
          )}
        </InlineTable>
      </Grid>
    </Grid>
  )
}

OrderList.propTypes = {
  equipmentId: PropTypes.string
}

OrderList.defaultProps = {
  equipmentId: ''
}

export default OrderList
