import React from 'react'
import { SUPPLIER } from 'constants/resources'
import { SUPPLIER_INDEX } from 'constants/algolia'
import { withAlgolia } from 'components/hocs'
import { connectHits } from 'react-instantsearch-dom'
import { AutoCompleteSelect } from 'components/common'

function SupplierSelect({ hits, value, ...otherProps }) {
  return (
    <AutoCompleteSelect
      hits={hits}
      value={value}
      attributesToRetrieve={['objectID', 'name']}
      objectName={SUPPLIER}
      keyLabel="name"
      {...otherProps}
    />
  )
}

export default withAlgolia(SUPPLIER_INDEX)(connectHits(SupplierSelect))
