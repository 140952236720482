import { takeLatest, call, put } from 'redux-saga/effects'
import { NEW } from 'constants/crud'
import { ORDER } from 'constants/resources'
import { LIST, GET, CREATE, UPDATE, DELETE, GET_STORE } from 'store/actions/factory'
import { create as factoryCreate, list, get, update, destroy } from './factory'
import { notify } from 'utils'
import i18n from 'config/i18n'
import { orders } from 'store/action-creators'
import { create as createService } from 'services/orders'
import { create as createItemService } from 'services/order-items'
import { create as createSupplierRateService } from 'services/supplier-rate'
import { closePreview } from './preview'
import nprogress from './nprogress'
import { getStore } from './storeFactory'

function* create({ payload }) {
  const { body } = payload
  if (body?.isRequestTransfer) {
    yield requestTransfer({ payload })
  } else {
    yield factoryCreate({ payload, resource: ORDER })
  }
}

function* requestTransfer({ payload }) {
  yield call(nprogress, true)
  const { body, onSuccess, onError } = payload
  yield put(orders.setCreateLoading(true))
  const response = yield call(createService, body)
  let transferSuccess = true
  let responseWithItems = response
  if (response) {
    for (let i = 0; i < body?.items.length; i++) {
      const data = body.items[i]
      const itemBody = {
        service_request: undefined,
        order: response.id,
        equipment: data.equipment.id,
        quantity: data.quantity,
        daily_rate: data.daily_rate,
        weekly_rate: data.weekly_rate,
        monthly_rate: data.monthly_rate,
        rental_protection: data.rental_protection,
        environmental_fee: data.environmental_fee,
        transport: data.transport,
        metadata: data.metadata
      }
      const responseItem = yield call(createItemService, itemBody)
      responseWithItems.items.push(responseItem)
      if (!responseItem) {
        transferSuccess = false
      } else {
        const selectedSupplier = body.items[i].supplier_rates.find((rate) => rate.is_selected)
        if (selectedSupplier) {
          const supplierRateBody = {
            order_item: responseItem.id,
            supplier: selectedSupplier.supplier.id,
            daily_rate: selectedSupplier.daily_rate,
            weekly_rate: selectedSupplier.weekly_rate,
            monthly_rate: selectedSupplier.monthly_rate,
            transport: selectedSupplier.transport,
            notes: selectedSupplier.notes,
            metadata: selectedSupplier.metadata,
            is_selected: selectedSupplier.is_selected,
            is_selected_transport: selectedSupplier.is_selected_transport
          }
          const responseSupplier = yield call(createSupplierRateService, supplierRateBody)
          responseWithItems.items[i].selected_supplier = responseSupplier
          if (!responseSupplier) {
            transferSuccess = false
          }
          const selectedTransportSupplier = body.items[i].supplier_rates.find((rate) => rate.is_selected_transport)
          if (selectedTransportSupplier) {
            const supplierTransportRateBody = {
              order_item: responseItem.id,
              supplier: selectedTransportSupplier.supplier.id,
              daily_rate: selectedTransportSupplier.daily_rate,
              weekly_rate: selectedTransportSupplier.weekly_rate,
              monthly_rate: selectedTransportSupplier.monthly_rate,
              transport: selectedTransportSupplier.transport,
              notes: selectedTransportSupplier.notes,
              metadata: selectedTransportSupplier.metadata,
              is_selected: selectedTransportSupplier.is_selected,
              is_selected_transport: selectedTransportSupplier.is_selected_transport
            }
            const responseTransportSupplier = yield call(createSupplierRateService, supplierTransportRateBody)
            responseWithItems.items[i].selected_transport_supplier = responseTransportSupplier
            if (!responseTransportSupplier) {
              transferSuccess = false
            }
          }
        }
      }
    }
    if (response && transferSuccess) {
      yield put(orders.prependListItem(response))
      yield put(orders.setCurrentData(responseWithItems))
      yield put(orders.setCurrentId(response.id))
      notify({
        text: i18n.t('common:shared.successCreate', { resource: response.internal_code })
      })
      yield call(closePreview, ORDER, NEW)
      if (onSuccess) {
        yield call(onSuccess, response)
      }
      yield call(nprogress, false)
      yield put(orders.setCreateLoading(false))
    } else if (onError) yield call(onError)
  }
}

export default function* orderSaga() {
  yield takeLatest(LIST(ORDER), list)
  yield takeLatest(GET(ORDER), get)
  yield takeLatest(GET_STORE(ORDER), getStore)
  yield takeLatest(CREATE(ORDER), create)
  yield takeLatest(UPDATE(ORDER), update)
  yield takeLatest(DELETE(ORDER), destroy)
}
