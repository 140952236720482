import api from 'config/axios'
import { buildQueryString } from 'utils'

export const list = async (filters) => api.get(`/app/orders/items/?${buildQueryString(filters)}`)

export const get = async (id) => api.get(`/app/orders/items/${id}/`)

export const create = async (orderItemList) => api.post('/app/orders/items/', orderItemList)

export const update = async (
  id,
  {
    status,
    order,
    equipment,
    daily_rate,
    weekly_rate,
    monthly_rate,
    rental_protection,
    environmental_fee,
    transport,
    metadata,
    invoices_sent,
    invoices_received,
    off_rented_at,
    quantity
  }
) =>
  api.patch(`/app/orders/items/${id}/`, {
    status,
    order,
    equipment,
    daily_rate,
    weekly_rate,
    monthly_rate,
    rental_protection,
    environmental_fee,
    transport,
    metadata,
    invoices_sent,
    invoices_received,
    off_rented_at,
    quantity
  })

export const destroy = async (id) => api.delete(`/app/orders/items/${id}/`)
