
import React, { useState, useEffect } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import PropTypes from 'prop-types'
import TextField from 'components/ui/TextField'

function SelectField({
  options,
  multiple,
  name,
  id,
  value,
  defaultValue,
  onChange,
  onInputChange,
  forwardRef,
  disabled,
  freeSolo,
  loading,
  type,
  autoComplete,
  clearOnEscape,
  clearOnBlur,
  autoSelect,
  ...otherProps
}) {
  const [option, setOption] = useState(multiple ? [] : { label: '', value: '' })
  const [defaultOption, setDefaultOption] = useState(null)
  // const [defaultInputValue] = useState(value)

  useEffect(() => {
    let selected = null
    if (multiple && value) {
      selected = options.filter(opt => value.includes(opt.value))
    } else {
      selected = options.find(opt => opt.value === value)
    }
    setOption(selected)
  }, [multiple, options, value])

  useEffect(() => {
    let selected = null
    if (multiple && defaultValue) {
      selected = options.filter(opt => defaultValue.includes(opt.value))
    } else {
      selected = options.find(opt => opt.value === defaultValue)
    }
    setDefaultOption(selected)
  }, [options, defaultValue, multiple])

  const handleChange = (event, data) => {
    if (onChange) {
      if (multiple) {
        // Returns an array of values from selected options.
        onChange(data && data.map(e => e.value))
      } else if (freeSolo) {
        // Returns the typed value. Sets the default value for freeSolo select fields.
        onChange(data)
      } else {
        // Returns the value of an option
        onChange(data?.value)
      }
    }
    return data
  }

  const handleInputChange = (event, data) => {
    if (freeSolo) {
      // Returns the typed value
      onChange(data)
    }
    if (onInputChange) onInputChange(event, data)
  }

  if (multiple) {
    return (
      <Autocomplete
        id={id}
        disabled={disabled}
        options={options}
        autoComplete={autoComplete}
        loading={loading}
        autoSelect={autoSelect}
        autoHighlight
        clearOnEscape={clearOnEscape}
        clearOnBlur={clearOnBlur}
        multiple
        onInputChange={onInputChange}
        freeSolo={freeSolo}
        filterSelectedOptions
        value={option || []}
        defaultValue={defaultOption}
        onChange={handleChange}
        getOptionLabel={opt => opt.label}
        renderInput={params => (
          <TextField
            {...params}
            {...otherProps}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
      />
    )
  }

  // This allows to pick an option and continue editing, as well as defaultValues with freeSolo.
  const freeSoloProps = freeSolo ? {
    inputValue: value || '', // Sets not an option value (freeSolo) // ORIGINAL: value || defaultInputValue || ''
    onInputChange: handleInputChange,
  } : {
    value: option, // Sets an option value
    onInputChange: handleInputChange,
  }

  return (
    <Autocomplete
      id={id}
      disabled={disabled}
      options={options}
      autoComplete={autoComplete}
      loading={loading}
      autoSelect={autoSelect}
      autoHighlight
      clearOnEscape={clearOnEscape}
      clearOnBlur={clearOnBlur}
      freeSolo={freeSolo}
      defaultValue={defaultOption}
      onChange={handleChange}
      getOptionLabel={opt => opt.label}
      getOptionSelected={opt => opt.value}
      renderInput={params => (
        <TextField
          {...params}
          {...otherProps}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
      {...freeSoloProps}
    />
  )
}

SelectField.propTypes = {
  id: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  type: PropTypes.string.isRequired,
  forwardRef: PropTypes.any,
  freeSolo: PropTypes.bool,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  autoComplete: PropTypes.bool,
  clearOnBlur: PropTypes.bool,
  clearOnEscape: PropTypes.bool,
  autoSelect: PropTypes.bool,
}

SelectField.defaultProps = {
  disabled: false,
  multiple: false,
  freeSolo: false,
  value: null,
  onChange: null,
  onInputChange: null,
  autoComplete: true,
  clearOnBlur: true,
  clearOnEscape: true,
  autoSelect: true,
}

export default SelectField
