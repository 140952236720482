import React from 'react'
import { AdminRow } from 'components/pages/users'
import { TableContent, TableLoading } from 'components/common'
import { connectInfiniteHits, Configure } from 'react-instantsearch-dom'
import { USER } from 'constants/resources'
import {
  useResource, useStoreAlgolia, useSelectResource, useDeviceColumns,
} from 'components/hooks'
import { users } from 'store/action-creators'
import PropTypes from 'prop-types'

function UserContent({
  hits, hasMore, refineNext, hasPrevious, refinePrevious, columns,
}) {
  const selected = useResource(USER, 'current', 'id')
  const list = useStoreAlgolia(USER, users, hits)
  const loading = useResource(USER, 'list', 'loading')
  const handleRowClick = useSelectResource(USER)
  const templateColumns = useDeviceColumns(columns, !!selected)

  return (
    <TableContent
      next={refineNext}
      dataLength={hits.length}
      hasMore={hasMore}
    >
      <Configure
        filters="is_admin:true"
      />
      <TableLoading loading={loading} />
      {
        list.map(user => (
          <AdminRow
            key={user.get('id')}
            id={user.get('id')}
            shrink={!!selected}
            templateColumns={templateColumns}
            name={user.get('name')}
            companyName="RentAll Construction"
            email={user.get('email')}
            role={user.get('role')}
            phoneNumber={user.get('contact_number')}
            active={user.get('id') === selected}
            onClick={() => handleRowClick(user.get('id'))}
          />
        ))
      }
    </TableContent>
  )
}

UserContent.propTypes = {
  columns: PropTypes.object.isRequired,
  hits: PropTypes.array.isRequired,
  hasMore: PropTypes.bool.isRequired,
  refineNext: PropTypes.func.isRequired,
  hasPrevious: PropTypes.bool.isRequired,
  refinePrevious: PropTypes.func.isRequired,
}

export default connectInfiniteHits(UserContent)
