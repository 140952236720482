import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Chip, Flex } from 'components/common'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { SERVICE_REQUEST } from 'constants/resources'
import { MessageForm, QuoteForm } from 'components/pages/service-request-messages'

function Form({ current, loading, update }) {
  const { t } = useTranslation(['common', SERVICE_REQUEST, 'error'])
  const [pdfGen, setPDFGen] = useState(false)
  const handleChipClick = () => {
    setPDFGen(!pdfGen)
  }
  const currentServiceRequest = useSelector(state => state.getIn([SERVICE_REQUEST, 'current', 'data']))
  const designation = currentServiceRequest.get('designation')

  return (
    <>
      <Flex align="center" justify="space-between">
        <h1>{t('common:shared.information')}</h1>
        {
          designation === 'rental-price' ||
          designation === 'order' ? (
            <Chip
              onClick={handleChipClick}
              outline={!pdfGen}
              label={t('serviceRequest:pdf.generate')}
            />
          ) : null
        }
      </Flex>
      {
        !pdfGen ? (
            <MessageForm current={current} loading={loading} update={update} />
          )
          : (
            <QuoteForm currentMessage={current} updateMessage={update} />
          )
      }
    </>
  )
}

Form.propTypes = {
  loading: PropTypes.bool,
  update: PropTypes.bool,
  current: PropTypes.instanceOf(Map),
}

Form.defaultProps = {
  loading: false,
  update: false,
  current: new Map(),
}

export default Form
