import {
  LIST,
  LIST_RESET,
  SET_LIST_DATA,
  PREPEND_LIST_ITEM,
  APPEND_LIST_ITEM,
  UPDATE_LIST_ITEM,
  REMOVE_LIST_ITEM,
  SET_LIST_SEARCH_INDEX,
  CLEAR_LIST_DATA,
  SET_LIST_SEARCH_QUERY,
  CLEAR_LIST_SEARCH_FACET_FILTERS,
  ADD_LIST_SEARCH_FACET_FILTERS,
  REMOVE_LIST_SEARCH_FACET_FILTERS,
  EMPTY_LIST_SEARCH_FACET_FILTERS,
  CLEAR_LIST_SEARCH_FILTERS,
  ADD_LIST_SEARCH_FILTERS,
  REMOVE_LIST_SEARCH_FILTERS,
  EMPTY_LIST_SEARCH_FILTERS,
  SET_LIST_METADATA,
  SET_LIST_LOADING,
  GET,
  GET_STORE,
  SET_CURRENT_DATA,
  CLEAR_CURRENT_DATA,
  SET_CURRENT_ID,
  SET_CURRENT_LOADING,
  CREATE,
  SET_CREATE_DATA,
  CLEAR_CREATE_DATA,
  SET_CREATE_LOADING,
  UPDATE,
  SET_UPDATE_DATA,
  CLEAR_UPDATE_DATA,
  SET_UPDATE_LOADING,
  DELETE,
  SET_DELETE_LOADING,
} from 'store/actions/factory'

export const createAction = (type, resource = '') => (body, onSuccess = null, onError = null) => ({
  type,
  resource,
  payload: { body, onSuccess, onError },
})

const createActionsWithResource = resource => ({
  getList: createAction(LIST(resource), resource),
  getListReset: createAction(LIST_RESET(resource), resource),
  setListData: createAction(SET_LIST_DATA(resource), resource),
  prependListItem: createAction(PREPEND_LIST_ITEM(resource), resource),
  appendListItem: createAction(APPEND_LIST_ITEM(resource), resource),
  updateListItem: createAction(UPDATE_LIST_ITEM(resource), resource),
  removeListItem: createAction(REMOVE_LIST_ITEM(resource), resource),
  setListIndex: createAction(SET_LIST_SEARCH_INDEX(resource), resource),
  clearListData: createAction(CLEAR_LIST_DATA(resource), resource),
  setListSearchQuery: createAction(SET_LIST_SEARCH_QUERY(resource), resource),
  clearListSearchFacetFilters: createAction(CLEAR_LIST_SEARCH_FACET_FILTERS(resource), resource),
  addListSearchFacetFilters: createAction(ADD_LIST_SEARCH_FACET_FILTERS(resource), resource),
  removeListSearchFacetFilters: createAction(REMOVE_LIST_SEARCH_FACET_FILTERS(resource), resource),
  emptyListSearchFacetFilters: createAction(EMPTY_LIST_SEARCH_FACET_FILTERS(resource), resource),
  clearListSearchFilters: createAction(CLEAR_LIST_SEARCH_FILTERS(resource), resource),
  addListSearchFilters: createAction(ADD_LIST_SEARCH_FILTERS(resource), resource),
  removeListSearchFilters: createAction(REMOVE_LIST_SEARCH_FILTERS(resource), resource),
  emptyListSearchFilters: createAction(EMPTY_LIST_SEARCH_FILTERS(resource), resource),
  setListMetadata: createAction(SET_LIST_METADATA(resource), resource),
  setListLoading: createAction(SET_LIST_LOADING(resource), resource),
  get: createAction(GET(resource), resource),
  getStore: createAction(GET_STORE(resource), resource),
  setCurrentData: createAction(SET_CURRENT_DATA(resource), resource),
  clearCurrentData: createAction(CLEAR_CURRENT_DATA(resource), resource),
  setCurrentId: createAction(SET_CURRENT_ID(resource), resource),
  setCurrentLoading: createAction(SET_CURRENT_LOADING(resource), resource),
  create: createAction(CREATE(resource), resource),
  setCreateData: createAction(SET_CREATE_DATA(resource), resource),
  clearCreateData: createAction(CLEAR_CREATE_DATA(resource), resource),
  setCreateLoading: createAction(SET_CREATE_LOADING(resource), resource),
  update: createAction(UPDATE(resource), resource),
  setUpdateData: createAction(SET_UPDATE_DATA(resource), resource),
  clearUpdateData: createAction(CLEAR_UPDATE_DATA(resource), resource),
  setUpdateLoading: createAction(SET_UPDATE_LOADING(resource), resource),
  delete: createAction(DELETE(resource), resource),
  setDeleteLoading: createAction(SET_DELETE_LOADING(resource), resource),
})

export default createActionsWithResource
