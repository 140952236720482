import React from 'react'
import { ORDER_ITEM, SUPPLIER_RATE } from 'constants/resources'
import { TableDetail } from 'components/common'
import SupplierForm from './SupplierForm'
import { useResource, useCurrent } from 'components/hooks'

function New() {
  const orderItem = useCurrent(ORDER_ITEM)
  const loading = useResource(SUPPLIER_RATE, 'create', 'loading')

  return (
    <TableDetail>
      <SupplierForm
        current={orderItem}
        loading={loading}
      />
    </TableDetail>
  )
}

export default New
