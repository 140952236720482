export const OFF_RENT = 'off-rent'
export const SERVICE_CALL = 'service-call'
export const RESERVATION = 'reservation'
export const RESERVATION_CANCELLATION = 'reservation-cancellation'
export const ACCOUNT_REQUEST = 'account-request'
export const ACCOUNT_DELETE = 'account-delete'
export const UPDATE = 'update'
export const REMINDER = 'reminder'
export const ORDER = 'order'
export const RENTAL_PRICE = 'rental-price'
export const PURCHASE_PRICE = 'purchase-price'
export const REQUEST = 'request'
export const ASSIGNED = 'assigned'
export const EXTRA = 'extra'
