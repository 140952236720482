const accent = {
  100: '#f7af1e',
  200: '#f7a01e',
  300: '#f7921e',
  400: '#f7831e',
  500: '#f7751e',
  600: '#f7661e',
  700: '#f7581e',
  800: '#78d4c3'
}

export default accent
