import React from 'react'
import {
  TableRow, TableCell, PreviewLink, Flex,
} from 'components/common'
import PropTypes from 'prop-types'
import moment from 'moment'
import { USER, COMPANY } from 'constants/resources'
import { NotificationAcknowledgment, NotificationDesignation } from 'components/pages/notifications'
import { DATETIME } from 'constants/formats'

function NotificationRow({
  templateColumns,
  designation,
  id,
  status,
  body,
  createdAt,
  acknowledgedAt,
  acknowledgedBy,
  actorName,
  actorId,
  companyName,
  companyId,
  active,
  shrink,
  onClick,
}) {
  return (
    <TableRow templateColumns={templateColumns} active={active} onClick={onClick}>
      <TableCell sm>
        <NotificationDesignation
          name={designation}
          secondary={active}
        />
      </TableCell>
      <TableCell sm>
      <span>{moment(createdAt).format(DATETIME)}</span>
      </TableCell>
      <TableCell md>
        <PreviewLink
          hideMobile
          resource={USER}
          id={actorId}
        >
          {actorName}
        </PreviewLink>
      </TableCell>
      <TableCell lg={!shrink}>
        <PreviewLink
          hideMobile
          resource={COMPANY}
          id={companyId}
        >
          {companyName}
        </PreviewLink>
      </TableCell>
      <TableCell md>
        <p>{body}</p>
      </TableCell>
      <TableCell sm>
        <Flex justify="center">
          <NotificationAcknowledgment
            acknowledgedAt={acknowledgedAt}
            acknowledgedBy={acknowledgedBy}
            id={id}
          />
        </Flex>
      </TableCell>
    </TableRow>
  )
}

NotificationRow.propTypes = {
  designation: PropTypes.string,
  active: PropTypes.bool,
  shrink: PropTypes.bool,
  templateColumns: PropTypes.string.isRequired,
  id: PropTypes.string,
  status: PropTypes.string,
  body: PropTypes.string,
  createdAt: PropTypes.string,
  acknowledgedAt: PropTypes.string,
  acknowledgedBy: PropTypes.string,
  actorName: PropTypes.string,
  actorId: PropTypes.string,
  companyName: PropTypes.string,
  companyId: PropTypes.string,
  onClick: PropTypes.func,
}

NotificationRow.defaultProps = {
  designation: '',
  active: false,
  shrink: false,
  id: '',
  status: '',
  body: '',
  createdAt: '',
  acknowledgedAt: '',
  acknowledgedBy: '',
  actorName: '',
  actorId: '',
  companyName: '',
  companyId: '',
  onClick: null,
}

export default NotificationRow
