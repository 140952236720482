import { SERVICE_REQUEST_MESSAGE } from 'constants/resources'
import { SERVICE_REQUEST_MESSAGE_CREATE_QUOTE } from 'store/actions/serviceRequestMessages'
import createActionsWithResource, { createAction } from './factory'

const serviceRequestMessages = createActionsWithResource(SERVICE_REQUEST_MESSAGE)

export default {
  ...serviceRequestMessages,
  createQuote: createAction(SERVICE_REQUEST_MESSAGE_CREATE_QUOTE),
}
