import React from 'react'
import { OrderItemRow } from 'components/pages/order-items'
import { TableContent, TableLoading } from 'components/common'
import { connectInfiniteHits, Configure } from 'react-instantsearch-dom'
import { ORDER_ITEM } from 'constants/resources'
import { useResource, useStoreAlgolia, useStatusColors, useSelectResource, useDeviceColumns, useFacetFilters, useFilters } from 'components/hooks'
import { orderItems } from 'store/action-creators'
import PropTypes from 'prop-types'

function OrderItemContent({ hits, hasMore, refineNext, hasPrevious, refinePrevious, columns }) {
  const statusColors = useStatusColors(ORDER_ITEM)
  const selected = useResource(ORDER_ITEM, 'current', 'id')
  const list = useStoreAlgolia(ORDER_ITEM, orderItems, hits)
  const loading = useResource(ORDER_ITEM, 'list', 'loading')
  const handleRowClick = useSelectResource(ORDER_ITEM)
  const templateColumns = useDeviceColumns(columns, !!selected)
  const facetFilters = useFacetFilters(ORDER_ITEM)
  const filters = useFilters(ORDER_ITEM)

  return (
    <TableContent next={refineNext} dataLength={hits.length} hasMore={hasMore}>
      <Configure hitsPerPage={40} facetFilters={facetFilters} filters={filters} />
      <TableLoading loading={loading} />
      {list.map((orderItem) => {
        const [street, street2, city, state, zipCode, country] = orderItem.getIn(['order', 'address'])
          ? orderItem.getIn(['order', 'address']).split(',')
          : ',,,,,'
        return (
          <OrderItemRow
            key={orderItem.get('id')}
            id={orderItem.get('id')}
            shrink={!!selected}
            templateColumns={templateColumns}
            internalCode={orderItem.get('internal_code')}
            quantity={orderItem.get('quantity')}
            startDate={orderItem.getIn(['order', 'start_date'])}
            address={orderItem.getIn(['order', 'address'])}
            street={`${street} ${street2}`}
            city={city}
            state={state}
            zipCode={zipCode}
            country={country}
            salesRepId={orderItem.getIn(['order', 'sales_rep', 'id'])}
            salesRepName={orderItem.getIn(['order', 'sales_rep', 'name'])}
            assigneeId={orderItem.getIn(['order', 'assignee', 'id'])}
            assigneeName={orderItem.getIn(['order', 'assignee', 'name'])}
            billingDate={orderItem.getIn(['order', 'billing_date'])}
            offRentedAt={orderItem.get('off_rented_at')}
            status={orderItem.get('status')}
            statusColor={statusColors[orderItem.get('status')]}
            equipmentName={orderItem.getIn(['equipment', 'name'])}
            equipmentId={orderItem.getIn(['equipment', 'id'])}
            orderCode={orderItem.getIn(['order', 'internal_code'])}
            orderId={orderItem.getIn(['order', 'id'])}
            companyName={orderItem.getIn(['company', 'name'])}
            companyId={orderItem.getIn(['company', 'id'])}
            active={orderItem.get('id') === selected}
            onClick={() => handleRowClick(orderItem.get('id'))}
          />
        )
      })}
    </TableContent>
  )
}

OrderItemContent.propTypes = {
  columns: PropTypes.object.isRequired,
  hits: PropTypes.array.isRequired,
  hasMore: PropTypes.bool.isRequired,
  refineNext: PropTypes.func.isRequired,
  hasPrevious: PropTypes.bool.isRequired,
  refinePrevious: PropTypes.func.isRequired
}

export default connectInfiniteHits(OrderItemContent)
