import { takeLatest, call, put, select } from 'redux-saga/effects'
import { ORDER_ITEM } from 'constants/resources'
import { LIST, GET, CREATE, UPDATE, DELETE } from 'store/actions/factory'
import { get, create, destroy, list } from './factory'
import nprogress from './nprogress'
import { orderItems } from 'store/action-creators'
import { update as updateItemService } from 'services/order-items'
import i18n from 'config/i18n'
import { notify } from 'utils'
import { closePreview } from './preview'
import { EDIT } from 'constants/crud'

export function* update({ payload }) {
  yield call(nprogress, true)
  const { body, onSuccess, onError } = payload
  yield put(orderItems.setUpdateLoading(true))
  const id = yield select((store) => store.getIn([ORDER_ITEM, 'current', 'id']))
  const response = yield call(updateItemService, body.id ?? id, body)
  if (response) {
    if (!!!body.noNoty) {
      yield call(notify, {
        text: i18n.t('common:shared.successUpdate', { resource: response.name || response.internal_code || response.description })
      })
    }
    yield call(closePreview, ORDER_ITEM, EDIT)
    if (onSuccess) yield call(onSuccess, response)
  } else if (onError) yield call(onError)

  yield call(nprogress, false)
  yield put(orderItems.setUpdateLoading(false))
}

export default function* orderItemSaga() {
  yield takeLatest(LIST(ORDER_ITEM), list)
  yield takeLatest(GET(ORDER_ITEM), get)
  yield takeLatest(CREATE(ORDER_ITEM), create)
  yield takeLatest(UPDATE(ORDER_ITEM), update)
  yield takeLatest(DELETE(ORDER_ITEM), destroy)
}
