import React from 'react'
import { SUPPLIER_RATE } from 'constants/resources'
import { TableDetail } from 'components/common'
import SupplierForm from './SupplierForm'
import { useResource, useCurrent } from 'components/hooks'
import { Map } from 'immutable'

function New() {
  const supplierRate = useCurrent(SUPPLIER_RATE) ?? new Map()
  const loading = useResource(SUPPLIER_RATE, 'create', 'loading')

  return (
    <TableDetail>
      <SupplierForm
        current={supplierRate}
        loading={loading}
      />
    </TableDetail>
  )
}

export default New
