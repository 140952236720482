import React from 'react'
import { SERVICE_CALL } from 'constants/resources'
import { SERVICE_REQUEST_INDEX } from 'constants/algolia'
import { withAlgolia } from 'components/hocs'
import { connectHits } from 'react-instantsearch-dom'
import { AutoCompleteSelect } from 'components/common'

function ServiceCallSelect({ hits, value, ...otherProps }) {
  return (
    <AutoCompleteSelect
      hits={hits}
      value={value}
      attributesToRetrieve={['objectID', 'internal_code']}
      objectName={SERVICE_CALL}
      keyLabel="internal_code"
      configureHitsPerPage={5}
      configureFilter={'designation:service-call AND is_redacted:false'}
      {...otherProps}
    />
  )
}

export default withAlgolia(SERVICE_REQUEST_INDEX)(
  connectHits(ServiceCallSelect)
)
