import React from 'react'
import { COMPANY } from 'constants/resources'
import { COMPANY_INDEX } from 'constants/algolia'
import { withAlgolia } from 'components/hocs'
import { connectHits } from 'react-instantsearch-dom'
import { AutoCompleteSelect } from 'components/common'

function CompanySelect({ hits, value, ...otherProps }) {
  return (
    <AutoCompleteSelect
      hits={hits}
      value={value}
      attributesToRetrieve={['objectID', 'name']}
      objectName={COMPANY}
      keyLabel="name"

      {...otherProps}
    />
  )
}

export default withAlgolia(COMPANY_INDEX)(connectHits(CompanySelect))
